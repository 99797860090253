/* eslint-disable camelcase */
import { isPresent, MonetaryValue } from '@luminovo/commons';
import { useMutation } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { QuoteRequestForm } from '../model/quoteRequestForm';

function useSaveLineItemTargetPrice() {
    const { mutateAsync: saveLineItemTargetPrices } = useHttpMutation('POST /quote-request/line-item/update-many', {
        snackbarMessage: null,
    });

    return async (lineItemsPrices: QuoteRequestForm['lineItemTargetPrices']) => {
        await saveLineItemTargetPrices({
            requestBody: {
                updates: lineItemsPrices
                    .filter(
                        (lineItemPrice) =>
                            lineItemPrice.targetPrice !== null && lineItemPrice.targetPrice.amount !== null,
                    )
                    .map((lineItemPrice) => ({
                        id: lineItemPrice.quoteRequestLineItemId,
                        target_price: lineItemPrice.targetPrice as MonetaryValue,
                    })),
            },
        });
    };
}

export function useSubmitLineItemPrices(
    quoteRequestLineItemId?: QuoteRequestForm['lineItemTargetPrices'][number]['quoteRequestLineItemId'],
) {
    const { handleSubmit, reset } = useFormContext<QuoteRequestForm>();
    const onSubmit = useSaveLineItemTargetPrice();
    const submit = handleSubmit((val) => {
        if (isPresent(quoteRequestLineItemId)) {
            const lineItemTargetPrice = val.lineItemTargetPrices.filter(
                (lineItem) => lineItem.quoteRequestLineItemId === quoteRequestLineItemId,
            );
            return onSubmit(lineItemTargetPrice);
        }
        return onSubmit(val.lineItemTargetPrices);
    });
    return useMutation({
        mutationFn: () => submit(),
        onError: () => {
            reset({}, { keepValues: true, keepErrors: true });
        },
    });
}
