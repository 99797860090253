import { AwardScenarioDTO } from '@luminovo/http-client';

export function formatAwardScenario(awardScenario?: AwardScenarioDTO | null) {
    if (!awardScenario) {
        return '';
    }

    if (!isAutomaticAwardScenario(awardScenario)) {
        return awardScenario.name;
    }

    if (awardScenario.name === 'luminovo::best-price-across-all-quotes') {
        return 'Best price across all quotes';
    }
    if (awardScenario.name === 'luminovo::last-purchase-price') {
        return 'Last purchase price';
    }
    if (isBestListPriceAwardScenario(awardScenario)) {
        return 'Best list price';
    }
    if (awardScenario.name === 'luminovo::random-list-price') {
        return 'Random list price';
    }
    return awardScenario.name;
}

export function isBestListPriceAwardScenario(awardScenario?: AwardScenarioDTO | null) {
    return awardScenario?.name === 'luminovo::best-list-price';
}

export function isLastPurchasePriceAwardScenario(awardScenario?: AwardScenarioDTO | null) {
    return awardScenario?.name === 'luminovo::last-purchase-price';
}

export function isBestPriceAcrossAllQuotesAwardScenario(awardScenario?: AwardScenarioDTO | null) {
    return awardScenario?.name === 'luminovo::best-price-across-all-quotes';
}

export function isAutomaticAwardScenario(awardScenario?: AwardScenarioDTO | null) {
    return awardScenario?.name.startsWith('luminovo::');
}
