/* eslint-disable camelcase */
import { t } from '@lingui/macro';
import { Currency, groupBy } from '@luminovo/commons';
import {
    ExtractRequestBody,
    ManufacturerDTO,
    Packaging,
    PriceType,
    SupplierAndStockLocationDTO,
} from '@luminovo/http-client';
import { ImporterConfig, UniversalImporter } from '@luminovo/universal-importer';
import { UseQueryResult } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../resources/mutation/useHttpMutation';
import { route } from '../../utils/routes';
import { formatError } from '../Error/formatError';

export function StandardOfferImporter() {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { onImportBatch } = useImportOffers();

    const { data: manufacturers = [] } = useManufacturerNames();

    const { data: suppliers = [] } = useHttpQuery(
        'GET /suppliers-and-stock-locations',
        {},
        { suspense: true, select: (res) => res.data.filter((supplier) => Boolean(supplier.supplier_number)) },
    );

    const { data: defaultCurrency = Currency.EUR } = useHttpQuery(
        'GET /organization-settings/organization-currency-settings',
        {},
        { suspense: true, select: (res) => res.data.currency },
    );

    const decimalSeparator = '.';

    return (
        <UniversalImporter
            title={t`MPN offer importer`}
            batchSize={Infinity}
            hrefBack={route('/parts/components/ipn')}
            onImportDone={() => {
                enqueueSnackbar(t`Offers imported successfully`, {
                    variant: 'success',
                    anchorOrigin: {
                        horizontal: 'center',
                        vertical: 'top',
                    },
                });
                history.goBack();
            }}
            onImportBatch={onImportBatch}
            config={createImporterConfig({
                manufacturers,
                decimalSeparator,
                defaultCurrency,
                suppliers,
            })}
        />
    );
}

function useImportOffers() {
    const { mutateAsync: importOffers } = useHttpMutation('POST /offers/import', { snackbarMessage: null });

    const onImportBatch = async (
        batch: Array<{
            data: {
                mpn: string;
                manufacturer: string;
                notes: string;
                availableStock: number;
                totalStock: number;
                packaging: Packaging;
                unitPrice: number;
                pricePer: number;
                priceType: PriceType;
                currency: Currency;
                moq: number;
                mpq: number;
                leadTimeWeeks: number;
                supplierNumber: string;
            };
        }>,
    ) => {
        const grouped = groupBy(batch, (row) => {
            return [
                row.data.manufacturer,
                row.data.mpn,
                row.data.availableStock,
                row.data.totalStock,
                row.data.supplierNumber,
                row.data.priceType,
            ];
        });

        const requestBody: ExtractRequestBody<'POST /offers/import'> = Object.values(grouped).map((rows) => {
            const row = rows[0];

            const prices = rows.map((row) => {
                return {
                    unit_price: {
                        amount: String(row.data.unitPrice / row.data.pricePer),
                        currency: row.data.currency,
                    },
                    moq: row.data.moq,
                    mpq: row.data.mpq,
                };
            });

            return {
                part: {
                    manufacturer: row.data.manufacturer,
                    manufacturer_part_number: row.data.mpn,
                },
                price_type: row.data.priceType,
                availability: {
                    available_stock: row.data.availableStock,
                    total_stock: row.data.totalStock,
                    lead_time: row.data.leadTimeWeeks * 7,
                },
                notes: row.data.notes,
                packaging: row.data.packaging,
                supplier: {
                    type: 'External',
                    supplier_number: row.data.supplierNumber,
                },
                prices,
            };
        });

        return importOffers({
            requestBody,
        })
            .then((results) => {
                return batch.map((batchItem, index) => {
                    const resultItem = results[index];

                    if (!resultItem) {
                        return {
                            success: false as const,
                            message: t`Unknown error`,
                        };
                    }

                    if (resultItem.status > 299) {
                        return {
                            success: false as const,
                            message: resultItem.description ?? t`Unknown error`,
                        };
                    }

                    return {
                        success: true as const,
                    };
                });
            })
            .catch((error) => {
                return batch.map((row) => {
                    return {
                        success: false as const,
                        message: formatError(error),
                    };
                });
            });
    };
    return { onImportBatch };
}

const createImporterConfig = ({
    manufacturers,
    decimalSeparator,
    defaultCurrency,
    suppliers,
}: {
    manufacturers: ManufacturerDTO[];
    decimalSeparator: '.' | ',';
    defaultCurrency: Currency;
    suppliers: SupplierAndStockLocationDTO[];
}) => {
    const config = {
        fields: [
            {
                id: 'mpn' as const,
                columnIndices: [],
                required: true,
                parser: { type: 'string', options: { trim: true, minLength: 1 } },
                label: t`MPN`,
                description: t`The technical description of the part`,
            },
            {
                id: 'manufacturer' as const,
                columnIndices: [],
                required: true,
                parser: { type: 'manufacturer.name', options: { manufacturers } },
                label: t`Manufacturer`,
                description: t`The name of the manufacturer`,
            },
            {
                id: 'unitPrice' as const,
                columnIndices: [],
                required: true,
                parser: {
                    type: 'number',
                    options: {
                        decimalSeparator,
                    },
                },
                label: t`Unit price`,
                description: t`The price of a single unit`,
            },
            {
                id: 'pricePer' as const,
                columnIndices: [],
                required: false,
                parser: { type: 'number', options: { min: 1 } },
                label: t`Price per`,
                defaultValue: { id: 1, label: `1`, description: '' },
                description: t`Is the unit pricer per 1s, 10s, 100s, 1000s, etc.`,
            },
            {
                id: 'currency' as const,
                columnIndices: [],
                required: false,
                parser: { type: 'currency', options: {} },
                label: t`Currency`,
                defaultValue: { id: defaultCurrency, label: defaultCurrency },
                description: t`The unit price's currency`,
            },
            {
                id: 'priceType' as const,
                columnIndices: [],
                required: false,
                parser: { type: 'priceType', options: {} },
                label: t`Price type`,
                defaultValue: { id: 'StandardPrice', label: 'StandardPrice' },
                description: t`The price type of the offer`,
            },
            {
                id: 'moq' as const,
                columnIndices: [],
                required: false,
                parser: { type: 'number', options: { min: 0, decimalSeparator } },
                label: t`MOQ`,
                defaultValue: { id: 1, label: `1`, description: '' },
                description: t`The minimum order quantity`,
            },
            {
                id: 'mpq' as const,
                columnIndices: [],
                required: false,
                parser: { type: 'number', options: { min: 1, decimalSeparator } },
                label: t`MPQ`,
                defaultValue: { id: 1, label: `1`, description: '' },
                description: t`The minimum package quantity`,
            },
            {
                id: 'packaging' as const,
                columnIndices: [],
                required: false,
                parser: { type: 'packaging', options: {} },
                label: t`Packaging`,
                defaultValue: { id: null, label: t`Unknown` },
                description: t`The packaging in which the part is delivered`,
            },
            {
                id: 'availableStock' as const,
                columnIndices: [],
                required: false,
                defaultValue: { id: 0, label: `0` },
                parser: { type: 'number', options: { min: 0, decimalSeparator } },
                label: t`Available stock`,
                description: t`The available stock.`,
            },
            {
                id: 'totalStock' as const,
                columnIndices: [],
                required: false,
                defaultValue: { id: null, label: t`Unknown` },
                parser: { type: 'number', options: { min: 0, decimalSeparator } },
                label: t`Total stock`,
                description: t`The total amount of stock. Some of it might be reserved or not available for use`,
            },
            {
                id: 'leadTimeWeeks' as const,
                columnIndices: [],
                required: false,
                label: t`Lead time (weeks)`,
                parser: { type: 'leadTimeWeeks', options: { decimalSeparator } },
                description: t`The standard manufacturer lead time`,
                defaultValue: { id: null, label: t`Unknown`, description: '' },
            },
            {
                id: 'supplierNumber' as const,
                columnIndices: [],
                required: false,
                parser: { type: 'supplier.number', options: { suppliers, warnOnSystemSupplier: false } },
                label: t`Supplier number`,
                description: t`The supplier of the part`,
            },
            {
                id: 'notes' as const,
                columnIndices: [],
                required: false,
                defaultValue: { id: '', label: ``, description: '' },
                parser: { type: 'notes', options: { min: 0 } },
                label: t`Notes`,
                description: t`Any additional information you want to add`,
            },
        ],
    } satisfies ImporterConfig;
    return config;
};

function useManufacturerNames(): UseQueryResult<ManufacturerDTO[]> {
    return useHttpQuery(
        'GET /manufacturers',
        {},
        {
            suspense: true,
            select: (res) => res.data,
        },
    );
}
