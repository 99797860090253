import * as Sentry from '@sentry/react';
import { ConsentType } from '../ConsentType';
import { Tracker } from '../Tracker';

export const sentryTracker: Tracker = {
    requiredConsent: ConsentType.support,

    /**
     * Track internal users as they also produce bugs
     */
    enabledForInternalUsers: false,

    enabledForDevEnvironment: false,

    identify(userId: string, { email }) {
        Sentry.setUser({ userId, email });
    },

    initialize() {
        /**
         * Setup Sentry.
         */
        const isClientInitialized = Sentry.getCurrentHub().getClient() !== undefined;
        if (isClientInitialized) {
            return;
        }
        Sentry.init({
            dsn: window.SENTRY_DSN,
            release: window.SENTRY_RELEASE,
            transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
            initialScope: (scope) => {
                return scope.setTags({
                    'luminovo.version': window.LUMINOVO_VERSION,
                    'screen.width': window.screen.width,
                    'screen.height': window.screen.height,
                    'screen.orientation.type': window.screen.orientation.type,
                    'screen.orientation.angle': window.screen.orientation.angle,
                });
            },
            integrations: [
                Sentry.extraErrorDataIntegration({ depth: 20 }),
                Sentry.browserTracingIntegration({
                    shouldCreateSpanForRequest: (url) => {
                        // Do not create spans for outgoing requests to magicbell.com or ably.io endpoints
                        return !url.match(/(?:magicbell\.com|ably\.io)/);
                    },
                }),
                Sentry.browserProfilingIntegration(),
                Sentry.replayIntegration({
                    minReplayDuration: 10 * 1000,
                    blockAllMedia: false,
                    maskAllText: false,
                    maskAllInputs: false,
                    networkDetailAllowUrls: [`https://${window.location.hostname}/api/`],
                }),
            ],
            tracesSampleRate: 0.3,
            profilesSampleRate: 0.5,
            replaysSessionSampleRate: 1.0,
            replaysOnErrorSampleRate: 1.0,
        });
    },

    group: () => {
        /* do nothing*/
    },

    track: () => {
        /* do nothing*/
    },
    page: () => {
        /* do nothing*/
    },
};
