import { Trans } from '@lingui/macro';
import { colorSystem, Flexbox, Text } from '@luminovo/design-system';
import { CancelRounded, Info } from '@mui/icons-material';
import { BomImporterIssue, IssueNameEnum, Level } from '../../../resources/bomImporter/bomImporterIssuesEnum';
import { convertIssueToUserFacingMessage } from '../../../resources/bomImporter/convertIssueToUserFacingMessage';
import { assertUnreachable } from '../../../utils/typingUtils';

export const BomIssueItem = ({ issue, lineNumber }: { issue: BomImporterIssue; lineNumber?: number }) => {
    if (
        issue.name === IssueNameEnum.NO_DNP_COLUMN ||
        issue.name === IssueNameEnum.NO_MPN_COLUMN ||
        issue.name === IssueNameEnum.NO_QUANTITY_COLUMN ||
        issue.name === IssueNameEnum.NO_DESIGNATOR_COLUMN ||
        issue.name === IssueNameEnum.NO_DESIGNATOR_AND_QUANTITY
    ) {
        // Skipping these as they've been added as frontend checks
        // otherwise they won't clear once fixed through the UI
        return null;
    }
    return (
        <ValidationIssueItem
            level={issue.level}
            message={convertIssueToUserFacingMessage(issue)}
            lineNumber={lineNumber}
        />
    );
};

const getColor = (level: Level): string => {
    switch (level) {
        case Level.Error:
            return colorSystem.red[6];
        case Level.Notification:
            return colorSystem.neutral[6];
        case Level.Warning:
            return colorSystem.yellow[6];
        default:
            assertUnreachable(level);
    }
};

export const ValidationIssueItem = ({
    message,
    level = Level.Error,
    lineNumber,
}: {
    message: string | JSX.Element;
    level?: Level;
    lineNumber?: number;
}): JSX.Element => {
    const Icon = level === Level.Error ? CancelRounded : Info;
    return (
        <Flexbox alignItems="flex-start" gap={8}>
            <Icon style={{ color: getColor(level), fontSize: '16px', paddingTop: '2px' }} />
            <Text>
                {message} {lineNumber && <Trans>Line number: {lineNumber}</Trans>}
            </Text>
        </Flexbox>
    );
};
