import { plural, Trans } from '@lingui/macro';
import { throwErrorUnlessProduction } from '@luminovo/commons';
import { Select, Text } from '@luminovo/design-system';
import { BomScreeningSheet, ScreenerHandlerOutput } from '@luminovo/http-client';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { BomImporterSidebarContainer } from './BomImporterSidebarContainer';

export const BomSheetSelect = ({
    sheetIndex,
    bomScreeningOutput,
    switchSheet,
}: {
    sheetIndex: number;
    bomScreeningOutput: ScreenerHandlerOutput;
    switchSheet: (sheetIndex: number) => void;
}): JSX.Element => {
    const sheets = bomScreeningOutput.sheets.map((sheet) => sheet.sheet_name);
    const bomScreeningSheet: BomScreeningSheet = bomScreeningOutput.sheets[sheetIndex];
    const excelLines = bomScreeningSheet.excel_lines;
    const numberOfLines = excelLines.length;

    const handleChange = (event: SelectChangeEvent<unknown>) => {
        if (typeof event.target.value !== 'number') {
            return throwErrorUnlessProduction('selected sheet index should be a number');
        }
        switchSheet(event.target.value);
    };

    if (sheets.length === 1) {
        return (
            <Text>
                {plural(numberOfLines, {
                    one: `We found ${numberOfLines} line in the Excel file`,
                    other: `We found ${numberOfLines} lines in the Excel file`,
                })}
            </Text>
        );
    }
    if (sheets.length > 1) {
        return (
            <BomImporterSidebarContainer>
                <Text variant="h5">
                    <Trans>Select the BOM sheet</Trans>
                </Text>
                <Select id="sheetSelect" onChange={handleChange} value={sheetIndex} size="small">
                    {sheets.map((sheet: string, i: number) => (
                        <MenuItem key={i} value={i}>
                            {sheet}
                        </MenuItem>
                    ))}
                </Select>
                <Text variant="body-small">
                    {plural(numberOfLines, {
                        one: `We found ${numberOfLines} line`,
                        other: `We found ${numberOfLines} lines`,
                    })}
                </Text>
            </BomImporterSidebarContainer>
        );
    }
    return <></>;
};
