import { t } from '@lingui/macro';
import {
    AutocompleteOptions,
    CustomerPartNumberIcon,
    InternalPartNumberIcon,
    ManufacturingIcon,
    PackageIcon,
    ParameterOption,
    PartCapacitorIcon,
    SearchBlock,
    SpecCapacitanceIcon,
    SpecPowerRatingIcon,
    SpecResistanceIcon,
    SpecTemperatureCoefficientIcon,
    SpecVoltageIcon,
    UseParametricSearchInputStateReturn,
    useParametricSearchInputState,
} from '@luminovo/design-system';
import Link from '@mui/icons-material/Link';

import {
    ComponentTypeEnum,
    CustomerDTO,
    ErpDataChangesFilter,
    ExtractResponseBody,
    GenericPartTypes,
    IpnLastChanged,
    LinkedPartsFilter,
    ManufacturerDTO,
    OtsComponentFull,
    PackageDTO,
} from '@luminovo/http-client';
import { formatPackage } from '@luminovo/sourcing-core';
import { DoneAllRounded, ModeOutlined } from '@mui/icons-material';
import { styled } from '@mui/material';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import {
    formatCapacitance,
    formatPowerRating,
    formatResistance,
    formatTemperatureCoefficient,
    formatTolerance,
    formatValueWithOrderOfMagnitude,
    formatVoltageRating,
    capacitanceConverter as parseCapacitance,
    resistanceConverter as parseResistance,
    toleranceConverter as parseTolerance,
    powerRatingConverter,
    voltageConverter,
} from '../../../../utils/converterUtils';
import { SearchField } from '../../../PartLibrary/PartSearchPage/PartSearchInput';

const LinkIcon = styled(Link)({
    width: 16,
    height: 16,
});

const autocompleteErpDataOptions = (
    {
        enableCustomerFilter,
    }: {
        enableCustomerFilter?: boolean;
    } = { enableCustomerFilter: false },
): AutocompleteOptions<OtsComponentFull, ComponentsSearchAutocompleteState> => {
    const areTranslation = t`are`;
    return {
        parameters: [
            {
                field: SearchField.ipn,
                label: t`IPN`,
                icon: <InternalPartNumberIcon />,
                ops: [
                    {
                        op: 'starts-with',
                    },
                ],
            },
            {
                field: SearchField.cpn,
                label: t`CPN`,
                icon: <CustomerPartNumberIcon />,
                ops: [
                    {
                        op: 'starts-with',
                    },
                ],
            },
            {
                field: SearchField.mpn,
                label: t`MPN`,
                icon: <CustomerPartNumberIcon />,
                ops: [
                    {
                        op: 'starts-with',
                    },
                ],
            },
            {
                field: SearchField.spn,
                label: t`SPN`,
                icon: <CustomerPartNumberIcon />,
                ops: [
                    {
                        op: 'starts-with',
                    },
                ],
            },
            {
                field: SearchField.linkedParts,
                label: t`Linked parts`,
                icon: <LinkIcon />,
                formatter: (data) => {
                    return linkedPartsFilterLabel(data as LinkedPartsFilter);
                },
                ops: [{ op: 'is', label: areTranslation, options: optionsForLinkedParts }],
            },
            {
                field: SearchField.lastChanged,
                label: t`Last changed`,
                icon: <ModeOutlined color={'inherit'} style={{ fontSize: '20px' }} />,
                formatter: (data) => {
                    return lastChangedLabel(data as IpnLastChanged);
                },
                ops: [
                    {
                        op: 'is',
                        options: optionsForLastChanged,
                    },
                ],
            },
            {
                field: SearchField.componentType,
                label: t`Component type`,
                icon: <PartCapacitorIcon fontSize="small" />,
                formatter: (data) => {
                    return componentTypeFilterLabel(data as ComponentTypeEnum);
                },
                ops: [{ op: 'is', label: t`is`, options: optionsForComponentType }],
            },
            {
                field: SearchField.erpChanges,
                label: t`ERP changes`,
                icon: <DoneAllRounded fontSize="small" />,
                formatter: (data) => {
                    return erpDataChangesFilterLabel(data as ErpDataChangesFilter);
                },
                ops: [{ op: 'is', label: t`require`, options: optionsForErpChanges }],
            },
            {
                field: SearchField.manufacturer,
                label: t`Manufacturer`,
                icon: <ManufacturingIcon />,
                formatter: (manufacturerId, state) => {
                    return state?.manufacturersById[String(manufacturerId)]?.name ?? '...';
                },
                ops: [{ op: 'is', options: optionsForManufacturer }],
            },
            {
                field: SearchField.customer,
                label: t`Customer`,
                icon: <CustomerPartNumberIcon />,
                formatter: (customerId, state) => {
                    return state?.customersById[String(customerId)]?.name ?? '...';
                },
                ops: [{ op: 'is', options: optionsForCustomer }],
                hidden: !enableCustomerFilter,
            },
            {
                field: SearchField.partType,
                label: t`Part type`,
                icon: <PartCapacitorIcon />,
                ops: [
                    {
                        op: 'is',
                        options: optionsForPartTypes,
                    },
                ],
            },
            {
                field: SearchField.package,
                label: t`Package`,
                icon: <PackageIcon />,
                formatter: (packageId, state) => {
                    const pack = state?.packagesById[String(packageId)];
                    if (pack) {
                        return formatPackage(pack);
                    }
                    return '';
                },
                ops: [{ op: 'is', options: optionsForPackage }],
            },
            {
                field: SearchField.capacitance,
                icon: <SpecCapacitanceIcon />,
                label: t`Capacitance`,
                unit: 'F',
                requires: (state) => {
                    const shouldHide = isResistorLikeFromSelectedBlocks(state.selectedBlocks);
                    return !shouldHide;
                },
                formatter: (capacitance) => formatCapacitance(String(capacitance)),
                ops: [{ op: '=', binned: true, fieldConverter: capacitanceConverter, options: optionsForCapacitance }],
            },
            {
                field: SearchField.resistance,
                icon: <SpecResistanceIcon />,
                label: t`Resistance`,
                unit: 'Ω',
                requires: (state) => {
                    const shouldHide = isCapacitorLikeFromSelectedBlocks(state.selectedBlocks);
                    return !shouldHide;
                },
                formatter: (resistance) => formatResistance(String(resistance)),
                ops: [{ op: '=', binned: true, fieldConverter: resistanceConverter, options: optionsForResistance }],
            },
            {
                field: SearchField.tolerance,
                label: t`Tolerance`,
                unit: '%',
                icon: <SpecTemperatureCoefficientIcon />,
                formatter: (tolerance) => formatTolerance(String(tolerance)),
                ops: [{ op: '≤', binned: true, fieldConverter: toleranceConverter, options: optionsForTolerance }],
            },
            {
                field: SearchField.powerRating,
                label: t`Power rating`,
                unit: 'W',
                icon: <SpecPowerRatingIcon />,
                requires: (state) => {
                    const shouldHide = isCapacitorLikeFromSelectedBlocks(state.selectedBlocks);
                    return !shouldHide;
                },
                formatter: (powerRating) => formatPowerRating(String(powerRating)),
                ops: [
                    {
                        op: '≥',
                        binned: true,
                        fieldConverter: powerRatingFieldConverter,
                        options: optionsForPowerRating,
                    },
                ],
            },
            {
                field: SearchField.voltageRating,
                label: t`Voltage rating`,
                unit: 'V',
                icon: <SpecVoltageIcon />,
                formatter: (tolerance) => formatVoltageRating(String(tolerance)),
                ops: [
                    { op: '≥', binned: true, fieldConverter: voltageRatingConverter, options: optionsForVoltageRating },
                ],
            },
            {
                field: SearchField.temperatureCoefficient,
                label: t`Temperature coefficient`,
                unit: 'ppm/K',
                icon: <SpecTemperatureCoefficientIcon />,
                requires: (state) => {
                    const shouldHide = isCapacitorLikeFromSelectedBlocks(state.selectedBlocks);
                    return !shouldHide;
                },
                formatter: (tc) => formatTemperatureCoefficient(String(tc)),
                ops: [
                    {
                        op: '≤',
                        binned: true,
                        fieldConverter: toleranceConverter,
                        options: optionsForTemperatureCoefficient,
                    },
                ],
            },
        ],
        textQueryParser: (parameter) => {
            return { field: '*', op: 'like', parameter };
        },
    };
};

const autocompleteComponentsDataOptions = (): AutocompleteOptions<
    OtsComponentFull,
    ComponentsSearchAutocompleteState
> => {
    return {
        parameters: [
            {
                field: SearchField.ipn,
                label: t`IPN`,
                icon: <InternalPartNumberIcon />,
                ops: [
                    {
                        op: 'starts-with',
                    },
                ],
            },
            {
                field: SearchField.cpn,
                label: t`CPN`,
                icon: <CustomerPartNumberIcon />,
                ops: [
                    {
                        op: 'starts-with',
                    },
                ],
            },
        ],
        textQueryParser: (parameter) => {
            return { field: '*', op: 'like', parameter };
        },
    };
};

export function isResistorLikeFromSelectedBlocks<T>(selectedBlocks: SearchBlock<T>[]): boolean {
    return (
        selectedBlocks.filter((block) => {
            return (
                (block.field === SearchField.partType && block.parameter === GenericPartTypes.Resistor) ||
                block.field === SearchField.resistance ||
                block.field === SearchField.powerRating ||
                block.field === SearchField.temperatureCoefficient
            );
        }).length > 0
    );
}

export function isCapacitorLikeFromSelectedBlocks<T>(selectedBlocks: SearchBlock<T>[]): boolean {
    return (
        selectedBlocks.filter((block) => {
            return (
                (block.field === SearchField.partType && block.parameter === GenericPartTypes.Capacitor) ||
                block.field === SearchField.capacitance ||
                block.field === SearchField.dielectricDielectric
            );
        }).length > 0
    );
}

export type UseComponentsSearchStateResult = UseParametricSearchInputStateReturn<
    OtsComponentFull,
    ComponentsSearchAutocompleteState
>;

type UseErpParametricSearchStateProps = Pick<
    AutocompleteOptions<OtsComponentFull, ComponentsSearchAutocompleteState>,
    'history'
>;

type UseComponentsParametricSearchStateProps = UseErpParametricSearchStateProps & { displayOtsFilters?: boolean };

export function useErpParametricSearchState(
    props: UseErpParametricSearchStateProps | undefined = {},
): UseComponentsSearchStateResult {
    return useParametricSearchInputState({ ...autocompleteErpDataOptions({ enableCustomerFilter: true }), ...props });
}

export function useComponentsParametricSearchState(
    props: UseComponentsParametricSearchStateProps | undefined = {},
): UseComponentsSearchStateResult {
    const displayOtsFilters = props.displayOtsFilters ?? true;
    const options = displayOtsFilters ? autocompleteErpDataOptions() : autocompleteComponentsDataOptions();
    return useParametricSearchInputState({ ...options, ...props });
}

export interface ComponentsSearchAutocompleteState {
    manufacturersById: Record<string, ManufacturerDTO>;
    packagesById: Record<string, PackageDTO>;
    customersById: Record<string, CustomerDTO>;
    aggregations?: ExtractResponseBody<'POST /components/search'>['aggregations'];
}

export const capacitanceConverter = {
    formatValue: (value: number) => formatValueWithOrderOfMagnitude(value, ''),
    parseValue: (value: string) => {
        const parsed = parseCapacitance(value);
        if (parsed === null || parsed === undefined) {
            return { ok: false as const };
        }
        return { ok: true as const, value: parsed.toNumber() };
    },
};

const optionsForCapacitance = (state?: ComponentsSearchAutocompleteState) => {
    if (!state || !state.aggregations) {
        return [];
    }
    return state.aggregations.capacitance.value.buckets.map((b) => {
        return { value: b.key, label: formatCapacitance(String(b.key)), count: b.doc_count };
    });
};

const optionsForResistance = (state?: ComponentsSearchAutocompleteState) => {
    if (!state || !state.aggregations) {
        return [];
    }
    return state.aggregations.resistance.value.buckets.map((b) => {
        return { value: b.key, label: formatResistance(String(b.key)), count: b.doc_count };
    });
};

export const resistanceConverter = {
    formatValue: (value: number) => formatValueWithOrderOfMagnitude(value, ''),
    parseValue: (value: string) => {
        const parsed = parseResistance(value);
        if (parsed === null || parsed === undefined) {
            return { ok: false as const };
        }
        return { ok: true as const, value: Number(parsed) };
    },
};

const optionsForTolerance = (state?: ComponentsSearchAutocompleteState) => {
    if (!state || !state.aggregations) {
        return [];
    }
    return state.aggregations.tolerance.value.buckets.map((b) => {
        return { value: b.key, label: formatTolerance(String(b.key)), count: b.doc_count };
    });
};

const optionsForPowerRating = (state?: ComponentsSearchAutocompleteState) => {
    if (!state || !state.aggregations) {
        return [];
    }
    return state.aggregations.power_rating.value.buckets.map((b) => {
        return { value: b.key, label: formatTolerance(String(b.key)), count: b.doc_count };
    });
};

const optionsForVoltageRating = (state?: ComponentsSearchAutocompleteState) => {
    if (!state || !state.aggregations) {
        return [];
    }
    return state.aggregations.voltage_rating.value.buckets.map((b) => {
        return { value: b.key, label: formatVoltageRating(String(b.key)), count: b.doc_count };
    });
};

const optionsForTemperatureCoefficient = (state?: ComponentsSearchAutocompleteState) => {
    if (!state || !state.aggregations) {
        return [];
    }
    return state.aggregations.temperature_coefficient.value.buckets.map((b) => {
        return { value: b.key, label: formatTemperatureCoefficient(String(b.key)), count: b.doc_count };
    });
};

export const toleranceConverter = {
    formatValue: (value: number) => formatValueWithOrderOfMagnitude(value, ''),
    parseValue: (value: string) => {
        const parsed = parseTolerance(value);
        if (parsed === null || parsed === undefined) {
            return { ok: false as const };
        }
        return { ok: true as const, value: parsed.toNumber() };
    },
};

const optionsForPartTypes = (state?: ComponentsSearchAutocompleteState) => {
    if (!state || !state.aggregations) {
        return [];
    }
    return state.aggregations['part-types']['type-name'].buckets.map((b) => {
        return { value: b.key, label: b.key, count: b.doc_count };
    });
};

const lastChangedLabel = (filter: IpnLastChanged) => {
    switch (filter) {
        case IpnLastChanged.Today:
            return t`Today`;
        case IpnLastChanged.Yesterday:
            return t`Yesterday`;
        case IpnLastChanged.Last7Days:
            return t`Last 7 days`;
        case IpnLastChanged.MoreThan7DaysAgo:
            return t`More than 7 days ago`;
    }
};

const optionsForLastChanged = (state?: ComponentsSearchAutocompleteState) => {
    const aggregations = state?.aggregations;
    if (!aggregations) {
        return [];
    }
    return [IpnLastChanged.Today, IpnLastChanged.Yesterday, IpnLastChanged.Last7Days].map((filter) => {
        const count = aggregations.last_changed.buckets.find((b) => b.key === filter)?.doc_count ?? 0;
        return { value: filter, label: lastChangedLabel(filter), count };
    });
};

const erpDataChangesFilterLabel = (filter: ErpDataChangesFilter) => {
    switch (filter) {
        case ErpDataChangesFilter.ReviewRequired:
            return t`Review`;
        case ErpDataChangesFilter.NoReviewRequired:
            return t`No review`;
    }
};

const optionsForErpChanges = (state?: ComponentsSearchAutocompleteState) => {
    const aggregations = state?.aggregations;
    if (!aggregations) {
        return [];
    }

    return [
        {
            value: ErpDataChangesFilter.ReviewRequired,
            label: erpDataChangesFilterLabel(ErpDataChangesFilter.ReviewRequired),
            count:
                aggregations.has_unreviewed_erp_changes.buckets.find((b) => b.key_as_string === 'true')?.doc_count ?? 0,
        },
        {
            value: ErpDataChangesFilter.NoReviewRequired,
            label: erpDataChangesFilterLabel(ErpDataChangesFilter.NoReviewRequired),
            count:
                aggregations.has_unreviewed_erp_changes.buckets.find((b) => b.key_as_string === 'false')?.doc_count ??
                0,
        },
    ];
};

const componentTypeFilterLabel = (filter: ComponentTypeEnum) => {
    switch (filter) {
        case ComponentTypeEnum.Custom:
            return t`Custom`;
        case ComponentTypeEnum.OffTheShelf:
            return t`Off-the-shelf`;
    }
};

const optionsForComponentType = (state?: ComponentsSearchAutocompleteState) => {
    const aggregations = state?.aggregations;
    if (!aggregations) {
        return [];
    }

    return [
        {
            value: ComponentTypeEnum.Custom,
            label: componentTypeFilterLabel(ComponentTypeEnum.Custom),
        },
        {
            value: ComponentTypeEnum.OffTheShelf,
            label: componentTypeFilterLabel(ComponentTypeEnum.OffTheShelf),
        },
    ];
};

const optionsForLinkedParts = (state?: ComponentsSearchAutocompleteState) => {
    const aggregations = state?.aggregations;
    if (!aggregations) {
        return [];
    }
    return [
        LinkedPartsFilter.NoLinkedParts,
        LinkedPartsFilter.IncompleteLinkedParts,
        LinkedPartsFilter.LinkedParts,
    ].map((filter) => {
        const count = aggregations.linked_parts_filter.buckets.find((b) => b.key === filter)?.doc_count ?? 0;
        return { value: filter, label: linkedPartsFilterLabel(filter), count };
    });
};

const linkedPartsFilterLabel = (filter: LinkedPartsFilter) => {
    switch (filter) {
        // TODO: We should rename this variant in a separate MR to Complete https://www.notion.so/luminovo/Building-Backlog-BOM-f9b31a8848bc4a05904107919350323f?p=32b63670074744529dd2957e84e43f91&pm=s
        case LinkedPartsFilter.LinkedParts:
            return t`Complete`;
        case LinkedPartsFilter.NoLinkedParts:
            return t`None`;
        case LinkedPartsFilter.IncompleteLinkedParts:
            return t`Incomplete`;
    }
};

export const voltageRatingConverter = {
    formatValue: (value: number) => formatValueWithOrderOfMagnitude(value, ''),
    parseValue: (value: string) => {
        const parsed = voltageConverter(value);
        if (parsed === null || parsed === undefined) {
            return { ok: false as const };
        }
        return { ok: true as const, value: parsed.toNumber() };
    },
};

export const powerRatingFieldConverter = {
    formatValue: (value: number) => formatValueWithOrderOfMagnitude(value, ''),
    parseValue: (value: string) => {
        const parsed = powerRatingConverter(value);
        if (parsed === null || parsed === undefined) {
            return { ok: false as const };
        }
        return { ok: true as const, value: parsed.toNumber() };
    },
};

export const optionsForPackage = (state?: ComponentsSearchAutocompleteState) => {
    if (!state) {
        return [];
    }

    if (!state.aggregations || !state.aggregations.packages) {
        return Object.keys(state.packagesById).map((k) => {
            const pack = state.packagesById[k];
            return { value: k, label: formatPackage(pack), count: 0 };
        });
    }

    return state.aggregations.packages.buckets.map((b) => {
        const pack = state.packagesById[b.key];
        if (pack) {
            return { value: pack.id, label: formatPackage(pack), count: b.doc_count };
        } else {
            return { value: b.key, label: b.key, count: b.doc_count };
        }
    });
};

const optionsForManufacturer = (state?: ComponentsSearchAutocompleteState) => {
    if (!state || !state.aggregations) {
        return [];
    }
    return state.aggregations.manufacturers['manufacturer-id'].buckets.map((b) => {
        const label = state.manufacturersById[b.key]?.name ?? '...';
        return { value: b.key, label, count: b.doc_count };
    });
};

const optionsForCustomer = (state?: ComponentsSearchAutocompleteState) => {
    if (!state || !state.customersById) {
        return [];
    }
    return Object.entries(state.customersById).map((val) => {
        return { value: val[0], label: val[1].name };
    });
};

export const useCustomersById = (searchState: UseComponentsSearchStateResult): Record<string, CustomerDTO> => {
    const customersHidden: boolean = findFieldConfig(searchState, SearchField.customer)?.hidden ?? false;

    const { data: customersById = {} } = useHttpQuery(
        'GET /customers',
        {},
        {
            select: groupCustomersById,
            staleTime: Infinity,
            enabled: !customersHidden,
        },
    );

    return customersById;
};

function groupCustomersById(res: { data: CustomerDTO[] }) {
    return res.data.reduce((customersById: Record<string, CustomerDTO>, customer) => {
        customersById[customer.id] = customer;
        return customersById;
    }, {});
}

function findFieldConfig(
    searchState: UseComponentsSearchStateResult,
    field: string,
): ParameterOption<OtsComponentFull, ComponentsSearchAutocompleteState> | undefined {
    return searchState.state.config.parameters.find((p) => p.field === field);
}
