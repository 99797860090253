import { transEnum } from '@luminovo/commons';
import { StatusChip } from '@luminovo/design-system';
import { QuoteRequestDTO, QuoteRequestStatus } from '@luminovo/http-client';
import { quoteRequestStatusTranslations } from '../model/getQuoteRequestStatus';

export function QuoteRequestStatusChip({ status }: Pick<QuoteRequestDTO, 'status'>) {
    return (
        <StatusChip
            color={getQuoteRequestStatusColor(status)}
            label={transEnum(status, quoteRequestStatusTranslations)}
        />
    );
}

export function getQuoteRequestStatusColor(status: QuoteRequestStatus) {
    switch (status) {
        case QuoteRequestStatus.NotSent:
            return 'neutral';
        case QuoteRequestStatus.Sent:
            return 'blue';
        case QuoteRequestStatus.Overdue:
            return 'red';
        case QuoteRequestStatus.Discarded:
            return 'yellow';
        case QuoteRequestStatus.Received:
            return 'green';
    }
}
