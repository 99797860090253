/* eslint-disable camelcase */
import * as r from 'runtypes';
import { ApprovalStatusRuntype, MonetaryValueBackendRuntype, QuantityUnitDTORuntype } from '../backendTypes';
import { BomFileDTORuntype } from '../bomImporter';
import { DesignItemExcelOriginDTORuntype } from '../designItem';
import {
    CustomComponentFullRuntype,
    IpnWithFullPartMatchesRuntype,
    OtsComponentFullRuntype,
} from '../internalPartNumber';
import { AvailabilityRuntype } from '../offer/availablityBackendTypes';
import {
    CustomOptionOfferDTORuntype,
    StandardPartInventoryOfferDTORuntype,
    StandardPartMarketOfferDTORuntype,
} from '../offer/offerBackendTypes';
import { PartSpecificationTypes } from '../part';
import {
    BulkCustomPartDTORuntype,
    BulkOTSPartDTORuntype,
    CustomFullPartRuntype,
    GenericFullPartRuntype,
    GenericPartWithIdAndFullPackageRuntype,
    OffTheShelfPartWithFullPackageRuntype,
    OtsFullPartRuntype,
    PartOptionOriginRuntype,
    StandardPartTypes,
} from '../part/partBackendTypes';
import { QuoteTrackingLightRuntype } from '../quoteTracking';
import { DerivedScrapQuantityDTORuntype, SolutionDTORuntype } from '../solution/solutionBackendTypes';
import { SupplierAndStockLocationDTORuntype } from '../supplierAndStockLocation';
import { SolutionPreferenceDTORuntype } from './solutionConfigurationBackendTypes';

export interface SourcingBreadcrumbItemsDTO extends r.Static<typeof SourcingBreadcrumbItemsDTORuntype> {}
const SourcingBreadcrumbItemsDTORuntype = r.Record({
    design_item_id: r.String,
    designator: r.String,
    parent_assemblies: r.Array(
        r.Record({
            id: r.String,
            name: r.String,
        }),
    ),
});

const AggregatedBreadcrumbsDTORuntype = r.Record({
    breadcrumbs: r.Record({ items: r.Array(SourcingBreadcrumbItemsDTORuntype) }),
});

const StandardFullPartOptionDTORuntype = r.Record({
    approval_status: ApprovalStatusRuntype,
    origin: PartOptionOriginRuntype.nullable(),
    part: r.Union(
        r.Record({
            type: r.Literal(StandardPartTypes.Generic),
            data: GenericFullPartRuntype,
        }),
        r.Record({
            type: r.Literal(StandardPartTypes.OffTheShelf),
            data: OtsFullPartRuntype,
        }),
        r.Record({
            type: r.Literal(StandardPartTypes.Ipn),
            data: OtsComponentFullRuntype,
        }),
    ),
});

const StandardPartSpecificationRuntype = r.Record({
    type: r.Literal(PartSpecificationTypes.OffTheShelf),
    data: r.Record({
        is_manufacturer_free: r.Boolean,
        part_options: r.Array(StandardFullPartOptionDTORuntype),
    }),
});

const CustomFullPartSpecificationRuntype = r.Record({
    type: r.Literal(PartSpecificationTypes.Custom),
    data: r.Array(
        r.Record({
            part: r.Union(
                r.Record({
                    type: r.Literal('CustomPart'),
                    data: CustomFullPartRuntype,
                }),
                r.Record({
                    type: r.Literal('CustomComponent'),
                    data: CustomComponentFullRuntype,
                }),
            ),
            approval_status: ApprovalStatusRuntype,
        }),
    ),
});

const PartOptionFullRuntype = r.Record({
    approval_status: ApprovalStatusRuntype,
    origin: PartOptionOriginRuntype.nullable(),
    part: r.Union(
        r.Record({
            type: r.Literal(StandardPartTypes.OffTheShelf),
            data: OffTheShelfPartWithFullPackageRuntype,
        }),
        r.Record({
            type: r.Literal(StandardPartTypes.Generic),
            data: GenericPartWithIdAndFullPackageRuntype,
        }),
        r.Record({
            type: r.Literal(StandardPartTypes.Ipn),
            data: IpnWithFullPartMatchesRuntype,
        }),
    ),
});

export interface PartSpecificationOffTheShelfFull extends r.Static<typeof PartSpecificationOffTheShelfFullRuntype> {}
export const PartSpecificationOffTheShelfFullRuntype = r.Record({
    type: r.Literal(PartSpecificationTypes.OffTheShelf),
    data: r.Record({
        is_manufacturer_free: r.Boolean,
        part_options: r.Array(PartOptionFullRuntype),
    }),
});

const CustomOptionVariantFullRuntype = r.Record({
    type: r.Literal(PartSpecificationTypes.Custom),
    data: r.Array(
        r.Record({
            part: r.Union(
                r.Record({
                    type: r.Literal('CustomPart'),
                    data: CustomFullPartRuntype,
                }),
                r.Record({
                    type: r.Literal('CustomComponent'),
                    data: CustomComponentFullRuntype,
                }),
            ),
            approval_status: ApprovalStatusRuntype,
        }),
    ),
});

export const PartSpecificationFullRuntype = r.Union(
    PartSpecificationOffTheShelfFullRuntype,
    CustomOptionVariantFullRuntype,
);

export interface DerivedScrapQuantityDTO extends r.Static<typeof DerivedScrapQuantityDTORuntype> {}

export interface SolutionConfigurationSourcingDTO extends r.Static<typeof SolutionConfigurationSourcingDTORuntype> {}
export const SolutionConfigurationSourcingDTORuntype = r.Record({
    id: r.String,
    effective_solution: SolutionDTORuntype.nullable(),
    aggregated_quantity: QuantityUnitDTORuntype,
    scrap_quantity: r.Null.Or(DerivedScrapQuantityDTORuntype),
    specification: r.Null.Or(r.Union(CustomFullPartSpecificationRuntype, StandardPartSpecificationRuntype)),
    aggregated_breadcrumbs: AggregatedBreadcrumbsDTORuntype,
    is_consigned: r.Boolean,
    notes: r.String.nullable(),
});

export interface SolutionConfigurationFullPartSourcingInformationResponse
    extends r.Static<typeof SolutionConfigurationFullPartSourcingInformationResponseRuntype> {}
export const SolutionConfigurationFullPartSourcingInformationResponseRuntype = r.Record({
    id: r.String,
    effective_solution: SolutionDTORuntype.nullable(),
    aggregated_quantity: QuantityUnitDTORuntype,
    scrap_quantity: r.Null.Or(DerivedScrapQuantityDTORuntype),
    specification: PartSpecificationFullRuntype.nullable(),
    aggregated_breadcrumbs: AggregatedBreadcrumbsDTORuntype,
    is_consigned: r.Boolean,
    notes: r.String.nullable(),
});

const SolutionConfigurationSourcingItemsDTORuntype = r.Record({
    items: r.Array(SolutionConfigurationSourcingDTORuntype),
});

const SolutionStatusCountsDTORuntype = r.Record({
    number_of_error: r.Number,
    number_of_ok: r.Number,
    number_of_warning: r.Number,
});

export interface FullSourcingDTO extends r.Static<typeof FullSourcingDTORuntype> {}

export const FullSourcingDTORuntype = r.Record({
    sourcing_scenario_id: r.String,
    solution_configurations_sourcing: SolutionConfigurationSourcingItemsDTORuntype,
    total_excess_material: MonetaryValueBackendRuntype.nullable(),
    total_unit_price: MonetaryValueBackendRuntype.nullable(),
    total_one_time_costs: MonetaryValueBackendRuntype.nullable(),
    total_scrap_costs: MonetaryValueBackendRuntype.nullable(),
    total_price: MonetaryValueBackendRuntype.nullable(),
    total_availability: AvailabilityRuntype.nullable(),
    status_count: SolutionStatusCountsDTORuntype,
    off_the_shelf_parts: BulkOTSPartDTORuntype,
    custom_parts: BulkCustomPartDTORuntype,
    off_the_shelf_offers: r.Record({
        items: r.Array(StandardPartMarketOfferDTORuntype),
    }),
    inventory_offers: r.Record({
        items: r.Array(StandardPartInventoryOfferDTORuntype),
    }),
    custom_part_offers: r.Record({
        items: r.Array(CustomOptionOfferDTORuntype),
    }),
    quotes_by_solution_config: r.Dictionary(r.Array(QuoteTrackingLightRuntype), r.String),
});

export interface AssemblyQuantities extends r.Static<typeof AssemblyQuantitiesRuntype> {}

export interface ItemObject extends r.Static<typeof ItemObjectRuntype> {}
const ItemObjectRuntype = r.Record({
    assembly: r.String,
    quantity: r.Number,
});

const AssemblyQuantitiesRuntype = r.Record({
    items: r.Array(ItemObjectRuntype),
});

export interface SourcingScenarioDTO extends r.Static<typeof SourcingScenarioDTORuntype> {}
export const SourcingScenarioDTORuntype = r.Record({
    id: r.String,
    rfq: r.String,
    name: r.String,
    solution_preference: SolutionPreferenceDTORuntype,
    assembly_quantities: AssemblyQuantitiesRuntype,
});

export interface SourcingScenarioPostDTO extends r.Static<typeof SourcingScenarioPostDTORuntype> {}
export const SourcingScenarioPostDTORuntype = r.Record({
    rfq: r.String,
    name: r.String,
    solution_preference: SolutionPreferenceDTORuntype,
    assembly_quantities: r.Array(ItemObjectRuntype),
});

export interface SourcingScenarioPatchDTO extends r.Static<typeof SourcingScenarioPatchDTORuntype> {}
export const SourcingScenarioPatchDTORuntype = SourcingScenarioPostDTORuntype.omit('rfq').asPartial();

export interface SupplierSolutionDTO extends r.Static<typeof SupplierSolutionRuntype> {}
export const SupplierSolutionRuntype = r.Record({
    linked_location: SupplierAndStockLocationDTORuntype,
    sourcing_scenario_solutions: r.Array(
        r.Record({
            solutions: r.Array(SolutionDTORuntype),
            solution_configuration_id: r.String,
            sourcing_scenario: SourcingScenarioDTORuntype,
        }),
    ),
});

export interface SolutionConfigurationExcelOriginInfo
    extends r.Static<typeof SolutionConfigurationExcelOriginInfoRuntype> {}
export const SolutionConfigurationExcelOriginInfoRuntype = r.Record({
    sourcing_scenario_id: r.String,
    solution_configuration_id: r.String,
    bom_files: r.Array(BomFileDTORuntype),
    excel_origins: r.Array(DesignItemExcelOriginDTORuntype),
});
