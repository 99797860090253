import { SecondaryButton } from '@luminovo/design-system';
import { Upload } from '@mui/icons-material';
import { useHistory } from 'react-router';
import { route } from '../../../utils/routes';

export function ButtonNegotiationQuoteImporter() {
    const history = useHistory();

    return (
        <SecondaryButton
            size="medium"
            startIcon={<Upload />}
            onClick={() => history.push(route('/negotiations/quote-importer'))}
        >
            Import Excel
        </SecondaryButton>
    );
}
