/**
 * Global registry of IDs used for analytics, HelpHero or other external tools.
 *
 * IDs placed in this list are to be treated as if they are an API, meaning if you change them,
 * you are likely going to break an integration with a 3rd party tool.
 */
interface IdRegistry {
    ['bom_importer/box_header']: {};
    ['bom_importer/button_import']: {};
    ['bom_importer/image_drag_and_drop']: {};
    ['bom_importer/table_header']: {};
    ['design/assembly_monitoring_drawer']: {};
    ['design/box_manufacturer_free']: {};
    ['design/box_pcb_availability_notification']: {};
    ['design/box_pcb_price_availability_notification']: {};
    ['design/box_pcb_specification']: {};
    ['design/box_pcb_characteristics']: {};
    ['design/box_pcb_appearance']: {};
    ['design/box_pcb_advanced']: {};
    ['design/box_pcb_price_radar']: {};
    ['design/box_pcb_specification_form']: {};
    ['design/box_pcb_type']: {};
    ['design/button_add_assembly']: {};
    ['design/button_add_comment']: {};
    ['design/button_add_generic_part']: {};
    ['design/button_add_ots_part_manually']: {};
    ['design/button_add_part_manually']: {};
    ['design/button_add_part_suggestion']: {};
    ['design/button_add_part_via_ipn']: {};
    ['design/button_add_part_via_mpn']: {};
    ['design/button_add_placed_component']: {};
    ['design/button_alternative_parts']: {};
    ['design/button_assembly_change_history']: {};
    ['design/button_assembly_monitoring']: {};
    ['design/button_assembly']: {};
    ['design/button_bom_change_history']: {};
    ['design/button_compare_parts']: {};
    ['design/button_contact_ems']: {};
    ['design/button_custom']: {};
    ['design/button_delete_assembly']: {};
    ['design/button_delete_bom']: {};
    ['design/button_dowload_original_bom']: {};
    ['design/button_edit_assembly']: {};
    ['design/button_edit_bom_item']: {};
    ['design/button_export_bom']: {};
    ['design/button_filter_by_all']: {};
    ['design/button_filter_by_approved']: {};
    ['design/button_filter_by_dnp']: {};
    ['design/button_filter_by_pending']: {};
    ['design/button_filter_by_rejected']: {};
    ['design/button_manufacturing_data']: {};
    ['design/button_off_the_shelf']: {};
    ['design/button_pcb_file_manager_edit']: {};
    ['design/button_pcb_file_manager_save']: {};
    ['design/button_pcb_specification_save']: {};
    ['design/button_part_selection']: {};
    ['design/button_partial_matches']: {};
    ['design/button_pin_section']: {};
    ['design/button_request_quotation']: {};
    ['design/button_search']: {};
    ['design/button_suggestions']: {};
    ['design/image_upload_pcb_files']: {};
    ['design/input_notes']: {};
    ['design/label_stock']: {};
    ['design/left_sidebar']: {};
    ['design/message_no_part_options']: {};
    ['design/pcb-drills-tab']: {};
    ['design/pcb-panelization-tab']: {};
    ['design/section_add_parts']: {};
    ['design/section_selected_part_options']: {};
    ['design/section_suggestions']: {};
    ['design/select_component_type']: {};
    ['design/select_sourced_by']: {};
    ['design/tab_bom']: {};
    ['design/tab_cad']: {};
    ['design/tab_files']: {};
    ['design/tab_pcb']: {};
    ['design/tab_pnp']: {};
    ['design/warning_filters']: {};
    ['home_navbar/link_manufacturing_lite']: {};
    ['manufacturing_scenario/activities_table']: {};
    ['manufacturing_scenario/button_add_manufacturing_scenario_dialog_continue_with_template']: {};
    ['manufacturing_scenario/button_create_manufacturing_scenario']: {};
    ['manufacturing_scenario/button_view_activities']: {};
    ['manufacturing_scenario/button_view_expenses']: {};
    ['manufacturing_scenario/cost_breakdown_table']: {};
    ['manufacturing_scenario/dialog_add_manufacturing_scenario_assembly']: {};
    ['manufacturing_scenario/dialog_add_manufacturing_scenario_select_template']: {};
    ['manufacturing_scenario/drivers_list']: {};
    ['manufacturing_scenario/expenses_table']: {};
    ['manufacturing_scenario/manufacturing_scenarios_table_card']: {};
    ['manufacturing_scenario/manufacturing_scenarios_table']: {};
    ['manufacturing_scenario/order_size_column']: {};
    ['navbar/button_avatar']: {};
    ['navbar/button_dashboard']: {};
    ['navbar/button_design']: {};
    ['navbar/button_notifications']: {};
    ['navbar/button_share']: {};
    ['navbar/button_update_offers']: {};
    ['navbar/link_manufacturing_lite']: {};
    ['navbar/link_manufacturing']: {};
    ['oem_create_rfq/section_additional_services']: {};
    ['oem_create_rfq/button_save']: {};
    ['oem_create_rfq/form_item_industry']: {};
    ['oem_create_rfq/notes']: {};
    ['oem_create_rfq/order_scenarios']: {};
    ['oem_create_rfq/project_name']: {};
    ['oem_dashboard/button_rfq_menu']: {};
    ['oem_dashboard/button_contact_ems']: {};
    ['oem_dashboard/button_edit_rfq']: {};
    ['oem_dashboard/button_request_quotation']: {};
    ['oem_dashboard/button_request_technical_support']: {};
    ['oem_dashboard/button_upload_bom']: {};
    ['oem_dashboard/button_upload_cad']: {};
    ['oem_dashboard/button_upload_files']: {};
    ['oem_dashboard/button_upload_manufacturing']: {};
    ['oem_dashboard/button_upload_pcb']: {};
    ['oem_dashboard/button_upload_pnp']: {};
    ['rfq_overview/button_view_archive']: {};
    ['rfq_overview/form_item_search_rfqs']: {};
    ['sliding_layout/panel_left']: {};
    ['sliding_layout/panel_main']: {};
    ['sourcing/apply_sourcing_scenario-changes']: {};
    ['sourcing/form_purchase_orders']: {};
    ['sourcing/button_change_baseline']: {};
    ['sourcing/button_compare_live_version']: {};
    ['sourcing/button_compare_version']: {};
    ['sourcing/button_compare']: {};
    ['sourcing/button_create_version']: {};
    ['sourcing/button_import_pdf']: {};
    ['sourcing/button_request_quote']: {};
    ['sourcing/button_submit_form']: {};
    ['sourcing/button_swap_versions']: {};
    ['sourcing/card_version_history']: {};
    ['sourcing/dialog_select_versions']: {};
    ['sourcing/form_item_select_rfq']: {};
    ['sourcing/form_item_select_sourcing_scenario']: {};
    ['sourcing/form_item_select_version']: {};
    ['sourcing/indicator_lead_time']: {};
    ['sourcing/input_supplier']: {};
    ['sourcing/indicator_total_price']: {};
    ['sourcing/indicator_unit_price']: {};
    ['sourcing/section_current_version']: {};
    ['sourcing/snapshots/button_change_baseline']: {};
    ['suppliers/button_add_supplier']: {};
    ['settings/e2e_manufacturing_scenario_templates_container']: {};
    ['settings/e2e_calculation_templates_container']: {};
    ['settings/e2e_show_part_data_warnings_to_customers']: {};
    ['checkout/e2e_stripe_payment_element']: {};
    ['checkout/e2e_stripe_address_element']: {};
    ['checkout/e2e_show_part_data_warnings_to_customers']: {};
    ['settings/e2e_selected_template']: {};
}

type Section =
    | 'bom_importer' // sort alphabetically
    | 'checkout'
    | 'design'
    | 'home_navbar'
    | 'manufacturing_scenario'
    | 'navbar'
    | 'oem_create_rfq'
    | 'oem_dashboard'
    | 'rfq_overview'
    | 'settings'
    | 'sliding_layout'
    | 'sourcing'
    | 'suppliers';
type ID = `${Section}/${string}`;

export type RegisteredId = ID & keyof IdRegistry;

/**
 * Use this function when you want to mark an ID as relevant to 3rd party tools (e.g. HelpHero tours).
 *
 * @see IdRegistry
 */
export function id(id: RegisteredId): RegisteredId {
    return id;
}
