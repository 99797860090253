import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { CenteredLayout } from '@luminovo/design-system';
import { PriceType, QuantityUnit, RfqContext, StandardPartOfferInputDTO, ValidFor } from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import { useHistory } from 'react-router';
import { useAttachmentFileMutation, useHttpFileUpload } from '../../../resources/http/useHttpFileUpload';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { useGlobalCurrency } from '../../../resources/organizationSettings/currencySettingsHandler';
import { useRfQ } from '../../../resources/rfq/rfqHandler';
import { useSolutionConfiguration } from '../../../resources/solutionConfiguration/solutionConfigurationHandler';
import { useNonExcludedSupplierAndStockLocations } from '../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { StandardPartOfferForm, StandardPartOfferFormValues } from './StandardPartOfferForm';
import { convertToStandardPartOfferInputDTO } from './converters';

export function AddStandardPartOfferForm({
    rfqId,
    linkedPart,
    solutionConfigurationId,
}: {
    rfqId: string;
    linkedPart: StandardPartOfferInputDTO['linked_part'];
    solutionConfigurationId: string | undefined;
}): JSX.Element {
    const { preferredCurrency } = useGlobalCurrency();
    const history = useHistory();
    const rfqContext: RfqContext = { type: 'WithinRfQ', rfq_id: rfqId };
    const { data: rfq } = useRfQ(rfqId);
    const { data: supplierAndStockLocations } = useNonExcludedSupplierAndStockLocations();

    const { data: solutionConfiguration, isLoading: isLoadingSolutionConfiguration } =
        useSolutionConfiguration(solutionConfigurationId);

    const { mutateAsync } = useHttpMutation('POST /offers/off-the-shelf', {
        snackbarMessage: null,
    });

    const { mutateAsync: mutateUpload } = useHttpFileUpload(
        `GET /offers/off-the-shelf/:id/additional-files/upload-link`,
        (response) => response.data.url,
        { snackbarMessage: null },
    );

    const { mutateAsync: mutateAttachmentFile } = useAttachmentFileMutation<{ offerId: string }>({
        mutateUpload: (file, { offerId }) => mutateUpload({ pathParams: { id: offerId }, files: file.file }),
        onSuccess: () => history.goBack(),
        snackbarMessage: t`Manual offer successfully created`,
    });

    if (!rfq || !supplierAndStockLocations || isLoadingSolutionConfiguration) {
        return (
            <CenteredLayout height={'30vh'}>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    const defaultSupplierAndStockLocation = supplierAndStockLocations[0];

    const defaultValues: StandardPartOfferFormValues = {
        linkedPart: linkedPart,
        supplierAndStockLocation: defaultSupplierAndStockLocation,
        quantity: 1,
        quantityUnit: solutionConfiguration?.aggregated_quantity.unit ?? QuantityUnit.Pieces,
        supplierPartNumber: undefined,
        packaging: undefined,
        validUntilDate: undefined,
        priceType: PriceType.QuotePrice,
        rfqId: rfq.id,
        customerId: rfq.customer,
        priceBreaks: [
            {
                moq: 1,
                mpq: 1,
                unitPrice: null,
            },
        ],
        currency: preferredCurrency,
        stock: null,
        factoryLeadTime: null,
        factoryQuantity: null,
        onOrder: [],
        notes: null,
        offerNumber: null,
        validFor: ValidFor.EveryCustomer,
        ncnr: null,
        files: [],
    };

    const onSubmitCallback = async (values: StandardPartOfferFormValues) => {
        const requestBody = convertToStandardPartOfferInputDTO(values);
        const { data: offer } = await mutateAsync({ requestBody });
        await mutateAttachmentFile({
            files: values.files,
            context: { offerId: offer.id },
        });
    };

    return (
        <StandardPartOfferForm
            rfqContext={rfqContext}
            onSubmit={onSubmitCallback}
            defaultValues={defaultValues}
            disabledQuantityUnit={isPresent(solutionConfiguration)}
            rfqId={rfqId}
            sourcingScenarioId={solutionConfiguration?.sourcing_scenario}
        />
    );
}
