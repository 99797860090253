import { t } from '@lingui/macro';
import type { Parser, ParseResult } from '../types';
import { parseDateCommon } from './parseDateString';

export interface Opts {}
/**
 * Parses the delivery start date.
 *
 * This function takes a string value representing a delivery start date and validates it.
 * It first trims any whitespace from the input. If the resulting string is empty,
 * it returns an error indicating that the date is required. Otherwise, it delegates
 * the actual date parsing to the `parseDateCommon` function.
 */
export const parseDeliveryStartDate: Parser<string, Opts> = async function (
    [value],
    opts,
): Promise<ParseResult<string>> {
    value = value.trim();

    if (value.length === 0) {
        return {
            status: 'error',
            message: t`The delivery start date is required`,
        };
    }

    return parseDateCommon(value);
};
