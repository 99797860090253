import { t } from '@lingui/macro';
import { assertUnreachable, isPresent } from '@luminovo/commons';
import {
    BomItemApprovalStatus,
    BomItemGeneralProperties,
    BomItemIssue,
    BomItemPartDataIssues,
    FullPart,
    PartCountEnum,
    PartSpecificationTypes,
    isCustomComponentFull,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { isComplianceError } from '../../../../components/PartComplianceView';
import { isLifecycleError } from '../../../../components/PartLifecycleView';
import { BomItem } from '../../../../resources/designItem/bomItemFrontendTypes';

export enum FilterId {
    UnresolvedComments,
    ResolvedComments,
    DesignatorAndQuantityUnclear,
    NoPartOptionsApproved,
    ApprovedBomItemsWithPartialMatches,
    ApprovedPartOptionsWithNoIPns,
    ApprovedIPNHasNoLinkedParts,
    MissingDataWithSuggestions,
    FewerPartOptionsAddedThanAlternativesInOriginalBom,
    LifecycleIssue,
    ComplianceIssue,
    ApprovedGenericPartHasNoMpnMatches,
    ConsignedDesignItem,
    InsufficientStock,
    THTMounting,
    SMTMounting,
    OtherMounting,
    PinsMissing,
    MountingMissing,
    MissingPartData,
    PackageNameMissing,
    PackageMismatch,
    HealthDashboardFilters,
    HasChangesFromPreviousImport,
}

export const commentFilterIds = [FilterId.UnresolvedComments, FilterId.ResolvedComments];

export const bomItemFilterIds = [
    FilterId.DesignatorAndQuantityUnclear,
    FilterId.NoPartOptionsApproved,
    FilterId.MissingDataWithSuggestions,
    FilterId.FewerPartOptionsAddedThanAlternativesInOriginalBom,
    FilterId.ApprovedPartOptionsWithNoIPns,
    FilterId.ApprovedIPNHasNoLinkedParts,
    FilterId.ApprovedBomItemsWithPartialMatches,
    FilterId.LifecycleIssue,
    FilterId.ComplianceIssue,
    FilterId.ApprovedGenericPartHasNoMpnMatches,
    FilterId.ConsignedDesignItem,
    FilterId.InsufficientStock,
    FilterId.HealthDashboardFilters,
    FilterId.HasChangesFromPreviousImport,
];

export const manufacturingFilterIds = [
    FilterId.PinsMissing,
    FilterId.MountingMissing,
    FilterId.PackageNameMissing,
    FilterId.PackageMismatch,
    FilterId.THTMounting,
    FilterId.SMTMounting,
    FilterId.OtherMounting,
];

interface FilterPredicate {
    matches: (bomItem: BomItem, context: FilterContext) => boolean;
    filterId: FilterId;
}

const changesFromPreviousImportFilter: FilterPredicate = {
    matches: (bomItem) => {
        return bomItem.generalProperties.includes(BomItemGeneralProperties.HasChangesFromPreviousImport);
    },
    filterId: FilterId.HasChangesFromPreviousImport,
};

const unResolvedCommentsFilter: FilterPredicate = {
    matches: (bomItem) => {
        const eventSummaries = bomItem.individualDesignItems.flatMap((designItem) => designItem.event_summary);
        return eventSummaries.some((event) => event.unresolved > 0);
    },
    filterId: FilterId.UnresolvedComments,
};

const resolvedCommentsFilter: FilterPredicate = {
    matches: (bomItem) => {
        const eventSummaries = bomItem.individualDesignItems.flatMap((designItem) => designItem.event_summary);
        return (
            eventSummaries.some((event) => event.total > 0) && eventSummaries.every((event) => event.unresolved === 0)
        );
    },
    filterId: FilterId.ResolvedComments,
};

const designatorAndQuantityUnclearFilter: FilterPredicate = {
    matches: (bomItem) => {
        return bomItem.issues.includes(BomItemIssue.DesignatorAndQuantityUnclear);
    },
    filterId: FilterId.DesignatorAndQuantityUnclear,
};

const noPartOptionsApprovedFilter: FilterPredicate = {
    matches: (bomItem) => {
        return bomItem.approvedPartOptionCardinality === PartCountEnum.None;
    },
    filterId: FilterId.NoPartOptionsApproved,
};

const approvedBomItemsWithPartialMatchesFilter: FilterPredicate = {
    matches: (bomItem) => {
        return bomItem.generalProperties.includes(BomItemGeneralProperties.ApprovedBomItemsWithPartialMatches);
    },
    filterId: FilterId.ApprovedBomItemsWithPartialMatches,
};

const approvedPartOptionsWithNoIPnsFilter: FilterPredicate = {
    matches: (bomItem) => {
        if (bomItem.approvedPartOptions.length === 0) {
            return false;
        }
        if (bomItem.doNotPlace) {
            return false;
        }
        if (bomItem.approvalStatus !== BomItemApprovalStatus.Approved) {
            return false;
        }
        return (
            bomItem.approvedPartOptions.filter((part) => {
                if (isOtsComponentFull(part) || isCustomComponentFull(part)) {
                    return true;
                }
                return false;
            }).length === 0
        );
    },
    filterId: FilterId.ApprovedPartOptionsWithNoIPns,
};

export function isIpnWithoutLinkedParts({ approvedPartOptions }: { approvedPartOptions: FullPart[] }): boolean {
    return approvedPartOptions.some((part) => {
        if (isOtsComponentFull(part)) {
            return part.matches.length === 0;
        }
        return false;
    });
}

const approvedIPNHasNoLinkedPartsFilter: FilterPredicate = {
    matches: (bomItem) => {
        return bomItem.approvedPartOptions.some((part) => {
            if (isOtsComponentFull(part)) {
                return part.matches.length === 0;
            }
            return false;
        });
    },
    filterId: FilterId.ApprovedIPNHasNoLinkedParts,
};

const missingDataWithSuggestionsFilter: FilterPredicate = {
    matches: (bomItem) => {
        return bomItem.generalProperties.includes(BomItemGeneralProperties.MissingDataWithSuggestions);
    },
    filterId: FilterId.MissingDataWithSuggestions,
};

const fewerPartOptionsAddedThanAlternativesInOriginalBom: FilterPredicate = {
    matches: (bomItem) => {
        return bomItem.generalProperties.includes(
            BomItemGeneralProperties.FewerPartOptionsAddedThanAlternativesInOriginalBOM,
        );
    },
    filterId: FilterId.FewerPartOptionsAddedThanAlternativesInOriginalBom,
};

const lifecycleIssueFilter: FilterPredicate = {
    matches: (bomItem) => {
        if (!bomItem.lifecycleStatus) return false;
        return isLifecycleError(bomItem.lifecycleStatus);
    },
    filterId: FilterId.LifecycleIssue,
};

const complianceIssueFilter: FilterPredicate = {
    matches: (bomItem, context) => {
        const aecqComplianceError = context.isAutomotiveAssembly
            ? bomItem.aecqCompliant
                ? isComplianceError(bomItem.aecqCompliant)
                : false
            : false;
        return (
            (bomItem.reachCompliant ? isComplianceError(bomItem.reachCompliant) : false) ||
            (bomItem.rohsCompliant ? isComplianceError(bomItem.rohsCompliant) : false) ||
            aecqComplianceError
        );
    },
    filterId: FilterId.ComplianceIssue,
};

export function hasGenericsWithoutMatches({ approvedPartOptions }: { approvedPartOptions: FullPart[] }): boolean {
    return approvedPartOptions.some((part) => {
        if (isGenericFullPart(part)) {
            return part.matches.length === 0;
        }
        return false;
    });
}

const approvedGenericPartHasNoMpnMatchesFilter: FilterPredicate = {
    matches: (bomItem) => {
        return hasGenericsWithoutMatches(bomItem);
    },
    filterId: FilterId.ApprovedGenericPartHasNoMpnMatches,
};

const consignedDesignItemFilter: FilterPredicate = {
    matches: (bomItem) => {
        return bomItem.isConsigned;
    },
    filterId: FilterId.ConsignedDesignItem,
};

const insufficientStockFilter: FilterPredicate = {
    matches: (bomItem) => {
        if (!bomItem.availability) {
            return false;
        }

        if (bomItem.availability.type === 'InsufficientStock' || bomItem.availability.type === 'OutOfStock') {
            return true;
        }
        return false;
    },
    filterId: FilterId.InsufficientStock,
};

const thtMountingFilter: FilterPredicate = {
    matches: ({ doNotPlace, approvedPartOptions }) => {
        if (doNotPlace || approvedPartOptions.length === 0) {
            return false;
        }
        if (!hasAtLeastOnePartWithMounting({ approvedPartOptions })) {
            return false;
        }
        return approvedPartOptions.every((part) => {
            if (isOtsComponentFull(part)) {
                return (
                    part.component_specification?.form_and_fit?.mounting === 'THT' ||
                    !part.component_specification?.form_and_fit?.mounting
                );
            }
            if (isOtsFullPart(part)) {
                return part.package?.mounting === 'THT' || !part.package?.mounting;
            }
            if (isGenericFullPart(part) && part.matches.length > 0) {
                return part.matches.every((match) => match.package?.mounting === 'THT' || !match.package?.mounting);
            }
            return false;
        });
    },
    filterId: FilterId.THTMounting,
};

const smtMountingFilter: FilterPredicate = {
    matches: ({ doNotPlace, approvedPartOptions }) => {
        if (doNotPlace || approvedPartOptions.length === 0) {
            return false;
        }
        if (!hasAtLeastOnePartWithMounting({ approvedPartOptions })) {
            return false;
        }
        return approvedPartOptions.every((part) => {
            if (isOtsComponentFull(part)) {
                return (
                    part.component_specification?.form_and_fit?.mounting === 'SMT' ||
                    !part.component_specification?.form_and_fit?.mounting
                );
            }
            if (isOtsFullPart(part)) {
                return part.package?.mounting === 'SMT' || !part.package?.mounting;
            }
            if (isGenericFullPart(part) && part.matches.length > 0) {
                return part.matches.every((match) => match.package?.mounting === 'SMT' || !match.package?.mounting);
            }
            return false;
        });
    },
    filterId: FilterId.SMTMounting,
};

const hasAtLeastOnePartWithMounting = ({ approvedPartOptions }: { approvedPartOptions: FullPart[] }): boolean => {
    return approvedPartOptions.some((part) => {
        if (isOtsComponentFull(part)) {
            return !!part.component_specification?.form_and_fit?.mounting;
        }
        if (isOtsFullPart(part)) {
            return !!part.package?.mounting;
        }
        if (isGenericFullPart(part) && part.matches.length > 0) {
            return part.matches.some((match) => {
                return !!match.package?.mounting;
            });
        }
        return false;
    });
};

const filterIfOtherMounting: FilterPredicate = {
    matches: ({ doNotPlace, approvedPartOptions, specification }) => {
        if (doNotPlace || approvedPartOptions.length === 0) {
            return false;
        }

        // custom part package should be marked as other.
        if (specification?.type === PartSpecificationTypes.Custom) {
            return true;
        }

        if (!hasAtLeastOnePartWithMounting({ approvedPartOptions })) {
            return false;
        }
        return approvedPartOptions.every((part) => {
            if (isOtsComponentFull(part)) {
                return (
                    part.component_specification?.form_and_fit?.mounting !== 'SMT' &&
                    part.component_specification?.form_and_fit?.mounting !== 'THT'
                );
            }
            if (isOtsFullPart(part)) {
                return part.package?.mounting !== 'SMT' && part.package?.mounting !== 'THT';
            }
            if (isGenericFullPart(part) && part.matches.length > 0) {
                return part.matches.every((match) => {
                    return match.package?.mounting !== 'SMT' && match.package?.mounting !== 'THT';
                });
            }
            return false;
        });
    },
    filterId: FilterId.OtherMounting,
};

const filterIfMissingPins: FilterPredicate = {
    matches: (bomItem) => {
        return bomItem.partDataIssues.includes(BomItemPartDataIssues.PinsMissing);
    },
    filterId: FilterId.PinsMissing,
};

const filterIfMissingMounting: FilterPredicate = {
    matches: (bomItem) => {
        return bomItem.partDataIssues.includes(BomItemPartDataIssues.MountingMissing);
    },
    filterId: FilterId.MountingMissing,
};

const filterIfMissingPackageName: FilterPredicate = {
    matches: (bomItem) => {
        return bomItem.partDataIssues.includes(BomItemPartDataIssues.PackageNameMissing);
    },
    filterId: FilterId.PackageNameMissing,
};

const filterIfMissingPartData: FilterPredicate = {
    matches: (bomItem) => {
        // This filter uses the issues, because, we only ever consider
        // missingPartData in the BomItemSummary, for which we need to consider the BOM item warnings configuration.
        return bomItem.issues.includes(BomItemIssue.MissingPartData);
    },
    filterId: FilterId.MissingPartData,
};

const filterPackageMismatch: FilterPredicate = {
    matches: (bomItem) => {
        return bomItem.partDataIssues.some((issue) =>
            [BomItemPartDataIssues.PackageMismatch, BomItemPartDataIssues.PackageNameOnlyMismatch].includes(issue),
        );
    },
    filterId: FilterId.PackageMismatch,
};

export function hasBomItemNoParts({ bomItem }: { bomItem: BomItem }): boolean {
    if (bomItem.doNotPlace) {
        return false;
    }
    return bomItem.parts.length === 0;
}

const allFilters: FilterPredicate[] = [
    unResolvedCommentsFilter,
    resolvedCommentsFilter,
    designatorAndQuantityUnclearFilter,
    noPartOptionsApprovedFilter,
    approvedBomItemsWithPartialMatchesFilter,
    approvedPartOptionsWithNoIPnsFilter,
    approvedIPNHasNoLinkedPartsFilter,
    missingDataWithSuggestionsFilter,
    fewerPartOptionsAddedThanAlternativesInOriginalBom,
    lifecycleIssueFilter,
    complianceIssueFilter,
    changesFromPreviousImportFilter,
    approvedGenericPartHasNoMpnMatchesFilter,
    consignedDesignItemFilter,
    insufficientStockFilter,
    thtMountingFilter,
    smtMountingFilter,
    filterIfOtherMounting,
    filterIfMissingPins,
    filterPackageMismatch,
    filterIfMissingMounting,
    filterIfMissingPackageName,
    filterIfMissingPartData,
];

export type FilterContext = {
    isAutomotiveAssembly: boolean;
};

export const getAllBomItemFilters = (bomItem: BomItem, context: FilterContext): Set<FilterId> => {
    const result = new Set<FilterId>();
    for (const filter of allFilters) {
        if (filter.matches(bomItem, context)) {
            result.add(filter.filterId);
        }
    }
    return result;
};

export const filterLabel = (filter: FilterId) => {
    switch (filter) {
        case FilterId.UnresolvedComments:
            return t`Unresolved comments`;
        case FilterId.ResolvedComments:
            return t`Resolved comments`;
        case FilterId.DesignatorAndQuantityUnclear:
            return t`Designator and quantity unclear`;
        case FilterId.NoPartOptionsApproved:
            return t`No part options approved`;
        case FilterId.ApprovedBomItemsWithPartialMatches:
            return t`Approved BOM items with partial matches`;
        case FilterId.ApprovedPartOptionsWithNoIPns:
            return t`Approved part options do not include IPNs`;
        case FilterId.ApprovedIPNHasNoLinkedParts:
            return t`Approved IPN has no linked parts`;
        case FilterId.LifecycleIssue:
            return t`Lifecycle issue`;
        case FilterId.ComplianceIssue:
            return t`Compliance issues`;
        case FilterId.HasChangesFromPreviousImport:
            return t`Changes from previous BOM import with same original Excel lines`;
        case FilterId.ApprovedGenericPartHasNoMpnMatches:
            return t`Approved generic part has no MPN matches`;
        case FilterId.ConsignedDesignItem:
            return t`Consigned design item`;
        case FilterId.InsufficientStock:
            return t`Insufficient stock`;
        case FilterId.THTMounting:
            return t`THT mounting`;
        case FilterId.SMTMounting:
            return t`SMT mounting`;
        case FilterId.OtherMounting:
            return t`Other mounting`;
        case FilterId.PinsMissing:
            return t`Pins missing`;
        case FilterId.MountingMissing:
            return t`Mounting missing`;
        case FilterId.PackageNameMissing:
            return t`Package missing`;
        case FilterId.PackageMismatch:
            return t`Inconsistent mounting, package or number of pins`;
        case FilterId.HealthDashboardFilters:
            return t`Health dashboard filters`;
        case FilterId.MissingPartData:
            return t`Missing part data`;
        case FilterId.MissingDataWithSuggestions:
            return t`Missing data with suggestions`;
        case FilterId.FewerPartOptionsAddedThanAlternativesInOriginalBom:
            return t`Fewer part options added than alternatives in original BOM`;
        default:
            assertUnreachable(filter);
    }
};

function parseFilterId(filterId: string): FilterId | undefined {
    const filterIdInt = parseInt(filterId);
    if (isNaN(filterIdInt)) {
        return undefined;
    }

    if (filterIdInt in FilterId) {
        return filterIdInt;
    }

    return undefined;
}

export const useBomFilters = (
    queryParamFilters: string | null | undefined,
): [Set<FilterId>, (filters: Set<FilterId>) => void] => {
    const history = useHistory();
    const location = useLocation();

    const filters = React.useMemo(() => {
        const filters = (queryParamFilters ?? '')
            .split(',')
            .map((issueId) => parseFilterId(issueId))
            .filter(isPresent);
        return new Set(filters);
    }, [queryParamFilters]);

    const setFilters = (newFilters: Set<FilterId>) => {
        const search = new URLSearchParams(location.search);
        search.set('filters', Array.from(newFilters).join(','));
        history.replace({ pathname: location.pathname, search: search.toString() });
    };

    return [filters, setFilters];
};
