import { PrimaryButton } from '@luminovo/design-system';
import { QuoteRequestDTO, QuoteRequestStatus } from '@luminovo/http-client';
import { usePageParams } from '../../../resources/hooks';
import { route } from '../../../utils/routes';

export function ButtonSendQuoteRequests({ quoteRequests }: { quoteRequests: QuoteRequestDTO[] }) {
    const { negotiationId } = usePageParams<'/negotiations/:negotiationId'>();

    const sendable = quoteRequests.filter((s) => s.status === QuoteRequestStatus.NotSent);
    if (sendable.length === 0) {
        return null;
    }

    // FIXME: Will break when to many quote requests are selected because we store it in the URL
    return (
        <PrimaryButton
            size="medium"
            href={route(
                '/negotiations/:negotiationId/quote-requests/preview-email',
                {
                    negotiationId,
                },
                {
                    quoteRequestIds: quoteRequests.map((s) => s.id).join(','),
                },
            )}
        >
            {sendable.length > 1 ? `Send ${sendable.length} requests` : 'Send request'}
        </PrimaryButton>
    );
}
