import { t } from '@lingui/macro';
import { isPresent, isUuid, transEnum, useMemoCompare } from '@luminovo/commons';
import {
    FieldDateControlled,
    FieldSelectControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
    Message,
    NonIdealState,
    PrimaryButton,
    TanStackTable,
    Text,
    colorSystem,
} from '@luminovo/design-system';
import {
    CustomComponentFull,
    CustomFullPart,
    OtsComponentFull,
    OtsFullPart,
    PriceType,
    RfqContext,
    StandardPartTypes,
    ValidFor,
    isOtsComponentFull,
} from '@luminovo/http-client';
import {
    findBestMatchingOtsPart,
    formatPart,
    formatSupplierAndStockLocationDTO,
    formatValidFor,
} from '@luminovo/sourcing-core';
import { ErrorOutlineRounded, ReplayRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import React from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { LayoutCard } from '../../../../components/LayoutCard';
import { useCustomer } from '../../../../resources/customer/customerHandler';
import { priceTypeTranslations } from '../../../../resources/offer/i18n';
import {
    useCustomComponentsBulk,
    useCustomPartsBulk,
    useIpnBulk,
    useOtsPartBulk,
    useOtsPartByMpn,
} from '../../../../resources/part/partHandler';
import { useQuoteTracking } from '../../../../resources/quoteTracking/quoteTrackingHandler';
import { useRfQ } from '../../../../resources/rfq/rfqHandler';
import {
    useSupplierAndStockLocations,
    useSupplierAndStockLocationsOptions,
} from '../../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { useQuoteLineTableState } from './QuoteLineTable';
import {
    CustomPartQuoteHeader,
    CustomPartQuoteImportResult,
    CustomPartQuoteLineRow,
    CustomPartQuoteLineTableData,
    QuoteImportResult,
    QuoteImporterFormState,
    StandardPartQuoteHeader,
    StandardPartQuoteImportResult,
    StandardPartQuoteLineRow,
    StandardPartQuoteLineTableData,
} from './types';

const SupplierFormItem: React.FunctionComponent = () => {
    const { control } = useFormContext<QuoteImporterFormState>();
    const { data, isLoading } = useSupplierAndStockLocationsOptions();

    const { options, preferredSuppliers, approvedSuppliers } = data ?? {
        options: [],
        preferredSuppliers: [],
        approvedSuppliers: [],
    };

    return (
        <FormItem label={t`Supplier`} required>
            <FieldSelectControlled
                control={control}
                name="supplierAndStockLocation"
                required
                FieldProps={{
                    size: 'small',
                    options,
                    disabled: isLoading,
                    virtualized: true,
                    getOptionLabel: (option) => formatSupplierAndStockLocationDTO(option),
                    groupBy: (opt) => {
                        if (preferredSuppliers.some((s) => s.id === opt.id)) {
                            return t`Preferred suppliers`;
                        }
                        if (approvedSuppliers.some((s) => s.id === opt.id)) {
                            return t`Approved suppliers`;
                        }
                        return t`Not approved suppliers`;
                    },
                    renderOption: (option) => (
                        <Flexbox gap={'4px'} alignItems={'baseline'}>
                            {formatSupplierAndStockLocationDTO(option)}
                            <Text variant="body-small" color={colorSystem.neutral[6]}>
                                {option.supplier_number}
                            </Text>
                        </Flexbox>
                    ),
                }}
            />
        </FormItem>
    );
};

const OfferNumberFormItem: React.FunctionComponent = () => {
    const { control } = useFormContext<QuoteImporterFormState>();

    return (
        <FormItem label={t`Offer number`}>
            <FieldTextControlled control={control} name="offerNumber" FieldProps={{ size: 'small' }} />
        </FormItem>
    );
};

const OfferValidityFormItem: React.FunctionComponent = () => {
    const { control } = useFormContext<QuoteImporterFormState>();

    return (
        <FormItem label={t`Valid until`}>
            <FieldDateControlled
                control={control}
                name="offerValidity"
                FieldProps={{
                    size: 'small',
                    helperText: t`Will only be applied if the 'Valid Until' column is empty`,
                }}
            />
        </FormItem>
    );
};

const ValidForFormItem: React.FunctionComponent = () => {
    const { control } = useFormContext<QuoteImporterFormState>();
    const rfq = useWatch({ control, name: 'rfq' });
    const customer = useWatch({ control, name: 'customer' });
    const lines = useWatch({ control, name: 'lines' });
    const isCustomPartImport = lines.every((line) => line.type === 'CustomPartLine');

    return (
        <FormItem label={t`Valid for`}>
            <FieldSelectControlled
                control={control}
                name="validFor"
                FieldProps={{
                    size: 'small',
                    options: Object.values(ValidFor),
                    getOptionLabel: (option) => formatValidFor(option, { rfq, customer }),
                    disableClearable: true,
                    disabled: isCustomPartImport,
                }}
            />
        </FormItem>
    );
};

const PriceTypeFormItem: React.FunctionComponent = () => {
    const { control } = useFormContext<QuoteImporterFormState>();

    return (
        <FormItem label={t`Price type`} required>
            <FieldSelectControlled
                control={control}
                name="priceType"
                required
                FieldProps={{
                    size: 'small',
                    options: Object.values(PriceType).filter(
                        (x) => x !== PriceType.PurchasePrice && x !== PriceType.StandardPrice,
                    ),
                    getOptionLabel: (option) => transEnum(option, priceTypeTranslations),
                    disableClearable: true,
                }}
            />
        </FormItem>
    );
};

export const QuoteImporterFrom: React.FunctionComponent<{
    onReset: () => void;
}> = ({ onReset }) => {
    const { trigger, setValue, control } = useFormContext<QuoteImporterFormState>();
    const { errors } = useFormState<QuoteImporterFormState>();
    const lines = useWatch({ control, name: 'lines' });
    const { table } = useQuoteLineTableState();

    // Trigger validation on mount
    React.useEffect(() => {
        trigger();
    }, [trigger]);

    // Deselect all rows with errors
    React.useEffect(() => {
        table.getCoreRowModel().rows.forEach((row) => {
            if (row.getIsSelected() && row.original.status.includes('errors')) {
                row.toggleSelected(false);
            }
        });
    }, [errors, lines, table]);

    // Update selectedIndices when selection changes
    const selectedIndices = table.getSelectedRowModel().rows.map((row) => row.original.index);
    React.useEffect(() => {
        setValue('selectedIndices', selectedIndices);
    }, [setValue, selectedIndices]);

    React.useEffect(() => {
        table.getCoreRowModel().rows.forEach((row) => {
            if (!row.original.status.includes('errors')) {
                row.toggleSelected(true);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <LayoutCard
            title={undefined}
            style={{ marginTop: '24px', marginLeft: '32px', marginRight: '32px', height: '100%' }}
        >
            <QuoteImporterWarning />
            <Flexbox flexDirection={'column'} width={'100%'} gap={'12px'} height={'100%'}>
                <Box gap={'24px'} display="grid" gridTemplateColumns="2fr 1fr 1fr 1fr 1fr 1fr">
                    <SupplierFormItem />
                    <OfferNumberFormItem />
                    <OfferValidityFormItem />
                    <ValidForFormItem />
                    <PriceTypeFormItem />
                </Box>
                <Flexbox flexDirection={'column'} gap={'12px'} height={'100%'}>
                    <TanStackTable
                        table={table}
                        EmptyPlaceholder={() => (
                            <NonIdealState
                                Icon={ErrorOutlineRounded}
                                title={t`No offers found`}
                                description={t`No offers with a unit price found. Please check the the Excel file and try again.`}
                                action={{
                                    onClick: () => onReset(),
                                    startIcon: <ReplayRounded />,
                                    children: t`Try again`,
                                }}
                                overrides={{ ActionButton: PrimaryButton }}
                            />
                        )}
                    />
                </Flexbox>
            </Flexbox>
        </LayoutCard>
    );
};

const QuoteImporterWarning: React.FunctionComponent = () => {
    const { control } = useFormContext<QuoteImporterFormState>();
    const rfq = useWatch({ control, name: 'rfq' });
    const quoteTracking = useWatch({ control, name: 'quoteTracking' });

    if (!isPresent(rfq) || !isPresent(quoteTracking)) {
        return null;
    }

    if (rfq.id === quoteTracking.rfq) {
        return null;
    }

    return (
        <Message
            size="small"
            variant="yellow"
            attention={'low'}
            title={t`Mismatched RfQ`}
            message={t`This quote was generated from a different RfQ, please ensure the offers are still relevant.`}
        />
    );
};

function extractLumiQuoteId(item: StandardPartQuoteLineRow): StandardPartQuoteLineTableData['requestedPart'] {
    const lumiQuoteId = item.row.lumiQuoteId;

    if (!isPresent(lumiQuoteId)) {
        return undefined;
    }

    if (lumiQuoteId.startsWith('Generic:')) {
        const genericId = lumiQuoteId.replace('Generic:', '');
        if (!isUuid(genericId)) {
            return undefined;
        }

        return {
            type: StandardPartTypes.Generic,
            data: lumiQuoteId.replace('Generic:', ''),
        };
    }

    if (lumiQuoteId.startsWith('Ipn:')) {
        return {
            type: StandardPartTypes.Ipn,
            data: lumiQuoteId.replace('Ipn:', ''),
        };
    }

    if (!isUuid(lumiQuoteId)) {
        return undefined;
    }

    return {
        type: StandardPartTypes.OffTheShelf,
        data: lumiQuoteId,
    };
}

function convertBestMatchToLinkedPart(
    bestMatch: ReturnType<typeof findBestMatchingOtsPart>,
    targetParts: OtsFullPart[],
    requestedOtsPart?: OtsFullPart,
): StandardPartQuoteLineTableData['linkedPart'] {
    if (isPresent(requestedOtsPart) && isPresent(bestMatch.part) && bestMatch.part.id === requestedOtsPart.id) {
        return {
            type: 'OffTheShelf',
            part: requestedOtsPart,
        };
    }

    if (isPresent(bestMatch.part)) {
        if (bestMatch.rating < 1) {
            Sentry.metrics.increment('quote_import.part_match.success', 1, {
                tags: {
                    mainString: bestMatch.mainString,
                    targetString: bestMatch.targetString,
                    rating: bestMatch.rating,
                    formatedPart: formatPart(bestMatch.part),
                },
            });
        }

        return {
            type: 'AlternativePart',
            part: bestMatch.part,
            matches: targetParts,
            requestedOtsPart,
        };
    }

    if (targetParts.length > 0) {
        Sentry.metrics.increment('quote_import.part_match.failure', 1, {
            tags: {
                mainString: bestMatch.mainString,
                targetString: bestMatch.targetString,
                rating: bestMatch.rating,
            },
        });

        return { type: 'AlternativePart', part: null, matches: targetParts, requestedOtsPart };
    }

    Sentry.metrics.increment('quote_import.missing_target_parts', 1, {
        tags: {
            mainString: bestMatch.mainString,
        },
    });
    return { type: 'NonMatching', part: null };
}

function extractLinkedStandardPart(
    standardParts: Array<OtsFullPart | OtsComponentFull>,
    partAlternatives: { alternativeMpn: string; matches: OtsFullPart[] }[],
    item: StandardPartQuoteLineRow,
): Pick<StandardPartQuoteLineTableData, 'requestedPart' | 'linkedPart'> {
    let requestedPart = extractLumiQuoteId(item);
    const requestedStandardPart = standardParts.find((part) => part.id === requestedPart?.data);

    if (requestedStandardPart === undefined) {
        requestedPart = undefined;
    }
    const itemMpn = item.row.mpn?.trim();
    const itemManufacturer = item.row.manufacturer?.trim();
    const alternativeMpn = item.row.alternativeMpn?.trim();
    const alternativeManufacturer = item.row.alternativeManufacturer?.trim();

    if (isOtsComponentFull(requestedStandardPart)) {
        if (isPresent(alternativeMpn)) {
            const targetParts = partAlternatives.flatMap((item) =>
                item.alternativeMpn === alternativeMpn ? item.matches : [],
            );
            const bestMatch = findBestMatchingOtsPart({
                input: { mpn: alternativeMpn, manufacturer: alternativeManufacturer ?? '' },
                targetParts,
                options: { ignoreCase: true },
            });
            return {
                requestedPart,
                linkedPart: convertBestMatchToLinkedPart(bestMatch, targetParts, undefined),
            };
        }

        if (isPresent(itemMpn)) {
            const targetParts = partAlternatives.flatMap((item) =>
                item.alternativeMpn === itemMpn ? item.matches : [],
            );
            const bestMatch = findBestMatchingOtsPart({
                input: { mpn: itemMpn, manufacturer: itemManufacturer ?? '' },
                targetParts,
                options: { ignoreCase: true },
            });
            return {
                requestedPart,
                linkedPart: convertBestMatchToLinkedPart(bestMatch, targetParts, undefined),
            };
        }

        return { requestedPart, linkedPart: { type: 'Ipn', part: requestedStandardPart } };
    }

    if (isPresent(alternativeMpn)) {
        const targetParts = partAlternatives.flatMap((item) =>
            item.alternativeMpn === alternativeMpn ? item.matches : [],
        );
        const bestMatch = findBestMatchingOtsPart({
            input: { mpn: alternativeMpn, manufacturer: alternativeManufacturer ?? '' },
            targetParts,
            options: { ignoreCase: true },
        });
        return {
            requestedPart,
            linkedPart: convertBestMatchToLinkedPart(bestMatch, targetParts, requestedStandardPart),
        };
    }

    if (isPresent(itemMpn)) {
        const targetParts = partAlternatives.flatMap((item) => (item.alternativeMpn === itemMpn ? item.matches : []));
        const bestMatch = findBestMatchingOtsPart({
            input: { mpn: itemMpn, manufacturer: itemManufacturer ?? '' },
            targetParts,
            options: { ignoreCase: true },
        });
        return {
            requestedPart,
            linkedPart: convertBestMatchToLinkedPart(bestMatch, targetParts, requestedStandardPart),
        };
    }

    Sentry.metrics.increment('quote_import.missing_mpn', 1, {
        tags: {
            item: JSON.stringify(item),
        },
    });
    return {
        requestedPart,
        linkedPart: { type: 'NonMatching', part: null },
    };
}

function extractLinkedCustomPart(
    customParts: Array<CustomFullPart>,
    customComponents: Array<CustomComponentFull>,
    item: CustomPartQuoteLineRow,
): Pick<CustomPartQuoteLineTableData, 'linkedPart' | 'requestedPart'> {
    const requestedPart = extractCustomPartLumiQuoteId(item);

    if (requestedPart?.type === 'CustomPart') {
        const customPart = customParts.find((part) => part.id === requestedPart.id);

        if (isPresent(customPart)) {
            return {
                linkedPart: { type: 'CustomPart', part: customPart },
                requestedPart,
            };
        }
    }

    if (requestedPart?.type === 'CustomComponent') {
        const customComponent = customComponents.find((part) => part.id === requestedPart.id);

        if (isPresent(customComponent)) {
            return {
                linkedPart: { type: 'CustomComponent', part: customComponent },
                requestedPart,
            };
        }
    }

    return {
        linkedPart: { type: 'NonMatching', part: null },
        requestedPart,
    };
}

function extractCustomPartLumiQuoteId(item: CustomPartQuoteLineRow): CustomPartQuoteLineTableData['requestedPart'] {
    const lumiQuoteId = item.row.lumiQuoteId;

    if (!isPresent(lumiQuoteId)) {
        return undefined;
    }

    if (lumiQuoteId.startsWith('Ipn:')) {
        return {
            type: 'CustomComponent',
            id: lumiQuoteId.replace('Ipn:', ''),
        };
    }

    if (!isUuid(lumiQuoteId)) {
        return undefined;
    }

    return {
        type: 'CustomPart',
        id: lumiQuoteId,
    };
}

function useCustomPartQuoteImporterFormDefaultValues({
    quoteImportResult,
    rfqId,
    rfqContext,
}: {
    quoteImportResult: CustomPartQuoteImportResult | undefined;
    rfqId: string;
    rfqContext: RfqContext;
}): QuoteImporterFormState | undefined {
    const { data: rfq } = useRfQ(rfqId);
    const { data: suppliers } = useSupplierAndStockLocations();
    const { data: quoteTracking = null, isLoading: isLoadingQuoteTracking } = useQuoteTracking({
        quoteTrackingId: quoteImportResult?.quoteTrackingId,
    });
    const { data: customer } = useCustomer(rfq?.customer);

    let customPartIds: string[] = [];
    let customComponentIds: string[] = [];

    const rows = quoteImportResult?.quoteLineRow
        .filter(
            (quoteLine) =>
                quoteLine.row.unitPrice !== undefined ||
                quoteLine.warnings.some((w) => w.column === CustomPartQuoteHeader.UnitPrice),
        )
        .map((quoteLine) => {
            const part = extractCustomPartLumiQuoteId(quoteLine);

            if (part?.type === 'CustomPart') {
                const customPartId = part.id;
                customPartIds.push(customPartId);
                return {
                    line: quoteLine,
                    customPartId,
                    customComponentId: null,
                };
            }

            if (part?.type === 'CustomComponent') {
                const customComponentId = part.id;
                customComponentIds.push(customComponentId);
                return {
                    line: quoteLine,
                    customPartId: null,
                    customComponentId,
                };
            }

            return {
                line: quoteLine,
                customPartId: null,
                customComponentId: null,
            };
        });

    const { data: customPartParts, isLoading: isLoadingCustomParts } = useCustomPartsBulk(customPartIds);
    const { data: customComponents, isLoading: isLoadingCustomComponents } = useCustomComponentsBulk(
        customComponentIds,
        rfqContext,
    );

    return useMemoCompare(
        () => {
            if (
                !customer ||
                !customPartParts ||
                !customComponents ||
                !quoteImportResult ||
                !rfq ||
                !rows ||
                !suppliers ||
                isLoadingQuoteTracking ||
                isLoadingCustomParts ||
                isLoadingCustomComponents
            ) {
                return undefined;
            }

            const supplierAndStockLocation = suppliers.find((s) => {
                if (!isPresent(quoteImportResult.supplierName)) {
                    return false;
                }

                return (
                    formatSupplierAndStockLocationDTO(s).includes(quoteImportResult.supplierName) ||
                    s.supplier.alternative_names.includes(quoteImportResult.supplierName) ||
                    s.id === quoteImportResult.supplierAndStockLocationId
                );
            });

            if (!isPresent(supplierAndStockLocation)) {
                Sentry.metrics.increment('quote_import.supplier_lookup.failure', 1, {
                    tags: {
                        supplierName: quoteImportResult.supplierName,
                        supplierAndStockLocationId: quoteImportResult.supplierAndStockLocationId,
                        numberOfSuppliers: suppliers.length,
                    },
                });
            }

            const lines: CustomPartQuoteLineTableData[] = rows.map(({ line }, index) => {
                const { requestedPart, linkedPart } = extractLinkedCustomPart(customPartParts, customComponents, line);

                return {
                    type: 'CustomPartLine',
                    index,
                    requestedPart,
                    linkedPart,
                    row: line.row,
                    warnings: line.warnings,
                    excelRowNumber: line.excelRowNumber,
                };
            });

            return {
                state: 'ready',
                file: quoteImportResult.file,
                quoteTracking,
                supplierAndStockLocation,
                offerNumber: quoteImportResult.offerNumber,
                offerValidity: quoteImportResult.offerValidity,
                validFor: ValidFor.ThisRfQ,
                priceType: PriceType.QuotePrice,
                lines,
                selectedIndices: [],
                rfq,
                customer,
            };
        },
        {
            customer,
            customPartParts,
            customComponents,
            isLoadingCustomParts,
            isLoadingQuoteTracking,
            quoteTracking,
            rfq,
            rows,
            suppliers,
        },
    );
}

function useStandardPartQuoteImporterFormDefaultValues({
    quoteImportResult,
    rfqContext,
    rfqId,
}: {
    quoteImportResult: StandardPartQuoteImportResult | undefined;
    rfqContext: RfqContext;
    rfqId: string;
}): QuoteImporterFormState | undefined {
    const { data: rfq } = useRfQ(rfqId);
    const { data: suppliers } = useSupplierAndStockLocations();
    const { data: quoteTracking = null, isLoading: isLoadingQuoteTracking } = useQuoteTracking({
        quoteTrackingId: quoteImportResult?.quoteTrackingId,
    });
    const { data: customer } = useCustomer(rfq?.customer);

    const rows = quoteImportResult?.quoteLineRow
        .filter(
            (quoteLine) =>
                quoteLine.row.unitPrice !== undefined ||
                quoteLine.warnings.some((w) => w.column === StandardPartQuoteHeader.UnitPrice),
        )
        .map((quoteLine) => {
            const requestedPart = extractLumiQuoteId(quoteLine);
            return {
                line: quoteLine,
                otsPartId: requestedPart?.type === StandardPartTypes.OffTheShelf ? requestedPart.data : undefined,
                mpn: quoteLine.row.mpn,
                alternativeMpn: quoteLine.row.alternativeMpn,
            };
        });

    const otsPartIds = rows?.flatMap((item) => {
        const requestedPart = extractLumiQuoteId(item.line);
        if (requestedPart?.type === StandardPartTypes.OffTheShelf) {
            return [requestedPart.data];
        }
        return [];
    });
    const ipnIds = rows?.flatMap((item) => {
        const requestedPart = extractLumiQuoteId(item.line);
        if (requestedPart?.type === StandardPartTypes.Ipn) {
            return [requestedPart.data];
        }
        return [];
    });

    const mpns = rows?.flatMap((item) => (item.mpn ? [item.mpn] : [])) ?? [];
    const alternativeMpns = rows?.flatMap((item) => (item.alternativeMpn ? [item.alternativeMpn] : [])) ?? [];

    const { data: otsParts, isLoading: isLoadingOtsParts } = useOtsPartBulk(otsPartIds, rfqContext);
    const { data: ipns, isLoading: isLoadingIpn } = useIpnBulk(ipnIds, rfqContext);
    const { data: partAlternatives } = useOtsPartByMpn({
        mpns: [...mpns, ...alternativeMpns],
        rfqContext,
    });

    return useMemoCompare(
        () => {
            if (
                !customer ||
                !otsParts ||
                !ipns ||
                !partAlternatives ||
                !quoteImportResult ||
                !rfq ||
                !rows ||
                !suppliers ||
                isLoadingOtsParts ||
                isLoadingIpn ||
                isLoadingQuoteTracking
            ) {
                return undefined;
            }

            const supplierAndStockLocation = suppliers.find((s) => {
                if (!isPresent(quoteImportResult.supplierName)) {
                    return false;
                }

                return (
                    formatSupplierAndStockLocationDTO(s).replace('· ', '') === quoteImportResult.supplierName ||
                    s.supplier.alternative_names.includes(quoteImportResult.supplierName) ||
                    s.id === quoteImportResult.supplierAndStockLocationId
                );
            });

            if (!isPresent(supplierAndStockLocation)) {
                Sentry.metrics.increment('quote_import.supplier_lookup.failure', 1, {
                    tags: {
                        supplierName: quoteImportResult.supplierName,
                        supplierAndStockLocationId: quoteImportResult.supplierAndStockLocationId,
                        numberOfSuppliers: suppliers.length,
                    },
                });
            }

            const lines: StandardPartQuoteLineTableData[] = rows.map(({ line }, index) => {
                const { requestedPart, linkedPart } = extractLinkedStandardPart(
                    [...otsParts, ...ipns],
                    partAlternatives,
                    line,
                );

                return {
                    type: 'StandardPartLine',
                    index,
                    requestedPart,
                    linkedPart,
                    row: line.row,
                    warnings: line.warnings,
                    excelRowNumber: line.excelRowNumber,
                };
            });
            return {
                state: 'ready',
                file: quoteImportResult.file,
                quoteTracking,
                supplierAndStockLocation,
                offerNumber: quoteImportResult.offerNumber,
                offerValidity: quoteImportResult.offerValidity,
                validFor: ValidFor.ThisRfQ,
                priceType: PriceType.QuotePrice,
                lines,
                selectedIndices: [],
                rfq,
                customer,
            };
        },
        {
            customer,
            isLoadingOtsParts,
            isLoadingQuoteTracking,
            otsParts,
            partAlternatives,
            quoteImportResult,
            quoteTracking,
            rfq,
            rows,
            suppliers,
        },
    );
}

export function useExcelQuoteImporterDefaultValues(
    quoteImportResult: QuoteImportResult | undefined,
    rfqId: string,
): QuoteImporterFormState {
    const rfqContext: RfqContext = {
        type: 'WithinRfQ',
        rfq_id: rfqId,
    };

    const isStandardPartImport = quoteImportResult?.quoteLineRow.every((line) => line.type === 'StandardPartLine');
    const isCustomPartImport = quoteImportResult?.quoteLineRow.every((line) => line.type === 'CustomPartLine');

    const standardPartDefaultValues = useStandardPartQuoteImporterFormDefaultValues({
        quoteImportResult: isStandardPartImport ? (quoteImportResult as StandardPartQuoteImportResult) : undefined,
        rfqContext,
        rfqId,
    });

    const customPartDefaultValues = useCustomPartQuoteImporterFormDefaultValues({
        quoteImportResult: isCustomPartImport ? (quoteImportResult as CustomPartQuoteImportResult) : undefined,
        rfqId,
        rfqContext,
    });

    if (!isPresent(quoteImportResult)) {
        return {
            state: 'incomplete',
        } as QuoteImporterFormState;
    }

    if (!standardPartDefaultValues && !customPartDefaultValues) {
        return {
            state: 'loading',
        } as QuoteImporterFormState;
    }

    if (isStandardPartImport && isPresent(standardPartDefaultValues)) {
        return standardPartDefaultValues;
    }

    if (isCustomPartImport && isPresent(customPartDefaultValues)) {
        return customPartDefaultValues;
    }

    return {
        state: 'incomplete',
    } as QuoteImporterFormState;
}
