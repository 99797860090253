import { Trans } from '@lingui/macro';
import { sortBy } from '@luminovo/commons';
import { Text } from '@luminovo/design-system';
import { BomImporterIssue, BomScreeningSheet, Level } from '@luminovo/http-client';
import { id } from '../../../utils/ids';
import { ValidationError } from '../../DesignItemDetails/components/AutocompleteColumnTags/validation';
import { BomIssueItem, ValidationIssueItem } from './bomImporterDialogComponents';
import { BomImporterSidebarContainer } from './BomImporterSidebarContainer';
import { consolidateDuplicatedDesignators } from './consolidateDuplicatedDesignators';

type BomImporterDetailsIssue =
    | {
          type: 'global-issue-response';
          data: BomImporterIssue;
      }
    | {
          type: 'validation-error';
          data: ValidationError;
      };

export const BomImporterDetails = ({
    bomScreeningSheet,
    validationErrors,
}: {
    bomScreeningSheet: BomScreeningSheet;
    validationErrors: ValidationError[];
}) => {
    const excelLines = bomScreeningSheet.excel_lines;
    const duplicatedDesignatorsIssue = consolidateDuplicatedDesignators(excelLines);

    const details: BomImporterDetailsIssue[] = [
        ...bomScreeningSheet.global_issues_response.map((e): BomImporterDetailsIssue => {
            return { type: 'global-issue-response', data: e };
        }),
        ...validationErrors.map((e): BomImporterDetailsIssue => {
            return { type: 'validation-error', data: e };
        }),
    ];

    const sortedDetails = sortBy(details, [
        (c) => c.data.level === Level.Notification,
        (c) => c.data.level === Level.Warning,
        (c) => c.data.level === Level.Error,
    ]);

    if (sortedDetails.length === 0) {
        return <></>;
    }

    return (
        <BomImporterSidebarContainer id={id('bom_importer/box_header')}>
            <Text variant="h5">
                <Trans>Details</Trans>
            </Text>

            {duplicatedDesignatorsIssue && <BomIssueItem issue={duplicatedDesignatorsIssue} />}
            {sortedDetails.map((d) => {
                if (d.type === 'global-issue-response') {
                    return <BomIssueItem key={`globalIssues:${d.data.name}`} issue={d.data} />;
                }
                if (d.type === 'validation-error') {
                    return (
                        <ValidationIssueItem
                            key={`validationErrors:${d.data.message}`}
                            message={d.data.message}
                            level={d.data.level}
                        />
                    );
                }
                return null;
            })}
        </BomImporterSidebarContainer>
    );
};
