import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { Currency } from '@luminovo/commons';

export interface GlobalCurrencySettingsForm {
    currency: Currency | '';
    /**
     * Manually set exchange rates for example:
     *
     * ```
     * {
     *   USD: { EUR: 1.2, MXN: 1.5 } // e.g. the rate from USD -> EUR is 1.2
     *                               // and  the rate from USD -> MXN is 1.5
     *   EUR: { MXN: 1.1 }           // e.g. the rate from EUR -> MXN is 1.2
     * }
     * ```
     */
    manualRates: { [currencyFrom: string]: { [currencyTo: string]: string | null } };
}

export const inputCurrenciesPublicTranslations: Record<Currency, MessageDescriptor> = {
    [Currency.USD]: defineMessage({ message: 'USD' }),
    [Currency.EUR]: defineMessage({ message: 'EUR' }),
    [Currency.AUD]: defineMessage({ message: 'AUD' }),
    [Currency.BGN]: defineMessage({ message: 'BGN' }),
    [Currency.BRL]: defineMessage({ message: 'BRL' }),
    [Currency.CAD]: defineMessage({ message: 'CAD' }),
    [Currency.CHF]: defineMessage({ message: 'CHF' }),
    [Currency.CNY]: defineMessage({ message: 'CNY' }),
    [Currency.CZK]: defineMessage({ message: 'CZK' }),
    [Currency.DKK]: defineMessage({ message: 'DKK' }),
    [Currency.GBP]: defineMessage({ message: 'GBP' }),
    [Currency.HKD]: defineMessage({ message: 'HKD' }),
    [Currency.HRK]: defineMessage({ message: 'HRK' }),
    [Currency.HUF]: defineMessage({ message: 'HUF' }),
    [Currency.IDR]: defineMessage({ message: 'IDR' }),
    [Currency.ILS]: defineMessage({ message: 'ILS' }),
    [Currency.INR]: defineMessage({ message: 'INR' }),
    [Currency.ISK]: defineMessage({ message: 'ISK' }),
    [Currency.JPY]: defineMessage({ message: 'JPY' }),
    [Currency.KRW]: defineMessage({ message: 'KRW' }),
    [Currency.MXN]: defineMessage({ message: 'MXN' }),
    [Currency.MYR]: defineMessage({ message: 'MYR' }),
    [Currency.NOK]: defineMessage({ message: 'NOK' }),
    [Currency.NZD]: defineMessage({ message: 'NZD' }),
    [Currency.PHP]: defineMessage({ message: 'PHP' }),
    [Currency.PLN]: defineMessage({ message: 'PLN' }),
    [Currency.RON]: defineMessage({ message: 'RON' }),
    [Currency.RMB]: defineMessage({ message: 'RMB' }),
    [Currency.RUB]: defineMessage({ message: 'RUB' }),
    [Currency.SEK]: defineMessage({ message: 'SEK' }),
    [Currency.SGD]: defineMessage({ message: 'SGD' }),
    [Currency.THB]: defineMessage({ message: 'THB' }),
    [Currency.TRY]: defineMessage({ message: 'TRY' }),
    [Currency.ZAR]: defineMessage({ message: 'ZAR' }),
    [Currency.TWD]: defineMessage({ message: 'TWD' }),
    [Currency.VND]: defineMessage({ message: 'VND' }),
};
