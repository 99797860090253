import { t } from '@lingui/macro';
import {
    assertUnreachable,
    formatInteger,
    formatToLongDate,
    formatToMonth,
    isPresent,
    lastDayOfMonth,
} from '@luminovo/commons';
import { TanStackTable, Tooltip, createColumnHelper, useTanStackTable } from '@luminovo/design-system';
import { ColumnDefWithSharedContext } from '@luminovo/design-system/src/components/TanStackTable/type';
import { DemandSummaryDTO } from '@luminovo/http-client';
import { TableCell } from '@mui/material';
import { NoDemandsMessage } from '../../components/NoDemandsMessage';
import { DemandTimeframeType, DemandType, ParticipantRole } from './types';

type Row = string[];
type Table = Array<Row>;

interface TableColumn {
    type: 'string';
    id: string;
    label?: string;
    columnIndex: number;
    tooltip?: string;
}

function maxDate(date1: Date, date2: Date): Date {
    return new Date(Math.max(date1.getTime(), date2.getTime()));
}

function minDate(date1: Date, date2: Date): Date {
    return new Date(Math.min(date1.getTime(), date2.getTime()));
}

function dateRangeTooltip(dateStart: Date, dateEnd: Date): string {
    return `${formatToLongDate(dateStart)} - ${formatToLongDate(dateEnd)}`;
}

export function transformDemandSummaryToTable({
    demandSummary,
    demandType,
    participantRole,
    timeframeType,
}: {
    demandSummary: DemandSummaryDTO | undefined;
    demandType: DemandType;
    participantRole: ParticipantRole;
    timeframeType: DemandTimeframeType;
}): {
    tableColumns: TableColumn[];
    table: Table;
} {
    if (!isPresent(demandSummary)) {
        return { tableColumns: [], table: [] };
    }

    let participantDemands = [];
    let label = '';
    switch (participantRole) {
        case 'supplier':
            participantDemands = demandSummary.supplier_demands;
            label = t`Supplier`;
            break;
        case 'recipient':
            participantDemands = demandSummary.recipient_demands;
            label = t`Recipient`;
            break;
        default:
            assertUnreachable(participantRole);
    }

    if (participantDemands.length === 0) {
        return { tableColumns: [], table: [] };
    }

    const timeframes: TableColumn[] = (() => {
        switch (timeframeType) {
            case 'monthly':
                return participantDemands[0].monthly_demands.map((demand, idx) => {
                    const dateStart = new Date(
                        `${demand.month_and_year.year}-${demand.month_and_year.month.toString().padStart(2, '0')}-01`,
                    );
                    return {
                        id: formatToMonth(dateStart),
                        columnIndex: idx + 1,
                        type: 'string' as const,
                        tooltip: dateRangeTooltip(
                            maxDate(dateStart, new Date(demandSummary.start_date)),
                            minDate(lastDayOfMonth(dateStart), new Date(demandSummary.end_date)),
                        ),
                    };
                });
            case 'yearly':
                return participantDemands[0].yearly_demands.map((demand, idx) => {
                    const dateStart = new Date(`${demand.year}-01-01`);
                    return {
                        id: demand.year.toString(),
                        columnIndex: idx + 1,
                        type: 'string' as const,
                        tooltip: dateRangeTooltip(
                            maxDate(dateStart, new Date(demandSummary.start_date)),
                            minDate(lastDayOfMonth(dateStart), new Date(demandSummary.end_date)),
                        ),
                    };
                });
            default:
                assertUnreachable(timeframeType);
        }
    })();

    const tableColumns: TableColumn[] = [
        {
            id: 'Participant',
            columnIndex: 0,
            type: 'string',
            label,
        },
        ...timeframes,
    ];

    const timeframeField: 'monthly_demands' | 'yearly_demands' = (() => {
        switch (timeframeType) {
            case 'monthly':
                return 'monthly_demands';
            case 'yearly':
                return 'yearly_demands';
            default:
                assertUnreachable(timeframeType);
        }
    })();

    const demandTypeField: 'gross_quantity' | 'net_quantity' = (() => {
        switch (demandType) {
            case 'gross':
                return 'gross_quantity';
            case 'net':
                return 'net_quantity';
            default:
                assertUnreachable(demandType);
        }
    })();

    const table: Table = participantDemands.map((participantDemand) => [
        participantDemand.participant_name ?? '',
        ...participantDemand[timeframeField].map((item) => formatInteger(item[demandTypeField].quantity)),
    ]);

    return { tableColumns: tableColumns, table };
}

const columnHelper = createColumnHelper<Row>();

export function DemandSummaryTableDynamic({
    demandSummary,
    demandType,
    participantRole,
    timeframeType,
}: {
    demandSummary: DemandSummaryDTO;
    demandType: DemandType;
    participantRole: ParticipantRole;
    timeframeType: DemandTimeframeType;
}): JSX.Element {
    const { table, tableColumns } = transformDemandSummaryToTable({
        demandSummary,
        demandType,
        participantRole,
        timeframeType,
    });

    let columns: ColumnDefWithSharedContext<Row, string, unknown>[] = [];

    if (table.length > 0) {
        columns = Object.keys(table[0]).map((_key, colIndex) => {
            const selected = tableColumns.find((option) => option.columnIndex === colIndex) ?? null;
            const label = selected ? (selected.label ?? selected.id) : `Column #${colIndex + 1}`;
            const tooltip = selected?.tooltip;
            const header = (
                <TableCell style={{ verticalAlign: 'middle', paddingLeft: 0 }} variant="head">
                    {label}
                </TableCell>
            );
            return columnHelper.text((row) => row[colIndex] ?? '', {
                id: String(colIndex),
                enableColumnFilter: false,
                enableSorting: false,
                enableGlobalFilter: true,
                enableMultiSort: false,
                label: () => label,
                header: function Header() {
                    if (isPresent(tooltip)) {
                        return (
                            <Tooltip title={tooltip} placement="top">
                                {header}
                            </Tooltip>
                        );
                    }
                    return header;
                },
                size: 100,
            });
        });
    }

    const tanstackTable = useTanStackTable({
        columns,
        data: table,
        enablePersistentGlobalFilter: true,
        enableColumnOrdering: false,
        enablePersistentColumnFilters: false,
    });

    return (
        <TanStackTable
            size="medium"
            enableMenuBar={false}
            table={tanstackTable.table}
            EmptyPlaceholder={() => <NoDemandsMessage />}
        />
    );
}
