import { t, Trans } from '@lingui/macro';
import {
    assertPresent,
    indexBy,
    isEqual,
    isPresent,
    sortBy,
    throwErrorUnlessProduction,
    uniqBy,
    useMemoCompare,
} from '@luminovo/commons';
import {
    colorSystem,
    Flexbox,
    MenuButton,
    MenuItem,
    RequestViaMailIcon,
    SecondaryButton,
    Table,
    TanStackMenuBar,
    TanStackTable,
    Text,
} from '@luminovo/design-system';
import {
    ApprovalStatus,
    CustomComponentFull,
    CustomFullPart,
    CustomOptionOfferDTO,
    FullSourcingDTO,
    isOtsFullPart,
    MonetaryValueBackend,
    OtsComponentFull,
    OtsFullPart,
    PartCategoryDTO,
    PurchaseOrderDTO,
    QuoteTrackingLight,
    RfqContext,
    SolutionConfigurationDTO,
    SolutionConfigurationExcelOriginInfo,
    SolutionConfigurationSourcingDTO,
    SolutionStatus,
    SolutionTag,
    SourcingScenarioDTO,
    StandardPartOfferDTO,
    SupplierAndStockLocationDTO,
} from '@luminovo/http-client';
import {
    extractAssemblyName,
    extractCustomPartOptions,
    extractDesignators,
    extractPartDTO,
    extractPartOptions,
    extractSolutionStatus,
    extractStandardPartOptions,
    formatSolutionStatus,
    formatSupplierAndStockLocationDTO,
    hasSolutionTag,
    isConsignedSolution,
    isStandardPartInventoryOffer,
    isStandardPartMarketOffer,
    parseSolution,
    Solution,
    SolutionStatusCircleIcon,
} from '@luminovo/sourcing-core';
import { ArrowDropDownRounded, AutoAwesomeRounded, GetApp, LockOutlined } from '@mui/icons-material';
import { Divider } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router';
import { PageLayout } from '../../../../components/PageLayout';
import { useDownloadQuotePriceTemplate } from '../../../../resources/export/exportHandler';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { useInventorySites } from '../../../../resources/organizationSettings/sitesHandler';
import {
    useCustomPartsFromOffers,
    usePartCategories,
    useStandardPartsFromOffers,
} from '../../../../resources/part/partHandler';
import { getTotalQuantity } from '../../../../resources/sourcingScenario/getTotalQuantity';
import { useSourcingFull, useSourcingScenario } from '../../../../resources/sourcingScenario/sourcingScenarioHandlers';
import { useSupplierAndStockLocations } from '../../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { assertUnreachable } from '../../../../utils/typingUtils';
import { usePersistedState } from '../../../../utils/usePersistedState';
import { useManualCostWarningDialog } from '../../../SolutionManager/components/Sidebar/ButtonSolutionSelection';
import { chartSpecLeadTime } from '../ChartLeadTime';
import { ChartSolutionConfiguration } from '../ChartSolutionConfiguration';
import { FieldId } from '../ChartSpec';
import { chartSpecSuppliers } from '../ChartSuppliers';
import { chartSpecTotalPrice } from '../ChartTotalPrice';
import { chartSpecUnitPrice } from '../ChartUnitPrice';
import { useRequestConfigurationDialog } from '../QuoteExporter/RequestConfigurationDialog';
import { SourcingFiltersSidebar } from '../SourcingFiltersSidebar';
import { SourcingListDetailsLayout } from '../SourcingListDetailsLayout';
import SourcingScenarioSummaryBox from '../SourcingScenarios/SourcingScenarioSummaryBox';
import { EditScrapQuantityButton, useScrapQuantityHandlers } from './EditScrapQuantityButton';
import { useSolutionConfigurationTableState } from './SolutionConfigurationTable';
import { SolutionConfigurationsTableContext, SolutionConfigurationsTableData } from './solutionConfigurationTypes';
import { SourcingConfigurationContentHeader } from './SourcingConfigurationContentHeader';
import { ToolbarSourcingScenario } from './ToolbarSourcingScenario';

interface SolutionConfigurationsProps {
    rfqId: string;
    sourcingScenarioDTO: SourcingScenarioDTO;
    fullSourcingDTO?: FullSourcingDTO;
}

function extractDisplayableSupplierName(
    itemType: SolutionConfigurationsTableData['itemType'],
    supplier: SupplierAndStockLocationDTO | undefined,
) {
    if (itemType === 'consigned') {
        return { supplierName: t`Consigned by the customer`, supplierId: undefined };
    }
    if (itemType === 'unknown') {
        return { supplierName: t`No offers`, supplierId: undefined };
    }
    if (itemType === 'unavailable') {
        return { supplierName: t`No offers found`, supplierId: undefined };
    }

    if (itemType === 'inventoryOffer') {
        return { supplierName: t`Inventory`, supplierId: undefined };
    }

    if (itemType === 'solutionOnly' || !isPresent(supplier)) {
        return { supplierName: t`Unknown`, supplierId: undefined };
    }

    if (itemType === 'marketOffer') {
        return { supplierName: formatSupplierAndStockLocationDTO(supplier), supplierId: supplier.id };
    }
    if (itemType === 'customPart') {
        return { supplierName: formatSupplierAndStockLocationDTO(supplier), supplierId: supplier.id };
    }

    assertUnreachable(itemType);
}

function extractLineValue({
    solution,
    solutionConfigurationSourcing,
    sourcingScenario,
}: {
    solution: Pick<Solution, 'unitPrice' | 'firstPurchaseOption'>;
    solutionConfigurationSourcing: Pick<SolutionConfigurationDTO, 'aggregated_quantity'>;
    sourcingScenario: SourcingScenarioDTO;
}): MonetaryValueBackend | null {
    const { unitPrice } = solution;

    if (!isPresent(unitPrice)) {
        return null;
    }

    const aggregateQuantity = solutionConfigurationSourcing.aggregated_quantity.quantity;
    const sourcingScenarioQuantity = getTotalQuantity(sourcingScenario);
    const quantityPerDesignator = aggregateQuantity / sourcingScenarioQuantity;

    return {
        amount: String(Number(unitPrice.amount) * quantityPerDesignator),
        currency: unitPrice.currency,
    };
}

function createSolutionConfigurationsTableData({
    item,
    standardPartOffersById,
    customPartOffersById,
    standardParts,
    customParts,
    purchaseOrders,
    sourcingScenario,
    quotes,
    partCategoriesById,
    excelOriginInfos,
}: {
    item: SolutionConfigurationSourcingDTO;
    standardPartOffersById: Map<string, StandardPartOfferDTO>;
    customPartOffersById: Map<string, CustomOptionOfferDTO>;
    standardParts: Array<OtsFullPart | OtsComponentFull>;
    customParts: Array<CustomFullPart | CustomComponentFull>;
    purchaseOrders: PurchaseOrderDTO[];
    sourcingScenario: SourcingScenarioDTO;
    quotes: QuoteTrackingLight[];
    partCategoriesById: Map<string, PartCategoryDTO>;
    excelOriginInfos: Array<SolutionConfigurationExcelOriginInfo>;
}): SolutionConfigurationsTableData {
    const baseValues = {
        quotes,
        solutionConfigurationSourcing: item,
        designators: extractDesignators(item),
        standardPartOptions: extractStandardPartOptions(item, { filterByStatus: ApprovalStatus.Approved }),
        customPartOptions: extractCustomPartOptions(item, { filterByStatus: ApprovalStatus.Approved }),
        purchaseOrder: purchaseOrders.find((po) => po.line_items.some((li) => li.solution_config_id === item.id)),
        sourcingScenario,
        partCategories: [],
        lineValue: null,
        excelOriginInfo: excelOriginInfos.find((x) => x.solution_configuration_id === item.id),
    };

    if (!isPresent(baseValues.standardPartOptions) && !isPresent(baseValues.customPartOptions)) {
        return {
            ...baseValues,
            itemType: 'unknown',
            solution: undefined,
            offer: undefined,
            selectedPart: undefined,
            ...extractDisplayableSupplierName('unknown', undefined),
        };
    }

    if (!isPresent(item.effective_solution)) {
        return {
            ...baseValues,
            itemType: 'unavailable',
            solution: undefined,
            offer: undefined,
            selectedPart: undefined,
            ...extractDisplayableSupplierName('unknown', undefined),
        };
    }

    const solution = parseSolution(item.effective_solution);

    const standardPartOffer = standardPartOffersById.get(solution.firstPurchaseOption.offer);
    const customPartOffer = customPartOffersById.get(solution.firstPurchaseOption.offer);

    const standardPart = standardParts.find((p) => p.id === standardPartOffer?.linked_part.id);
    const customPart = customParts.find((p) => p.id === customPartOffer?.linked_part.id);

    if (isPresent(standardPartOffer) && isPresent(standardPart) && isStandardPartMarketOffer(standardPartOffer)) {
        const partCategories = isOtsFullPart(standardPart)
            ? (standardPart.part_category?.path
                  .map((id) => partCategoriesById.get(String(id)))
                  .flatMap((x) => (isPresent(x) ? [x] : []))
                  .reverse() ?? [])
            : [];

        return {
            ...baseValues,
            itemType: 'marketOffer',
            solution,
            supplierAndStockLocation: standardPartOffer.linked_location,
            offer: standardPartOffer,
            selectedPart: standardPart,
            partCategories,
            lineValue: extractLineValue({ solution, solutionConfigurationSourcing: item, sourcingScenario }),
            ...extractDisplayableSupplierName('marketOffer', standardPartOffer.linked_location),
        };
    }

    if (isPresent(customPartOffer) && isPresent(customPart)) {
        return {
            ...baseValues,
            itemType: 'customPart',
            solution,
            supplierAndStockLocation: customPartOffer.linked_location,
            offer: customPartOffer,
            selectedPart: customPart,
            lineValue: extractLineValue({ solution, solutionConfigurationSourcing: item, sourcingScenario }),
            assemblyName: extractAssemblyName(item),
            ...extractDisplayableSupplierName('customPart', customPartOffer.linked_location),
        };
    }

    if (isPresent(standardPartOffer) && isPresent(standardPart) && isStandardPartInventoryOffer(standardPartOffer)) {
        const partCategories = isOtsFullPart(standardPart)
            ? (standardPart.part_category?.path
                  .map((id) => partCategoriesById.get(String(id)))
                  .flatMap((x) => (isPresent(x) ? [x] : []))
                  .reverse() ?? [])
            : [];

        return {
            ...baseValues,
            itemType: 'inventoryOffer',
            solution,
            inventorySite: standardPartOffer.linked_location,
            offer: standardPartOffer,
            selectedPart: standardPart,
            partCategories,
            lineValue: extractLineValue({ solution, solutionConfigurationSourcing: item, sourcingScenario }),
            ...extractDisplayableSupplierName('inventoryOffer', undefined),
        };
    }

    if (isConsignedSolution(solution)) {
        return {
            ...baseValues,
            itemType: 'consigned',
            solution: solution,
            offer: undefined,
            selectedPart: undefined,
            ...extractDisplayableSupplierName('consigned', undefined),
        };
    }

    // FIXME: This is a workaround for the case where the offer is not available.
    return {
        ...baseValues,
        itemType: 'solutionOnly',
        solution: solution,
        offer: undefined,
        selectedPart: undefined,
        ...extractDisplayableSupplierName('solutionOnly', undefined),
    };
}

function useSolutionConfigurationsTableData({
    rfqId,
    sourcingScenarioId,
}: {
    rfqId: string;
    sourcingScenarioId: string;
}): SolutionConfigurationsTableData[] | undefined {
    const { data: fullSourcingDTO } = useSourcingFull(sourcingScenarioId);
    const { data: sourcingScenario } = useSourcingScenario(sourcingScenarioId);
    const { data: excelOriginInfos } = useHttpQuery(
        'POST /sourcing-scenarios/excel-origins/bulk',
        { requestBody: { ids: [sourcingScenarioId] } },
        {
            select: (res) => res.items,
        },
    );

    const rfqContext: RfqContext = {
        type: 'WithinRfQ',
        rfq_id: rfqId,
    };

    const stardardPartOffers = isPresent(fullSourcingDTO)
        ? [...fullSourcingDTO.off_the_shelf_offers.items, ...fullSourcingDTO.inventory_offers.items]
        : undefined;

    const { data: partCategories = [] } = usePartCategories();
    const { data: standardParts } = useStandardPartsFromOffers(stardardPartOffers, rfqContext);
    const { data: customParts } = useCustomPartsFromOffers(fullSourcingDTO?.custom_part_offers.items, rfqContext);
    const { data: purchaseOrders } = useHttpQuery(
        'POST /purchase-orders/find',
        {
            requestBody: { sourcing_scenario_ids: [sourcingScenarioId] },
        },
        {
            select: (data) => data.purchase_orders,
        },
    );

    return useMemoCompare(
        () => {
            if (
                !fullSourcingDTO ||
                !stardardPartOffers ||
                !standardParts ||
                !customParts ||
                !purchaseOrders ||
                !sourcingScenario ||
                !excelOriginInfos
            ) {
                return undefined;
            }

            const standardPartOffersById = indexBy(stardardPartOffers, (x) => x.id);
            const customPartOffersById = indexBy(fullSourcingDTO.custom_part_offers.items, (x) => x.id);

            const items = fullSourcingDTO.solution_configurations_sourcing.items.map((item) => {
                return createSolutionConfigurationsTableData({
                    item,
                    standardPartOffersById,
                    customPartOffersById,
                    standardParts,
                    customParts,
                    purchaseOrders,
                    sourcingScenario,
                    quotes: uniqBy(fullSourcingDTO.quotes_by_solution_config[item.id] ?? [], (x) => x.id),
                    partCategoriesById: indexBy(partCategories, (x) => String(x.id)),
                    excelOriginInfos,
                });
            });

            // Sort the items by default using the designator column. The DataTable will perform additional sorting
            // depending on the user's preferences but this ensures a sensible default order.
            return sortBy(items, (s) => s.designators[0] ?? '');
        },
        {
            fullSourcingDTO,
            stardardPartOffers,
            standardParts,
            customParts,
            purchaseOrders,
            sourcingScenario,
            partCategories,
            excelOriginInfos,
        },
    );
}

function useSolutionConfigurationsTableContext({ rfqId }: { rfqId: string }): SolutionConfigurationsTableContext {
    const history = useHistory();
    const { data: supplierAndStockLocations = [] } = useSupplierAndStockLocations();
    const { data: inventorySites = [] } = useInventorySites();
    const scrapQuantityHandlers = useScrapQuantityHandlers();

    return {
        rfqId,
        scrapQuantityHandlers,
        displaySiteName: inventorySites.length > 1,
        supplierAndStockLocations,
        history,
    };
}

const ActionButton: React.FunctionComponent<{
    table: Table<SolutionConfigurationsTableData>;
    sharedContext: SolutionConfigurationsTableContext;
}> = ({ table, sharedContext }) => {
    const selectedItems = table.getSelectedRowModel().rows.map((row) => row.original);
    const hasSelectedItems = selectedItems.length > 0;

    return (
        <Flexbox gap={8}>
            {hasSelectedItems && (
                <Flexbox gap={8} alignItems={'center'}>
                    <Text
                        variant="body"
                        color={colorSystem.neutral[6]}
                    >{t`${selectedItems.length} rows selected`}</Text>

                    <RequestQuoteButtons rfqId={sharedContext.rfqId} selectedItems={selectedItems} />

                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        style={{ marginLeft: '8px', marginRight: '8px' }}
                    />

                    <MultiManualSolutionStatusSelector selectedItems={selectedItems} />

                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        style={{ marginLeft: '8px', marginRight: '8px' }}
                    />

                    <MultiManuallySelectedSolutionSelector selectedItems={selectedItems} />
                </Flexbox>
            )}

            {!hasSelectedItems && (
                <EditScrapQuantityButton table={table} handlers={sharedContext.scrapQuantityHandlers} />
            )}
        </Flexbox>
    );
};

const RequestQuoteButtons: React.FunctionComponent<{
    rfqId: string;
    selectedItems: SolutionConfigurationsTableData[];
}> = ({ rfqId, selectedItems }): JSX.Element => {
    const manuallySelectedPartIds = selectedItems
        .flatMap((item) =>
            extractPartOptions(item.solutionConfigurationSourcing, {
                filterByStatus: ApprovalStatus.Approved,
            }),
        )
        .map((part) => extractPartDTO(part));

    const { openDialog } = useRequestConfigurationDialog({
        rfqId,
        manuallySelectedPartIds,
    });

    const { mutateAsync: download, isLoading: isLoadingFile } = useDownloadQuotePriceTemplate();
    const disabled = isLoadingFile || manuallySelectedPartIds.length === 0;

    return (
        <Flexbox gap={8}>
            <SecondaryButton
                size="medium"
                disabled={disabled}
                onClick={() =>
                    download({ requestBody: { rfq: rfqId, part_ids: manuallySelectedPartIds.map((p) => p.data) } })
                }
                isLoading={isLoadingFile}
                startIcon={<GetApp />}
            >
                <Trans>Download quote template</Trans>
            </SecondaryButton>
            <SecondaryButton
                size="medium"
                disabled={disabled}
                onClick={() => openDialog()}
                startIcon={<RequestViaMailIcon />}
            >
                {t({ id: 'request.quote.cta', message: 'Request quote' })}
            </SecondaryButton>
        </Flexbox>
    );
};

const MultiManualSolutionStatusSelector: React.FunctionComponent<{
    selectedItems: SolutionConfigurationsTableData[];
}> = ({ selectedItems }): JSX.Element => {
    const { mutateAsync, isLoading } = useHttpMutation('PATCH /solution-configurations', {
        snackbarMessage: t`Status updated`,
    });

    const solutionStatuses = selectedItems.map(({ solution }) =>
        isPresent(solution) ? extractSolutionStatus(solution, 'effective') : SolutionStatus.Error,
    );
    const isAllSameValue = solutionStatuses.every((status) => status === solutionStatuses[0]);

    const changeableSolutionConfigurationsIds = selectedItems
        .filter(({ solution }) => isPresent(solution) && !isConsignedSolution(solution))
        .map(({ solutionConfigurationSourcing }) => solutionConfigurationSourcing.id);

    const handleClick = React.useCallback(
        (manualSolutionStatus: SolutionStatus) => {
            const items = changeableSolutionConfigurationsIds.map((id) => {
                return { id, update: { manual_solution_status: manualSolutionStatus } };
            });
            mutateAsync({ requestBody: { items } });
        },
        [changeableSolutionConfigurationsIds, mutateAsync],
    );

    return (
        <Flexbox gap={12} alignItems={'center'}>
            <Text variant="h4" color={colorSystem.neutral[7]}>
                <Trans>Status</Trans>
            </Text>

            <MenuButton
                size="medium"
                label={
                    <Flexbox gap={4} alignItems={'center'}>
                        {isAllSameValue && <SolutionStatusCircleIcon status={solutionStatuses[0]} />}
                        {isAllSameValue ? formatSolutionStatus(solutionStatuses[0]) : t`Mixed`}
                        <ArrowDropDownRounded />
                    </Flexbox>
                }
                appearance="secondary"
                disabled={isLoading || changeableSolutionConfigurationsIds.length === 0}
                menuProps={{
                    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                    transformOrigin: { horizontal: 'right', vertical: -4 },
                }}
            >
                {[SolutionStatus.Good, SolutionStatus.Warning, SolutionStatus.Error].map((manualSolutionStatus) => (
                    <MenuItem
                        key={manualSolutionStatus}
                        startIcon={<SolutionStatusCircleIcon status={manualSolutionStatus} />}
                        onClick={() => handleClick(manualSolutionStatus)}
                        label={formatSolutionStatus(manualSolutionStatus)}
                    />
                ))}
            </MenuButton>
        </Flexbox>
    );
};

type SolutionSelectionStatus = 'auto' | 'manual';

interface MultiManuallySelectedSolutionSelectorProps {
    selectedItems: SolutionConfigurationsTableData[];
}

const MultiManuallySelectedSolutionSelector: React.FunctionComponent<MultiManuallySelectedSolutionSelectorProps> = ({
    selectedItems,
}) => {
    const solutionSelectionStatuses: SolutionSelectionStatus[] = selectedItems.flatMap(({ solution }) => {
        if (!isPresent(solution)) {
            return [];
        }
        if (hasSolutionTag(solution, SolutionTag.AutoSelected) && !hasSolutionTag(solution, SolutionTag.Selected)) {
            return ['auto'];
        }
        if (hasSolutionTag(solution, SolutionTag.Selected)) {
            return ['manual'];
        }
        throwErrorUnlessProduction(new Error('Solution has no selection status'));
        return [];
    });

    const formatStatus = (status: SolutionSelectionStatus, isAllSameValue: boolean): string => {
        if (!isAllSameValue) {
            return t`Mixed`;
        }
        return status === 'auto' ? t`Automatic` : t`Manual`;
    };

    const { mutateAsync, isLoading } = useHttpMutation('PATCH /solution-configurations', {
        snackbarMessage: t`Selection updated`,
    });

    const { openDialog } = useManualCostWarningDialog();

    const changeableSolutionConfigurations = selectedItems.filter(
        ({ solution }) => isPresent(solution) && !isConsignedSolution(solution),
    );

    const handleClick = React.useCallback(
        (manualSolutionStatus: SolutionSelectionStatus) => {
            const items = changeableSolutionConfigurations.map(({ solutionConfigurationSourcing, solution }) => {
                if (!isPresent(solution)) {
                    return { id: solutionConfigurationSourcing.id, update: {} };
                }

                if (manualSolutionStatus === 'auto') {
                    return { id: solutionConfigurationSourcing.id, update: { manually_selected_solution_token: null } };
                }

                if (manualSolutionStatus === 'manual' && !hasSolutionTag(solution, SolutionTag.Selected)) {
                    return {
                        id: solutionConfigurationSourcing.id,
                        update: { manually_selected_solution_token: solution.token },
                    };
                }

                return { id: solutionConfigurationSourcing.id, update: {} };
            });
            mutateAsync({ requestBody: { items } });
        },
        [changeableSolutionConfigurations, mutateAsync],
    );

    const allSameStatus = solutionSelectionStatuses.every((status) => isEqual(status, solutionSelectionStatuses[0]));

    return (
        <Flexbox gap={12} alignItems="center">
            <Text variant="h4" color={colorSystem.neutral[7]}>
                <Trans>Selection</Trans>
            </Text>

            <MenuButton
                size="medium"
                label={
                    <Flexbox gap={4} alignItems="center">
                        {formatStatus(solutionSelectionStatuses[0], allSameStatus)}
                        <ArrowDropDownRounded />
                    </Flexbox>
                }
                appearance="secondary"
                disabled={isLoading || changeableSolutionConfigurations.length === 0}
                menuProps={{
                    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                    transformOrigin: { horizontal: 'right', vertical: -4 },
                }}
            >
                <MenuItem
                    startIcon={<AutoAwesomeRounded />}
                    label={t`Automatic`}
                    onClick={() => {
                        if (
                            changeableSolutionConfigurations.some(
                                ({ solution }) =>
                                    isPresent(solution) && hasSolutionTag(solution, SolutionTag.ManualOneTimeCost),
                            )
                        ) {
                            openDialog(() => handleClick('auto'));
                        } else {
                            handleClick('auto');
                        }
                    }}
                />
                <MenuItem startIcon={<LockOutlined />} label={t`Manual`} onClick={() => handleClick('manual')} />
            </MenuButton>
        </Flexbox>
    );
};

const SolutionConfigurations: React.FunctionComponent<SolutionConfigurationsProps> = ({
    rfqId,
    sourcingScenarioDTO,
    fullSourcingDTO,
}: SolutionConfigurationsProps): JSX.Element => {
    const [graphId, setSelectedGraphId] = usePersistedState<FieldId | undefined>(
        'sourcing.solutionConfiguration.selectedGraphId',
        undefined,
    );

    const data = useSolutionConfigurationsTableData({ rfqId, sourcingScenarioId: sourcingScenarioDTO.id });
    const sharedContext = useSolutionConfigurationsTableContext({ rfqId });
    const { table } = useSolutionConfigurationTableState({
        rfqId,
        sourcingScenarioId: sourcingScenarioDTO.id,
        data,
        sharedContext,
    });

    return (
        <PageLayout
            removeHubspotPaddingFix
            header={
                <ToolbarSourcingScenario
                    rfqId={rfqId}
                    sourcingScenarioName={sourcingScenarioDTO.name}
                    sourcingScenarioId={sourcingScenarioDTO.id}
                />
            }
        >
            <SourcingListDetailsLayout
                title={t`Solution preferences`}
                sidebar={<SourcingFiltersSidebar sourcingScenarioId={sourcingScenarioDTO.id} />}
            >
                <SourcingConfigurationContentHeader
                    rfqId={rfqId}
                    sourcingScenarioDTO={sourcingScenarioDTO}
                    fullSourcingDTO={fullSourcingDTO}
                />

                <SourcingScenarioSummaryBox
                    fullSourcingDTO={fullSourcingDTO}
                    selectedGraphId={graphId}
                    setSelectedGraphId={setSelectedGraphId}
                    tableData={data}
                />

                {isPresent(graphId) && <SolutionConfigurationGraph graphId={graphId} table={table} />}
                {!isPresent(graphId) && (
                    <Flexbox flexDirection={'column'} flexGrow={1}>
                        <TanStackTable
                            table={table}
                            size={'medium'}
                            ActionButton={ActionButton}
                            enableMenuBar={({ table, sharedContext }) => {
                                const hasSelectedItems = table.getSelectedRowModel().rows.length > 0;
                                const hasEnabledScrapQuantity = sharedContext.scrapQuantityHandlers.isEditable;
                                return {
                                    resultCount: !hasSelectedItems && !hasEnabledScrapQuantity,
                                    controlButtons: !hasSelectedItems && !hasEnabledScrapQuantity,
                                };
                            }}
                        />
                    </Flexbox>
                )}
            </SourcingListDetailsLayout>
        </PageLayout>
    );
};

export default SolutionConfigurations;

const SolutionConfigurationGraph: React.FunctionComponent<{
    graphId: FieldId;
    table: Table<SolutionConfigurationsTableData>;
}> = ({ graphId, table }): JSX.Element => {
    const renderGraph = React.useCallback(
        (graphId: FieldId) => {
            const data = table.getRowModel().rows.map((row) => row.original);
            const sharedContext = assertPresent(
                table.options.meta?.sharedContext,
            ) as SolutionConfigurationsTableContext;

            if (graphId === 'unitPrice') {
                return (
                    <ChartSolutionConfiguration
                        rfqId={sharedContext.rfqId}
                        data={data}
                        chartSpec={chartSpecUnitPrice}
                        sharedContext={sharedContext}
                    />
                );
            }
            if (graphId === 'effectiveTotalPrice') {
                return (
                    <ChartSolutionConfiguration
                        rfqId={sharedContext.rfqId}
                        data={data}
                        chartSpec={chartSpecTotalPrice}
                        sharedContext={sharedContext}
                    />
                );
            }
            if (graphId === 'leadTime') {
                return (
                    <ChartSolutionConfiguration
                        rfqId={sharedContext.rfqId}
                        data={data}
                        chartSpec={chartSpecLeadTime}
                        sharedContext={sharedContext}
                    />
                );
            }
            if (graphId === 'supplierName') {
                return (
                    <ChartSolutionConfiguration
                        rfqId={sharedContext.rfqId}
                        data={data}
                        chartSpec={chartSpecSuppliers}
                        sharedContext={sharedContext}
                    />
                );
            }

            assertUnreachable(graphId);
        },
        [table],
    );

    return (
        <Flexbox flexDirection="column">
            <TanStackMenuBar table={table} enableMenuBar={{ controlButtons: true }} />
            <React.Fragment key={graphId}>{renderGraph(graphId)}</React.Fragment>
        </Flexbox>
    );
};
