import { SingleOriginalExcelRow } from '@luminovo/http-client';
import { BomImporterIssue, IssueNameEnum } from '../../../resources/bomImporter/bomImporterIssuesEnum';

export const consolidateDuplicatedDesignators = (excelRows: SingleOriginalExcelRow[]): BomImporterIssue | undefined => {
    const duplicatedDesignatorsIssues = excelRows
        .flatMap((row) => row.issues)
        .filter((issue) => issue.name === IssueNameEnum.DUPLICATE_DESIGNATORS_FOUND);

    const duplicatedDesignators = duplicatedDesignatorsIssues
        .map((issue) => {
            if (issue.name === IssueNameEnum.DUPLICATE_DESIGNATORS_FOUND) {
                return issue.content.duplicatedDesignators;
            } else {
                return [];
            }
        })
        .flat();
    if (
        duplicatedDesignatorsIssues.length > 0 &&
        duplicatedDesignatorsIssues[0].name === IssueNameEnum.DUPLICATE_DESIGNATORS_FOUND
    ) {
        const issue = duplicatedDesignatorsIssues[0];
        issue.content.duplicatedDesignators = Array.from(new Set(duplicatedDesignators));
        return issue;
    }

    return undefined;
};
