import { t, Trans } from '@lingui/macro';
import { assertUnreachable, id, isPresent } from '@luminovo/commons';
import { colorSystem, Flexbox, Text } from '@luminovo/design-system';
import { PCBV2 } from '@luminovo/http-client';
import { EnergySavingsLeafRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { EmissionsTooltip } from '../../../../components/PartEmissionsView/EmissionsTooltip';
import { EmissionsView } from '../../../../components/PartEmissionsView/EmissionsView';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import {
    Capability,
    EXTRACTED_VALUES_FIELDS,
    PCB_TYPE_VALUE_FIELDS,
    PLACEMENT_SELECTION_FIELDS,
    STACK_UP_ADVANCED_VALUE_FIELDS,
    STACK_UP_VALUE_FIELDS,
} from '../../../../resources/pcb/pcbFunctions';
import { CollapsibleSection } from '../../components/CollapsibleSection';
import { PcbFormItem } from '../../components/PcbFormItem';
import { PdfExtractionMessage } from '../../components/PcbPdfExtraction/PcbExtractionMessage';
import { useIsPcbOnlyAssemblyType } from '../../utils/useIsPcbOnlyAssemblyType';
import { FlexIcon, IMSIcon, RigidFlexIcon, RigidIcon } from './icons';

type FormCapabilities = {
    basic: Capability[];
    advanced: Capability[];
};

const usePcbCapabilities = (pcbCapabilities: FormCapabilities) => {
    const { characteristics, appearance, advanced, pcbType, placementSelection } = React.useMemo(() => {
        const characteristics = [];
        const appearance = [];
        const pcbType = [];
        const placementSelection = [];

        for (const capability of pcbCapabilities.basic) {
            if (PCB_TYPE_VALUE_FIELDS.includes(capability.capabilityName)) {
                pcbType.push(capability);
            } else if (EXTRACTED_VALUES_FIELDS.includes(capability.capabilityName)) {
                characteristics.push(capability);
            } else if (PLACEMENT_SELECTION_FIELDS.includes(capability.capabilityName)) {
                placementSelection.push(capability);
            }
            // Everything else is non-extracted
            else if (!STACK_UP_VALUE_FIELDS.includes(capability.capabilityName)) {
                appearance.push(capability);
            }
        }

        return {
            pcbType,
            characteristics,
            appearance,
            placementSelection,
            advanced: pcbCapabilities.advanced.filter(
                (capability) => !STACK_UP_ADVANCED_VALUE_FIELDS.includes(capability.capabilityName),
            ),
        };
    }, [pcbCapabilities]);

    return {
        characteristics,
        appearance,
        advanced,
        pcbType,
        placementSelection,
    };
};

const PcbEmmisionData = ({ pcbId, assemblyId }: { pcbId: string; assemblyId: string }) => {
    const { data: pcbEmissionsData, isLoading } = useHttpQuery('GET /assemblies/:assemblyId/pcb/:pcbId/emissions', {
        pathParams: { assemblyId, pcbId },
    });

    if (isLoading || !isPresent(pcbEmissionsData)) {
        return <></>;
    }

    switch (pcbEmissionsData.type) {
        case 'AnalysisInProgress':
        case 'AnalysisError':
        case 'MissingPcbInfo':
            return <>-</>;
        case 'CalculatedEmissions':
            return (
                <Flexbox
                    flexDirection={'row'}
                    style={{
                        borderRadius: '4px',
                        backgroundColor: colorSystem.neutral[0],
                        padding: '8px',
                        alignItems: 'flex-end',
                        justifyContent: 'space-between',
                    }}
                >
                    <Flexbox flexDirection={'column'} gap={'8px'}>
                        <Text variant="h5">
                            <Trans>Carbon footprint estimate</Trans>
                        </Text>
                        <Flexbox flexDirection={'row'} gap={'4px'} alignItems={'center'}>
                            <Text variant="body">
                                <EmissionsView
                                    min={Number(pcbEmissionsData.product_phase_gwp_in_kg_co2e_min)}
                                    max={Number(pcbEmissionsData.product_phase_gwp_in_kg_co2e_max)}
                                />{' '}
                                <Trans>kgCO2e</Trans>
                            </Text>
                            <EnergySavingsLeafRounded style={{ color: colorSystem.green[5], fontSize: 'inherit' }} />
                        </Flexbox>
                    </Flexbox>
                    <EmissionsTooltip>
                        <span>
                            <Text
                                variant="body-small-semibold"
                                color={colorSystem.blue[6]}
                                style={{
                                    cursor: 'pointer',
                                }}
                            >
                                What is it?
                            </Text>
                        </span>
                    </EmissionsTooltip>
                </Flexbox>
            );
        default:
            assertUnreachable(pcbEmissionsData);
    }
};

export function PcbSpecificationForm({
    pcb,
    isEditing,
    pcbCapabilities,
    assemblyId,
}: {
    pcb: PCBV2;
    assemblyId: string;
    isEditable: boolean;
    isEditing: boolean;
    pcbCapabilities: FormCapabilities;
}) {
    const isPcbOnlyAssemblyType = useIsPcbOnlyAssemblyType(assemblyId);
    const extractedValue = React.useCallback(
        (capability: Capability): string | number | undefined => {
            // @ts-ignore
            return pcb.properties[capability.section][capability.capabilityName];
        },
        [pcb],
    );
    const { characteristics, appearance, advanced, pcbType, placementSelection } = usePcbCapabilities(pcbCapabilities);
    return (
        <Flexbox flexDirection={'column'} paddingBottom={'48px'} gap={'16px'}>
            <PdfExtractionMessage capabilities={appearance.concat(advanced)} pcbId={pcb.id} />

            <Box style={{ borderRadius: '8px', backgroundColor: colorSystem.neutral.white }}>
                <CollapsibleSection
                    id={id('design/box_pcb_type')}
                    label={t`PCB type`}
                    description={<PcbTypeDescription />}
                    tooltipProps={{
                        componentsProps: {
                            tooltip: {
                                style: {
                                    maxWidth: 'none',
                                    width: 'fit-content',
                                },
                            },
                        },
                    }}
                >
                    <Box style={{ display: 'grid', gridTemplateColumns: '1fr', gap: 24, width: '100%' }}>
                        {pcbType.map((capability, i) => (
                            <PcbFormItem
                                key={i}
                                capability={capability}
                                disabled={!isEditing}
                                extractedValue={extractedValue(capability)}
                            />
                        ))}
                    </Box>
                </CollapsibleSection>
            </Box>

            {isPcbOnlyAssemblyType === false && (
                <Box style={{ borderRadius: '8px', backgroundColor: colorSystem.neutral.white }}>
                    <CollapsibleSection
                        label={t`Placements`}
                        description={t`Please indicate if the parts are mounted on one side only or on both sides.`}
                    >
                        <Box style={{ display: 'grid', gridTemplateColumns: '1fr', gap: 24, width: '100%' }}>
                            {placementSelection.map((capability, i) => (
                                <PcbFormItem
                                    key={i}
                                    capability={capability}
                                    disabled={!isEditing}
                                    extractedValue={extractedValue(capability)}
                                />
                            ))}
                        </Box>
                    </CollapsibleSection>
                </Box>
            )}

            <Box style={{ borderRadius: '8px', backgroundColor: colorSystem.neutral.white }}>
                <CollapsibleSection
                    id={id('design/box_pcb_characteristics')}
                    label={t`Characteristics`}
                    isOpenByDefault
                    shouldCollapseButtonBeShown
                >
                    <Box style={{ display: 'grid', gridTemplateColumns: '1fr', gap: 24, width: '100%' }}>
                        {characteristics.map((capability, i) => {
                            return (
                                <PcbFormItem
                                    style={{
                                        justifyContent: 'space-between',
                                        whiteSpace: 'pre-line',
                                    }}
                                    key={i}
                                    capability={capability}
                                    disabled={!isEditing}
                                    extractedValue={extractedValue(capability)}
                                />
                            );
                        })}
                        <PcbEmmisionData pcbId={pcb.id} assemblyId={assemblyId} />
                    </Box>
                </CollapsibleSection>
            </Box>

            <Box style={{ borderRadius: '8px', backgroundColor: colorSystem.neutral.white }}>
                <CollapsibleSection
                    id={id('design/box_pcb_appearance')}
                    label={t`Appearance`}
                    shouldCollapseButtonBeShown
                >
                    <Box style={{ display: 'grid', gridTemplateColumns: '1fr', gap: 24, width: '100%' }}>
                        {appearance.map((capability, i) => (
                            <PcbFormItem
                                style={{
                                    justifyContent: 'space-between',
                                    whiteSpace: 'pre-line',
                                }}
                                key={i}
                                capability={capability}
                                disabled={!isEditing}
                            />
                        ))}
                    </Box>
                </CollapsibleSection>
            </Box>

            <Box style={{ borderRadius: '8px', backgroundColor: colorSystem.neutral.white }}>
                <CollapsibleSection id={id('design/box_pcb_advanced')} label={t`Advanced`} shouldCollapseButtonBeShown>
                    <Box
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr',
                            gap: 24,
                            width: '100%',
                        }}
                    >
                        {advanced.map((capability, i) => (
                            <PcbFormItem key={i} capability={capability} disabled={!isEditing} />
                        ))}
                    </Box>
                </CollapsibleSection>
            </Box>
        </Flexbox>
    );
}

const PcbTypeDescription = () => {
    return (
        <Flexbox
            flexDirection={'column'}
            gap={'16px'}
            padding={'16px'}
            style={{
                whiteSpace: 'nowrap',
            }}
        >
            <Box
                style={{
                    display: 'grid',
                    alignItems: 'center',
                    gap: '8px',
                    gridTemplateColumns: '48px auto 1fr',
                }}
                alignItems={'center'}
                gap={'8px'}
            >
                <RigidIcon />
                <Text variant="h4">{t`Rigid`}</Text>
                <Text variant="body-small">{t`Rigid circuit board`}</Text>
            </Box>
            <Box
                style={{
                    display: 'grid',
                    alignItems: 'center',
                    gap: '8px',
                    gridTemplateColumns: '48px auto 1fr',
                }}
                alignItems={'center'}
                gap={'8px'}
            >
                <FlexIcon />
                <Text variant="h4">{t`Flex`}</Text>
                <Text variant="body-small">{t`Flexible board`}</Text>
            </Box>
            <Box
                style={{
                    display: 'grid',
                    alignItems: 'center',
                    gap: '8px',
                    gridTemplateColumns: '48px auto 1fr',
                }}
                alignItems={'center'}
                gap={'8px'}
            >
                <RigidFlexIcon />
                <Text variant="h4">{t`Rigid-flex`}</Text>
                <Text variant="body-small">{t`Combination of rigid and flexible boards`}</Text>
            </Box>
            <Box
                style={{
                    display: 'grid',
                    alignItems: 'center',
                    gap: '8px',
                    gridTemplateColumns: '48px auto 1fr',
                }}
                alignItems={'center'}
                gap={'8px'}
            >
                <IMSIcon />
                <Text variant="h4">{t`IMS`}</Text>
                <Text variant="body-small">{t`Board with a thermally conductive substrate`}</Text>
            </Box>
        </Flexbox>
    );
};
