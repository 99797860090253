import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { QuoteRequestLineItemStatus } from '@luminovo/http-client';

export const quoteRequestLineItemStatusTranslations: Record<QuoteRequestLineItemStatus, MessageDescriptor> = {
    [QuoteRequestLineItemStatus.NotSent]: defineMessage({ message: 'Not sent' }),
    [QuoteRequestLineItemStatus.Sent]: defineMessage({ message: 'Sent' }),
    [QuoteRequestLineItemStatus.Overdue]: defineMessage({ message: 'Overdue' }),
    [QuoteRequestLineItemStatus.Received]: defineMessage({ message: 'Received' }),
    [QuoteRequestLineItemStatus.NoBid]: defineMessage({ message: 'No bid' }),
    [QuoteRequestLineItemStatus.Discarded]: defineMessage({ message: 'Discarded' }),
};
