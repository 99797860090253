import * as r from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';
import { ApprovalStatusRuntype, QuantityUnitDTORuntype } from '../backendTypes';
import { CommentCategoryRuntype } from '../comments';
import { CustomPartTypeRuntype, TechnicalParametersRuntype } from '../part';
import { UserDTORuntype } from '../user';

export enum HistoryOperation {
    AutoAdd = 'AutoAdd',
    AutoGenerate = 'AutoGenerate',
    AutoAddViaQuoteImport = 'AutoAddViaQuoteImport',
    AutoUpdate = 'AutoUpdate',
    ManualAdd = 'ManualAdd',
    ManualUpdate = 'ManualUpdate',
    ManualRemove = 'ManualRemove',
    ManualApprove = 'ManualApprove',
    ManualUpdateApproval = 'ManualUpdateApproval',
    ManualResolve = 'ManualResolve',
    ManualUnresolve = 'ManualUnresolve',
    ManualUpload = 'ManualUpload',
    AutoRemove = 'AutoRemove',
}

const HistoryRecordFlagRunType = r.Record({
    marked: r.Boolean,
});
export type HistoryRecordFlagDTO = r.Static<typeof HistoryRecordFlagRunType>;
export const HistoryRecordOtsPartRuntype = r.Record({
    mpn: r.String,
    manufacturer: r.String,
    approval: ApprovalStatusRuntype,
});

export type IpnHistoryData = r.Static<typeof IpnHistoryDataRuntype>;
const IpnHistoryDataRuntype = r.Record({
    id: r.String,
    value: r.String,
    revision: r.String.nullable().optional(),
});

export type HistoryRecordOtsDTO = r.Static<typeof HistoryRecordOtsPartRuntype>;
const HistoryRecordIpnRuntype = r.Record({
    ipn: IpnHistoryDataRuntype,
    approval: ApprovalStatusRuntype,
});
export type HistoryRecordIPNDTO = r.Static<typeof HistoryRecordIpnRuntype>;
const GenericPartHistoryDataRuntype = r.Record({
    part: TechnicalParametersRuntype,
    approval: ApprovalStatusRuntype,
});
export type GenericPartHistoryDataDTO = r.Static<typeof GenericPartHistoryDataRuntype>;
const HistoryCommentDataRuntype = r.Record({
    category: CommentCategoryRuntype,
    content: r.String,
});
export type HistoryCommentDataDTO = r.Static<typeof HistoryCommentDataRuntype>;
const CustomPartDataWithApproval = r.Record({
    part_type: CustomPartTypeRuntype,
    approval: ApprovalStatusRuntype,
});
export type HistoryCustomPartDataDTO = r.Static<typeof CustomPartDataWithApproval>;

const CustomComponentDataWithApproval = r.Record({
    ipn: IpnHistoryDataRuntype,
    approval: ApprovalStatusRuntype,
});
export type HistoryCustomComponentDataDTO = r.Static<typeof CustomComponentDataWithApproval>;

export enum HistoryFileType {
    BOM = 'BOM',
    Cad = 'Cad',
    Pnp = 'Pnp',
    Other = 'Other',
}

export const SubAssemblyQuantityChanged = r.Record({
    old_quantity: r.Number,
    new_quantity: r.Number,
    designator: r.String,
});

export type SubAssemblyQuantityChangedDTO = r.Static<typeof SubAssemblyQuantityChanged>;

export const HistoryRecordDataRuntype = r.Union(
    r.Record({
        type: r.Literal('BomItem'),
        data: r.Record({
            designators: r.Array(r.String),
        }),
    }),
    r.Record({
        type: r.Literal('Designators'),
        data: r.Record({
            designators: r.Array(r.String),
        }),
    }),
    r.Record({
        type: r.Literal('Comment'),
        data: HistoryCommentDataRuntype,
    }),
    r.Record({
        type: r.Literal('CustomPart'),
        data: CustomPartDataWithApproval,
    }),
    r.Record({
        type: r.Literal('CustomComponent'),
        data: CustomComponentDataWithApproval,
    }),
    r.Record({
        type: r.Literal('Dnp'),
        data: HistoryRecordFlagRunType,
    }),
    r.Record({
        type: r.Literal('IgnoreManufacturerDataWarning'),
        data: HistoryRecordFlagRunType,
    }),
    r.Record({
        type: r.Literal('GenericPart'),
        data: GenericPartHistoryDataRuntype,
    }),
    r.Record({
        type: r.Literal('Ipn'),
        data: r.Record({
            ipn: IpnHistoryDataRuntype,
            approval: ApprovalStatusRuntype,
        }),
    }),
    r.Record({
        type: r.Literal('IpnData'),
        data: IpnHistoryDataRuntype,
    }),
    r.Record({
        type: r.Literal('ManufacturerFree'),
        data: HistoryRecordFlagRunType,
    }),
    r.Record({
        type: r.Literal('Note'),
        data: r.String,
    }),
    r.Record({
        type: r.Literal('OtsPart'),
        data: HistoryRecordOtsPartRuntype,
    }),
    r.Record({
        type: r.Literal('SourcedBy'),
        data: HistoryRecordFlagRunType,
    }),
    r.Record({
        type: r.Literal('Unit'),
        data: QuantityUnitDTORuntype,
    }),
    r.Record({
        type: r.Literal('File'),
        data: r.Record({
            file_type: runtypeFromEnum(HistoryFileType),
            file_name: r.String.nullable(),
        }),
    }),
    r.Record({
        type: r.Union(r.Literal('Assembly'), r.Literal('PCB'), r.Literal('ParentAssembly'), r.Literal('SubAssembly')),
        data: r.String,
    }),
    r.Record({
        type: r.Literal('DesignItemsExtracted'),
        data: r.Number,
    }),
    r.Record({
        type: r.Literal('SubAssemblyQuantityChanged'),
        data: SubAssemblyQuantityChanged,
    }),
);
export type HistoryRecordDTO = r.Static<typeof HistoryRecordDataRuntype>;

export const AssemblyIdWithNameRuntype = r.Record({
    id: r.String,
    name: r.String,
    parent: r.String.nullable(),
});

export const DesignatorWithDesignItemIdRuntype = r.Record({
    id: r.String,
    designator: r.String,
});

export const HistoryRecordRelationRuntype = r.Union(
    r.Record({
        type: r.Literal('Assembly'),
        data: r.String,
    }),
    r.Record({
        type: r.Literal('DesignItems'),
        data: r.Array(DesignatorWithDesignItemIdRuntype),
    }),
);

export const HistoryRecordBreadCrumbRuntype = r.Record({
    parent_assemblies: r.Array(AssemblyIdWithNameRuntype),
    relation: HistoryRecordRelationRuntype,
});

export const ActorResponseRuntype = r.Union(
    r.Record({
        type: r.Literal('User'),
        data: UserDTORuntype.nullable(),
    }),
    r.Record({
        type: r.Literal('Machine'),
    }),
);

export type ActorResponseRuntypeDTO = r.Static<typeof ActorResponseRuntype>;

export type HistoryRecordBreadCrumb = r.Static<typeof HistoryRecordBreadCrumbRuntype>;
export const HistoryRecordResponseRuntype = r.Record({
    created_by: ActorResponseRuntype,
    created_at: r.String,
    operation: runtypeFromEnum(HistoryOperation),
    data: HistoryRecordDataRuntype,
    bread_crumb: HistoryRecordBreadCrumbRuntype.nullable(),
});
export type HistoryRecordResponseDTO = r.Static<typeof HistoryRecordResponseRuntype>;
