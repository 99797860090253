import { colorSystem, Text, Tooltip } from '@luminovo/design-system';
import { PartIdTypes, PartLite } from '@luminovo/http-client';
import { Box, Divider } from '@mui/material';
import { NegotiationPartLabel } from './NegotiationPartLabel';

export function NegotiationPartsLabel({ parts, hideIpns }: { parts: PartLite[]; hideIpns?: boolean }) {
    const flattenedParts = hideIpns ? parts.flatMap((part) => flattenPartLite(part)) : parts;
    const [first, ...rest] = flattenedParts;

    if (!first) {
        return <span>-</span>;
    }
    return (
        <Tooltip
            variant="white"
            title={
                <Box sx={{ display: 'grid', alignItems: 'center', gridTemplateColumns: '1fr', gap: 1, paddingY: 1 }}>
                    <Text color={colorSystem.neutral[6]} variant="h5">
                        {flattenedParts.length === 1 ? 'Part option' : 'Part options'}
                    </Text>
                    <Divider />
                    {flattenedParts.map((part, key) => (
                        <NegotiationPartLabel key={key} part={part} />
                    ))}
                </Box>
            }
        >
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'auto 1fr',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    gap: 1,
                    width: '100%',
                    alignItems: 'center',
                }}
            >
                <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    <NegotiationPartLabel part={first} />
                </div>
                {rest.length > 0 && <div>+{rest.length}</div>}
            </Box>
        </Tooltip>
    );
}

export function flattenPartLite(part: PartLite): PartLite[] {
    switch (part.kind) {
        case PartIdTypes.OffTheShelf:
            return [part];
        case PartIdTypes.Generic:
            return part.matches.flatMap((match) => flattenPartLite(match));
        case PartIdTypes.Ipn:
            return part.matches.flatMap((match) => flattenPartLite(match));
        case PartIdTypes.Custom:
            return [part];
        case PartIdTypes.CustomComponent:
            return part.matches.flatMap((match) => flattenPartLite(match));
    }
}
