import {
    PlannedOrderCreationRequestSingleDTO,
    PlannedOrdersCreationRequestDTO,
    QuantityUnit,
} from '@luminovo/http-client';
import { formatNaiveDate } from '@luminovo/manufacturing-core';
import { MOCK_SITE_NUMBER } from './mockNames';
import { SingleDemand } from './mockTypes';
/* eslint-disable camelcase */

const randomDateBetween = (start: Date, end: Date): string => {
    const rnDate = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
    return formatNaiveDate(rnDate.toISOString());
};
const oneDayBeforeDate = (date: string): string => {
    const rnDate = new Date(date);
    rnDate.setDate(rnDate.getDate() - 1);
    return formatNaiveDate(rnDate.toISOString());
};

const createSingleDemand = ({
    assemblyIpn,
    recipientSiteNumber,
    supplierSiteNumber,
    deliveryDate,
    quantity,
    productionStartDate,
}: SingleDemand): PlannedOrderCreationRequestSingleDTO => {
    return {
        type: 'gross',
        delivery_start_date: deliveryDate,
        delivery_end_date: deliveryDate,
        production_start_date: productionStartDate ?? oneDayBeforeDate(deliveryDate),
        internal_part_number: {
            value: assemblyIpn,
        },
        quantity: {
            quantity,
            unit: QuantityUnit.Pieces,
        },
        recipient_site_number: recipientSiteNumber,
        supplier_site_number: supplierSiteNumber,
    };
};

export const createExampleDemands = (singleDemands: SingleDemand[]): PlannedOrdersCreationRequestDTO['items'] => {
    const demands: PlannedOrdersCreationRequestDTO['items'] = [];
    singleDemands.forEach((singleDemand) => {
        demands.push(createSingleDemand(singleDemand));
    });
    return demands;
};

const createDemandsForAssembly = ({
    assemblyIpn,
    site,
    supplier,
    quantity,
}: {
    assemblyIpn: string;
    site: string;
    supplier?: string;
    quantity: number;
}): PlannedOrdersCreationRequestDTO['items'] => {
    const demands: PlannedOrdersCreationRequestDTO['items'] = [];
    const randomDeliveryDate = randomDateBetween(new Date('2024-12-01'), new Date('2025-12-01'));

    for (let i = 0; i < quantity; i++) {
        demands.push(
            createSingleDemand({
                assemblyIpn,
                recipientSiteNumber: site,
                supplierSiteNumber: supplier,
                deliveryDate: randomDeliveryDate,
                quantity: 1,
            }),
        );
    }
    return demands;
};

export const createDemandsForAssemblies = ({ assemblyIpns }: { assemblyIpns: string[] }) => {
    const demands: PlannedOrdersCreationRequestDTO['items'] = [];
    assemblyIpns.forEach((assemblyIpn) => {
        demands.push(
            ...createDemandsForAssembly({
                assemblyIpn,
                site: MOCK_SITE_NUMBER,
                quantity: 1,
            }),
            ...createDemandsForAssembly({
                assemblyIpn,
                site: MOCK_SITE_NUMBER,
                quantity: 1,
            }),
        );
    });
    return demands;
};

export const assemblyDemands: PlannedOrdersCreationRequestDTO['items'] = [
    {
        type: 'gross',
        delivery_start_date: '2024-12-02',
        delivery_end_date: '2024-12-02',
        production_start_date: '2024-12-01',
        internal_part_number: {
            value: 'Assembly-1',
        },
        quantity: {
            quantity: 100,
            unit: QuantityUnit.Pieces,
        },
        recipient_site_number: MOCK_SITE_NUMBER,
    },
    {
        type: 'gross',
        delivery_start_date: '2024-12-01',
        delivery_end_date: '2024-12-01',
        internal_part_number: {
            value: 'Assembly-1',
        },
        quantity: {
            quantity: 100,
            unit: QuantityUnit.Pieces,
        },
        recipient_site_number: MOCK_SITE_NUMBER,
    },
];

/* eslint-enable camelcase */
