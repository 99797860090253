import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { QuoteRequestStatus } from '@luminovo/http-client';

export const quoteRequestStatusTranslations: Record<QuoteRequestStatus, MessageDescriptor> = {
    [QuoteRequestStatus.NotSent]: defineMessage({ message: 'Not sent' }),
    [QuoteRequestStatus.Sent]: defineMessage({ message: 'Sent' }),
    [QuoteRequestStatus.Overdue]: defineMessage({ message: 'Overdue' }),
    [QuoteRequestStatus.Discarded]: defineMessage({ message: 'Discarded' }),
    [QuoteRequestStatus.Received]: defineMessage({ message: 'Received' }),
};
