/* eslint-disable camelcase */
import * as r from 'runtypes';
import { HttpEndpoint } from '../http/HttpEndpoint';
import { endpoint } from '../http/endpoint';
import {
    AllOriginRuntype,
    CustomOptionOfferDTORuntype,
    CustomPartOfferInputDTORuntype,
    CustomPartOfferUpdateDTORuntype,
    ImportOffersRequestBodyRuntype,
    PartOfferUpdateStatusRuntype,
    PdfAnalyzeResponseRuntype,
    QuoteImportEventRuntype,
    QuoteImporterResponseRuntype,
    StandardPartOfferBulkInputDTORuntype,
    StandardPartOfferDTORuntype,
    StandardPartOfferInputDTORuntype,
    StandardPartOfferUpdateDTORuntype,
    StandardPartOfferWithSolutionsDTORuntype,
} from './offerBackendTypes';

// Whenever a change is made to the offer endpoints, the following endpoints should be invalidated.
export const generalOfferEndpointsToInvalidate: HttpEndpoint[] = [
    // Offers
    'GET /offers/custom-part',
    'GET /offers/off-the-shelf',
    'POST /offers/off-the-shelf/bulk',
    'POST /offers/off-the-shelf/with-solutions',
    // Sourcing scenarios / Solutions
    'POST /sourcing-scenarios/bulk',
    'POST /sourcing-scenarios/sourcing-full/bulk',
    'GET /solution-configurations',
    'GET /solutions',
    'POST /solutions/custom-part/bulk',
];

export const offerEndpoints = {
    'POST /offers/off-the-shelf/update': endpoint({
        description: 'Creates a long running task in the back-end that will fetch offers for the given partIds',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({ part_ids: r.Array(r.String) }),
        responseBody: r.Record({
            data: r.Record({
                task_id: r.String,
            }),
        }),
    }),

    'GET /offers/off-the-shelf': endpoint({
        description: 'Fetches all the offers for the given OTS part',
        pathParams: r.Undefined,
        queryParams: r.Union(
            r.Record({
                part: r.String,
                rfq_context: r.Literal('WithinRfQ'),
                rfq_id: r.String,
            }),
            r.Record({
                part: r.String,
                rfq_context: r.Literal('OutsideRfQ'),
            }),
        ),
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: r.Array(StandardPartOfferDTORuntype),
        }),
    }),

    'GET /offers/off-the-shelf/by-ipn': endpoint({
        description: 'Fetches all the offers for the given IPN',
        pathParams: r.Undefined,
        queryParams: r.Union(
            r.Record({
                ipn: r.String,
                source: r.Union(r.Literal('Inventory'), r.Literal('Supplier')),
                rfq_context: r.Literal('WithinRfQ'),
                rfq_id: r.String,
            }),
            r.Record({
                ipn: r.String,
                source: r.Union(r.Literal('Inventory'), r.Literal('Supplier')),
                rfq_context: r.Literal('OutsideRfQ'),
            }),
        ),
        requestBody: r.Undefined,
        responseBody: r.Record({
            items: r.Array(StandardPartOfferDTORuntype),
        }),
    }),

    'POST /offer-update': endpoint({
        description: 'Pushes a single offer update task on the top of the queue',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({ api: AllOriginRuntype, sourceable_part: r.String }),
        responseBody: r.Record({ status: PartOfferUpdateStatusRuntype }),
    }),

    'POST /offers/off-the-shelf/with-solutions': endpoint({
        description: 'Fetches all the offers with the fastest and best-price solutions for the given OTS part',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Union(
            r.Record({
                part: r.String,
                quantities: r.Array(r.Number),
                rfq_context: r.Literal('WithinRfQ'),
                rfq_id: r.String,
            }),
            r.Record({
                part: r.String,
                quantities: r.Array(r.Number),
                rfq_context: r.Literal('OutsideRfQ'),
            }),
        ),
        responseBody: r.Record({
            data: r.Array(StandardPartOfferWithSolutionsDTORuntype),
        }),
    }),

    'GET /inventory/:id/offers': endpoint({
        description: 'Returns OTS offer for the provided inventory id',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: StandardPartOfferDTORuntype.nullable(),
        }),
        // Some IPNs might not have inventory, so we just return null.
        handleResponse: async (response, _) => {
            if (response.ok) {
                return response.json();
            }
            if (response.status === 404) {
                return { data: null };
            }
            throw Error(`Failed to GET inventory offer. Status code: ${response.status}`);
        },
    }),

    'POST /offers/off-the-shelf/bulk': endpoint({
        description: 'Returns a list of offers given their IDs',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Union(
            r.Record({
                ids: r.Array(r.String),
                rfq_context: r.Literal('WithinRfQ'),
                rfq_id: r.String,
            }),
            r.Record({
                ids: r.Array(r.String),
                rfq_context: r.Literal('OutsideRfQ'),
            }),
        ),
        responseBody: r.Record({ items: r.Array(StandardPartOfferDTORuntype) }),
    }),

    'POST /analyze/pdf': endpoint({
        description: 'Extracts key-value pairs, named entities and other values from a PDF',
        pathParams: r.Undefined,
        queryParams: r.Record({
            type: r.Union(r.Literal('Offer'), r.Literal('General')),
        }),
        requestBody: r.InstanceOf(FormData),
        responseBody: PdfAnalyzeResponseRuntype,
    }),

    'POST /offers/import': endpoint({
        description: 'Imports offers',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Array(ImportOffersRequestBodyRuntype),
        responseBody: r.Array(
            r.Record({
                status: r.Number,
                part: r
                    .Record({
                        internal_part_number: r.String.optional(),
                        manufacturer_part_number: r.String.optional(),
                        manufacturer: r.String.optional(),
                    })
                    .optional(),
                description: r.String.optional(),
            }),
        ),
        invalidates: [
            ...generalOfferEndpointsToInvalidate,
            'GET /rfqs/:rfqId/quote-tracking',
            'GET /quote-tracking/:id',
            'GET /offers/custom-part',
            'GET /offers/off-the-shelf',
            'GET /solution-configurations',
            'GET /solutions',
            'POST /offers/off-the-shelf/bulk',
            'POST /offers/off-the-shelf/with-solutions',
            'POST /sourcing-scenarios/bulk',
            'POST /sourcing-scenarios/sourcing-full/bulk',
        ],
    }),

    'POST /offers/off-the-shelf/bulk/import': endpoint({
        description: 'Creates offers from a quote import for pdf and excel',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            rfq_id: r.String,
            supplier_and_stock_location: r.String,
            quote_tracking_id: r.String.optional(),
            inputs: r.Array(StandardPartOfferBulkInputDTORuntype),
            event_metadata: QuoteImportEventRuntype,
        }),
        responseBody: QuoteImporterResponseRuntype,
        invalidates: [
            ...generalOfferEndpointsToInvalidate,
            'GET /rfqs/:rfqId/quote-tracking',
            'GET /quote-tracking/:id',
            'GET /offers/custom-part',
            'GET /offers/off-the-shelf',
            'GET /solution-configurations',
            'GET /solutions',
            'POST /offers/off-the-shelf/bulk',
            'POST /offers/off-the-shelf/with-solutions',
            'POST /sourcing-scenarios/bulk',
            'POST /sourcing-scenarios/sourcing-full/bulk',
        ],
    }),

    'POST /offers/custom-part/bulk/import': endpoint({
        description: 'Creates custom part offers from a quote import excel',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            rfq_id: r.String,
            supplier_and_stock_location_id: r.String,
            quote_tracking_id: r.String.optional(),
            custom_part_offers: r.Array(CustomPartOfferInputDTORuntype),
            event_metadata: QuoteImportEventRuntype,
        }),
        responseBody: r.Record({ quote_tracking_id: r.String, items: r.Array(CustomOptionOfferDTORuntype) }),
        invalidates: [
            ...generalOfferEndpointsToInvalidate,
            'GET /rfqs/:rfqId/quote-tracking',
            'GET /quote-tracking/:id',
            'GET /offers/custom-part',
            'GET /offers/off-the-shelf',
            'GET /solution-configurations',
            'GET /solutions',
            'POST /offers/off-the-shelf/bulk',
            'POST /offers/off-the-shelf/with-solutions',
            'POST /sourcing-scenarios/bulk',
            'POST /sourcing-scenarios/sourcing-full/bulk',
        ],
    }),

    'PATCH /offers/off-the-shelf/:id': endpoint({
        description: 'Updates an offer',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: StandardPartOfferUpdateDTORuntype,
        responseBody: r.Unknown,
        invalidates: [
            ...generalOfferEndpointsToInvalidate,
            'GET /offers/custom-part',
            'GET /offers/off-the-shelf',
            'GET /solution-configurations',
            'GET /solutions',
            'POST /offers/off-the-shelf/bulk',
            'POST /offers/off-the-shelf/with-solutions',
            'POST /sourcing-scenarios/bulk',
            'POST /sourcing-scenarios/sourcing-full/bulk',
        ],
    }),

    'DELETE /offers/off-the-shelf/:id': endpoint({
        description: 'Deletes off-the-shelf offer',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: [
            ...generalOfferEndpointsToInvalidate,
            'GET /offers/custom-part',
            'GET /offers/off-the-shelf',
            'GET /solution-configurations',
            'GET /solutions',
            'POST /offers/off-the-shelf/bulk',
            'POST /offers/off-the-shelf/with-solutions',
            'POST /sourcing-scenarios/bulk',
            'POST /sourcing-scenarios/sourcing-full/bulk',
        ],
        removes: [
            'GET /offers/off-the-shelf',
            'GET /solution-configurations',
            'GET /solutions',
            'POST /offers/off-the-shelf/bulk',
            'POST /offers/off-the-shelf/with-solutions',
            'POST /sourcing-scenarios/bulk',
            'POST /sourcing-scenarios/sourcing-full/bulk',
        ],
    }),

    'POST /offers/off-the-shelf': endpoint({
        description: 'Creates an off-the-shelf offer',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: StandardPartOfferInputDTORuntype,
        responseBody: r.Record({
            data: StandardPartOfferDTORuntype,
        }),
        invalidates: [
            ...generalOfferEndpointsToInvalidate,
            'GET /offers/custom-part/:id',
            'GET /offers/custom-part',
            'GET /offers/off-the-shelf',
            'GET /solution-configurations',
            'GET /solutions',
            'POST /offers/off-the-shelf/bulk',
            'POST /offers/off-the-shelf/with-solutions',
            'POST /sourcing-scenarios/bulk',
            'POST /sourcing-scenarios/sourcing-full/bulk',
        ],
    }),

    'GET /offers/custom-part/:id': endpoint({
        description: 'Fetches a custom offer by id',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ data: CustomOptionOfferDTORuntype }),
    }),

    'GET /offers/custom-part': endpoint({
        description: 'Fetches all offers for a given custom part',
        pathParams: r.Undefined,
        queryParams: r.Record({ part: r.String }),
        requestBody: r.Undefined,
        responseBody: r.Record({ data: r.Array(CustomOptionOfferDTORuntype) }),
    }),

    'GET /offers/custom-part/:id/additional-files/upload-link': endpoint({
        description: 'Returns a link to upload additional files to a custom part offer',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: r.Record({
                url: r.String,
            }),
        }),
    }),

    'GET /offers/custom-part/:id/additional-files': endpoint({
        description: 'Returns a list of additional files for a custom part offer',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ items: r.Array(r.String) }),
    }),

    'DELETE /offers/custom-part/:id/additional-files': endpoint({
        description: 'Deletes an additional file for a custom part offer',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Record({ file_name: r.String }),
        responseBody: r.String,
        invalidates: ['GET /offers/custom-part/:id/additional-files'],
    }),

    'POST /offers/custom-part/bulk': endpoint({
        description: 'Returns a list of offers given their IDs',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({ ids: r.Array(r.String) }),
        responseBody: r.Record({ items: r.Array(CustomOptionOfferDTORuntype) }),
    }),

    'POST /offers/custom-part': endpoint({
        description: 'Creates a custom-part offer',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: CustomPartOfferInputDTORuntype,
        responseBody: r.Record({ data: CustomOptionOfferDTORuntype }),
        invalidates: [
            ...generalOfferEndpointsToInvalidate,
            'POST /offers/custom-part/bulk',
            'GET /offers/custom-part/:id',
            'GET /offers/custom-part',
            'GET /offers/off-the-shelf',
            'GET /solution-configurations',
            'GET /solutions',
            'POST /offers/off-the-shelf/bulk',
            'POST /offers/off-the-shelf/with-solutions',
            'POST /sourcing-scenarios/bulk',
            'POST /sourcing-scenarios/sourcing-full/bulk',
        ],
    }),

    'PATCH /offers/custom-part/:id': endpoint({
        description: 'Updates a custom-part offer',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: CustomPartOfferUpdateDTORuntype,
        responseBody: r.Record({ data: CustomOptionOfferDTORuntype }),
        invalidates: [
            ...generalOfferEndpointsToInvalidate,
            'POST /offers/custom-part/bulk',
            'GET /offers/custom-part/:id',
            'GET /offers/custom-part',
            'GET /offers/off-the-shelf',
            'GET /solution-configurations',
            'GET /solutions',
            'POST /offers/off-the-shelf/bulk',
            'POST /offers/off-the-shelf/with-solutions',
            'POST /sourcing-scenarios/bulk',
            'POST /sourcing-scenarios/sourcing-full/bulk',
        ],
    }),

    'POST /offers/pcb/:id/update': endpoint({
        description: 'Updates offers for a pcb',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Unknown,
        invalidates: [
            ...generalOfferEndpointsToInvalidate,
            'POST /assemblies/:id/pcb/:pcbId/offer-state',
            'POST /offers/custom-part/bulk',
            'GET /offers/custom-part/:id',
            'GET /offers/custom-part',
            'GET /offers/off-the-shelf',
            'GET /solution-configurations',
            'GET /solutions',
            'POST /offers/off-the-shelf/bulk',
            'POST /offers/off-the-shelf/with-solutions',
            'POST /sourcing-scenarios/bulk',
            'POST /sourcing-scenarios/sourcing-full/bulk',
            'GET /panels',
        ],
    }),

    'DELETE /offers/custom-part/:id': endpoint({
        description: 'Deletes custom offer',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: [
            ...generalOfferEndpointsToInvalidate,
            'POST /offers/custom-part/bulk',
            'GET /offers/custom-part',
            'GET /offers/off-the-shelf',
            'GET /solution-configurations',
            'GET /solutions',
            'POST /offers/off-the-shelf/bulk',
            'POST /offers/off-the-shelf/with-solutions',
            'POST /sourcing-scenarios/bulk',
            'POST /sourcing-scenarios/sourcing-full/bulk',
        ],
        removes: ['GET /offers/custom-part', 'POST /offers/custom-part/bulk'],
    }),

    'GET /offers/off-the-shelf/:id/attachment': endpoint({
        description: 'Fetches the attachment for the given offer',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ url: r.String }),
        handleResponse: async (response) => {
            if (response.ok) {
                return response.json();
            }
            if (response.status === 404) {
                return { data: null };
            }
            throw Error(`Failed to GET attachment. Status code: ${response.status}`);
        },
    }),

    'GET /offers/off-the-shelf/:id/additional-files/upload-link': endpoint({
        description: 'Returns a link to upload additional files to a off the shelf offer',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: r.Record({
                url: r.String,
            }),
        }),
    }),

    'POST /offers/off-the-shelf/:id/additional-files/duplicate': endpoint({
        description: 'Duplicates an additional file for a off the shelf offer',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Record({ file_names: r.Array(r.String), new_offer_id: r.String }),
        responseBody: r.String,
    }),

    'GET /offers/off-the-shelf/:id/additional-files': endpoint({
        description: 'Returns a list of additional files for a off the shelf offer',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ items: r.Array(r.String) }),
    }),

    'DELETE /offers/off-the-shelf/:id/additional-files': endpoint({
        description: 'Deletes an additional file for a off the shelf offer',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Record({ file_name: r.String }),
        responseBody: r.String,
        invalidates: ['GET /offers/off-the-shelf/:id/additional-files'],
    }),
};
