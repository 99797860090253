import { isPresent, MonetaryValue, throwErrorUnlessProduction } from '@luminovo/commons';
import {
    AwardedOffer,
    AwardedOfferDTO,
    AwardScenarioDTO,
    NegotiationLineItem,
    QuoteRequestLineItemDTO,
} from '@luminovo/http-client';
import { monetaryValue } from '../monetaryValueMath';

export function extractAwardedOffer(
    lineItem: Pick<NegotiationLineItem, 'awarded_offers'>,
    scenarioId?: number,
): AwardedOffer | null {
    const offers = lineItem.awarded_offers.filter((offer) => offer.award_scenario_id === scenarioId);
    if (offers.length > 1) {
        throwErrorUnlessProduction(new Error('extractAwardedOffers: more than one offer found'));
    }
    return offers[0] ?? null;
}

export function extractAwaredOfferFromScenario(
    negotiationLineItemId: number,
    referenceScenario: AwardScenarioDTO,
): AwardedOfferDTO | null {
    const offers = referenceScenario.awarded_offers.filter((a) => a.negotiation_line_item_id === negotiationLineItemId);

    if (offers.length > 1) {
        // eslint-disable-next-line spellcheck/spell-checker
        throwErrorUnlessProduction(new Error('extractAwaredOfferFromScenario: more than one offer found'));
    }
    return offers[0] ?? null;
}

export function getScenarioUnitPrice(
    lineItem: Pick<NegotiationLineItem, 'awarded_offers'>,
    scenarioId?: number,
): MonetaryValue | undefined {
    return extractAwardedOffer(lineItem, scenarioId)?.awarded_solution.unit_price;
}

export function extractTargetPrice(lineItem: QuoteRequestLineItemDTO, referenceScenario: AwardScenarioDTO) {
    if (isPresent(lineItem.target_price)) {
        return lineItem.target_price;
    }

    const referenceUnitPrice = extractAwaredOfferFromScenario(lineItem.negotiation_line_item_id, referenceScenario)
        ?.awarded_solution.unit_price;

    return referenceUnitPrice ?? monetaryValue.zero;
}

export function extractTargetPriceSavings(lineItem: QuoteRequestLineItemDTO, referenceScenario: AwardScenarioDTO) {
    const referenceUnitPrice =
        extractAwaredOfferFromScenario(lineItem.negotiation_line_item_id, referenceScenario)?.awarded_solution
            .unit_price ?? null;

    const targetPrice = extractTargetPrice(lineItem, referenceScenario);
    const relativeDifference = monetaryValue.relativeDifference(referenceUnitPrice, targetPrice);

    return relativeDifference;
}
