import type { ParseResult, Parser } from '../types';

export interface Opts {}

export const parseDateCommon = (value: string): ParseResult<string> => {
    const num = Date.parse(value);
    if (isNaN(num)) {
        return {
            status: 'error',
            message: `Unable to parse date`,
        };
    }

    const date = new Date(num);
    return {
        status: 'done',
        value: { id: date.toISOString(), label: date.toLocaleDateString() },
    };
};

export const parseDateString: Parser<string, Opts> = async function ([value], opts): Promise<ParseResult<string>> {
    if (typeof value === 'string' && value.length === 0) {
        return {
            status: 'done',
            value: { id: '', label: '' },
        };
    }

    return parseDateCommon(value);
};
