import { t } from '@lingui/macro';
import { DisplayableColumnIds } from '@luminovo/http-client';
import { Level } from '../../../../resources/bomImporter/bomImporterIssuesEnum';
import { isTagAssignedToColumn } from './reducer';
import { ColumnTagsSheetsState } from './types';

export type ValidationError = {
    message: string;
    level: Level;
};

type Validator = (
    state: ColumnTagsSheetsState,
    isCustomer?: boolean,
) => { errorMessage: string; errorLevel: Level } | undefined;

function countAssignmentsByTagId(state: ColumnTagsSheetsState, tagId: DisplayableColumnIds): number {
    return state.sheets[state.selectedSheet].tags.find((state) => state.id === tagId)?.associatedTo.length ?? 0;
}

function countAssignmentsByColumnId(state: ColumnTagsSheetsState, columnId: string): number {
    return state.sheets[state.selectedSheet].tags.filter((tag) => isTagAssignedToColumn(tag, columnId)).length;
}

function errorIf({
    validationFailed,
    errorMessage,
    errorLevel,
}: {
    validationFailed: boolean;
    errorMessage: string;
    errorLevel: Level;
}): { errorMessage: string; errorLevel: Level } | undefined {
    if (validationFailed) {
        return { errorMessage, errorLevel };
    }
    return undefined;
}

function checkMaxOneDesignatorAssigned(state: ColumnTagsSheetsState) {
    const assignments = countAssignmentsByTagId(state, 'designators');
    return errorIf({
        validationFailed: assignments > 1,
        errorMessage: t`The Designator tag cannot be assigned more than once`,
        errorLevel: Level.Error,
    });
}

function checkMaxOneQuantityAssigned(state: ColumnTagsSheetsState) {
    const assignments = countAssignmentsByTagId(state, 'quantity');
    return errorIf({
        validationFailed: assignments > 1,
        errorMessage: t`The Quantity tag cannot be assigned more than once`,
        errorLevel: Level.Error,
    });
}

function checkDesignatorOrQuantityAssigned(state: ColumnTagsSheetsState) {
    const quantity = countAssignmentsByTagId(state, 'quantity');
    const designators = countAssignmentsByTagId(state, 'designators');
    return errorIf({
        validationFailed: quantity === 0 && designators === 0,
        errorMessage: t`Either the Quantity or Designator tag should be used.`,
        errorLevel: Level.Error,
    });
}

function checkIfAtLeastOneMpnTagIsAssigned(state: ColumnTagsSheetsState) {
    const mpn = countAssignmentsByTagId(state, 'mpn');
    return errorIf({
        validationFailed: mpn === 0,
        errorMessage: t`No MPN column found. Rename your MPN column to "MPN".`,
        errorLevel: Level.Warning,
    });
}

function checkIfDnpTagAssigned(state: ColumnTagsSheetsState) {
    const dnp = countAssignmentsByTagId(state, 'dnp');
    return errorIf({
        validationFailed: dnp === 0,
        errorMessage: t`No DNP column found. We are assuming none of the BOM items are DNP.`,
        errorLevel: Level.Notification,
    });
}

function checkIfDesignatorTagAssigned(state: ColumnTagsSheetsState, isCustomer?: boolean) {
    const designator = countAssignmentsByTagId(state, 'designators');
    const quantity = countAssignmentsByTagId(state, 'quantity');
    const errorMessage = t`No designator column found.`;
    if (isCustomer) {
        return errorIf({
            validationFailed: designator === 0,
            errorMessage,
            errorLevel: Level.Error,
        });
    }
    return errorIf({
        validationFailed: designator === 0 && quantity !== 0,
        errorMessage,
        errorLevel: Level.Warning,
    });
}

function checkIfQuantityTagAssigned(state: ColumnTagsSheetsState) {
    const designator = countAssignmentsByTagId(state, 'designators');
    const quantity = countAssignmentsByTagId(state, 'quantity');
    return errorIf({
        validationFailed: designator !== 0 && quantity === 0,
        errorMessage: t`No quantity column found.`,
        errorLevel: Level.Warning,
    });
}

function checkIfAtLeastOnePartRelatedTagIsAssigned(state: ColumnTagsSheetsState) {
    const mpn = countAssignmentsByTagId(state, 'mpn');
    const technicalParameters = countAssignmentsByTagId(state, 'technical_parameters');
    const supplierPartNumber = countAssignmentsByTagId(state, 'supplier_part_number');
    // eslint-disable-next-line spellcheck/spell-checker
    const ipn = countAssignmentsByTagId(state, 'ipn');
    const cpn = countAssignmentsByTagId(state, 'cpn');
    return errorIf({
        validationFailed: mpn === 0 && technicalParameters === 0 && supplierPartNumber === 0 && ipn === 0 && cpn === 0,
        errorMessage: t`Either the MPN, IPN, CPN, Supplier part number or Tech. Parameters tag should be used.`,
        errorLevel: Level.Error,
    });
}

/**
 * If a column has been tagged as designator, then it shouldn't have additional tags.
 */
function checkIfDesignatorDontAllowAdditionalTags(state: ColumnTagsSheetsState) {
    const columnsWithDesignators =
        state.sheets[state.selectedSheet].tags.find((tag) => tag.id === 'designators')?.associatedTo ?? [];
    for (const col of columnsWithDesignators) {
        if (countAssignmentsByColumnId(state, col.id) > 1) {
            return {
                errorMessage: t`Columns tagged as Designator cannot have additional tags.`,
                errorLevel: Level.Error,
            };
        }
    }
    return undefined;
}

const validators: Validator[] = [
    checkMaxOneDesignatorAssigned,
    checkMaxOneQuantityAssigned,
    checkDesignatorOrQuantityAssigned,
    checkIfAtLeastOnePartRelatedTagIsAssigned,
    checkIfDesignatorDontAllowAdditionalTags,
    checkIfAtLeastOneMpnTagIsAssigned,
    checkIfDnpTagAssigned,
    checkIfDesignatorTagAssigned,
    checkIfQuantityTagAssigned,
];

export function getValidationErrors(state: ColumnTagsSheetsState, isCustomer: boolean): ValidationError[] {
    const errors: ValidationError[] = [];
    for (const validator of validators) {
        const error = validator(state, isCustomer);
        if (error) {
            errors.push({ message: error.errorMessage, level: error.errorLevel });
        }
    }
    return errors;
}
