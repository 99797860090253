import { SecondaryButton } from '@luminovo/design-system';
import { QuoteRequestStatus } from '@luminovo/http-client';
import { Archive } from '@mui/icons-material';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { useQuoteRequest } from '../hooks/negotiationHandlers';

export function ButtonDiscardQuoteRequest({ quoteRequestId }: { quoteRequestId: number }) {
    const { mutateAsync, isLoading } = useHttpMutation('PATCH /quote-request/:id', {
        snackbarMessage: 'Quote request updated',
    });

    const { data: quoteRequest } = useQuoteRequest(quoteRequestId);

    if (!quoteRequest) {
        return null;
    }

    return (
        <SecondaryButton
            size="medium"
            startIcon={<Archive />}
            isLoading={isLoading}
            onClick={() =>
                mutateAsync({
                    pathParams: { id: quoteRequestId },
                    requestBody: { discarded: quoteRequest.status !== QuoteRequestStatus.Discarded },
                })
            }
        >
            {quoteRequest.status === QuoteRequestStatus.Discarded ? 'Undo discard' : 'Discard'}
        </SecondaryButton>
    );
}
