import { isPresent } from '@luminovo/commons';
import { PCBV2 } from '@luminovo/http-client';

export const extractPcbWidthAndHeight = (pcb: PCBV2): { width: number | undefined; height: number | undefined } => {
    const boardWidth = isPresent(pcb.specifications[0].settings.board.boardWidth)
        ? pcb.specifications[0].settings.board.boardWidth
        : pcb.properties.board.boardWidth;
    const boardHeight = isPresent(pcb.specifications[0].settings.board.boardHeight)
        ? pcb.specifications[0].settings.board.boardHeight
        : pcb.properties.board.boardHeight;

    return {
        width: boardWidth,
        height: boardHeight,
    };
};
