import { assertUnreachable } from '@luminovo/commons';
import { PartIdTypes, PartLite } from '@luminovo/http-client';

export function formatNegotiationPart(part?: PartLite): string {
    if (!part) {
        return '-';
    }
    if (part.kind === PartIdTypes.OffTheShelf) {
        return `${part.manufacturer.name}, ${part.mpn}`;
    }
    if (part.kind === PartIdTypes.Ipn) {
        return part.id;
    }
    if (part.kind === PartIdTypes.Custom) {
        return `Custom ${part.type.name} ${part.description}`;
    }
    if (part.kind === PartIdTypes.CustomComponent) {
        return part.id;
    }
    if (part.kind === PartIdTypes.Generic) {
        return `Generic ${part.content.type}`;
    }
    assertUnreachable(part);
}
