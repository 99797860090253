import { t, Trans } from '@lingui/macro';
import { colorSystem, Flexbox, RightBoxDrawer, Tag, TertiaryButton, Text } from '@luminovo/design-system';
import {
    EmissionData,
    GenericCapacitor,
    GenericFullPart,
    GenericPartTypes,
    GenericResistor,
    OtsFullPart,
    PackageDTO,
    RfqContext,
} from '@luminovo/http-client';
import { Edit, Info } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import React, { useCallback } from 'react';
import { AddGenericCapacitorForm } from '../../../modules/DesignItem/components/AddGenericCapacitorForm';
import {
    createGenericCapacitorFormInitialValues,
    createGenericResistorFormInitialValues,
} from '../../../modules/DesignItem/components/AddGenericPartFunctions';
import { AddGenericResistorForm } from '../../../modules/DesignItem/components/AddGenericResistorForm';
import { RequestEmissionsData } from '../../../modules/PartLibrary/PartDetailsPage/components/PartDetailsPanel';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { usePartPackages } from '../../../resources/part/partHandler';
import {
    formatCapacitance,
    formatDielectricMaterial,
    formatPowerRating,
    formatResistance,
    formatTemperatureCoefficient,
    formatTolerance,
    formatVoltageRating,
} from '../../../utils/converterUtils';
import { useDrawerContext } from '../../contexts/ModalContext';
import { EmissionsTooltip } from '../../PartEmissionsView/EmissionsTooltip';
import { EmissionsView } from '../../PartEmissionsView/EmissionsView';
import { OTSPartCard } from '../OTSPart/OTSPartCard';
import { OTSPartDetailsMounting } from '../OTSPart/OTSPartDetailsMounting';
import { HeadingWithValue } from '../PartCardDetailsComponents';

const MAX_MPN_MATCH_LIST_RESULTS = 50;

export function useGenericPartDetailsDrawer() {
    const { closeDrawer, setDrawer } = useDrawerContext();

    return {
        openDrawer: ({
            genericPart,
            genericPartId,
            mpnMatches = [],
            isEditEnable = false,
            onAddAlsoRemove = () => {},
            rfqContext,
        }: {
            /**
             * To open this drawer you can pass an ID, or the contents of the generic part.
             * This looks a bit weird, but it's required because we're re-using this drawer
             * for incomplete generic parts, where there is no ID.
             */
            genericPart?: GenericFullPart['content'];
            mpnMatches?: OtsFullPart[];
            genericPartId?: string;
            isEditEnable?: boolean;
            onAddAlsoRemove?: (newPart: GenericFullPart) => void;
            rfqContext: RfqContext;
        }) => {
            setDrawer(
                <RightBoxDrawer onClose={closeDrawer}>
                    <GenericPartDetailsDrawer
                        genericPartId={genericPartId}
                        genericPart={genericPart}
                        mpnMatches={mpnMatches}
                        rfqContext={rfqContext}
                        isEditEnable={isEditEnable}
                        onAddAlsoRemove={onAddAlsoRemove}
                        closeDrawer={closeDrawer}
                    />
                </RightBoxDrawer>,
            );
        },
    };
}

function GenericPartDetailsDrawer({
    genericPartId,
    genericPart,
    mpnMatches = [],
    rfqContext,
    isEditEnable,
    onAddAlsoRemove,
    closeDrawer,
}: {
    genericPartId?: string;
    genericPart?: GenericFullPart['content'];
    mpnMatches?: OtsFullPart[];
    rfqContext: RfqContext;
    isEditEnable: boolean;
    onAddAlsoRemove: (newPart: GenericFullPart) => void;
    closeDrawer: () => void;
}) {
    const context =
        rfqContext.type === 'WithinRfQ'
            ? { rfq_id: rfqContext.rfq_id, rfq_context: rfqContext.type }
            : { rfq_context: rfqContext.type };
    const { data: part, isLoading } = useHttpQuery(
        'POST /parts/generic/bulk',
        {
            requestBody: { ids: [genericPartId ?? ''], ...context },
        },
        {
            select: (res) => res.items[0],
            suspense: true,
            enabled: Boolean(genericPartId),
        },
    );

    if (isLoading) {
        return <></>;
    }
    const content = part?.content ?? genericPart;
    const matches = part?.matches ?? mpnMatches ?? [];
    const emissionData = part?.emission_data ?? null;

    return (
        <Flexbox padding="32px" minWidth="400px">
            {content?.type === GenericPartTypes.Resistor && (
                <GenericResistorCardDetails
                    genericPartId={genericPartId}
                    genericPart={content}
                    mpnMatches={matches}
                    isEditable={isEditEnable}
                    rfqContext={rfqContext}
                    onAddAlsoRemove={onAddAlsoRemove}
                    onSettled={closeDrawer}
                    emissionData={emissionData}
                />
            )}
            {content?.type === GenericPartTypes.Capacitor && (
                <GenericCapacitorCardDetails
                    genericPartId={genericPartId}
                    genericPart={content}
                    mpnMatches={matches}
                    isEditable={isEditEnable}
                    rfqContext={rfqContext}
                    onAddAlsoRemove={onAddAlsoRemove}
                    onSettled={closeDrawer}
                    emissionData={emissionData}
                />
            )}
        </Flexbox>
    );
}

type GenericPartCardDetailsProps<P> = {
    genericPartId?: string;
    genericPart: P;
    mpnMatches: OtsFullPart[];
    isEditable: boolean;
    onAddAlsoRemove: (newPart: GenericFullPart) => void;
    rfqContext: RfqContext;
    onSettled: () => void;
    emissionData: EmissionData | null;
};

const GenericResistorCardDetails = ({
    genericPartId,
    genericPart,
    mpnMatches,
    isEditable,
    onAddAlsoRemove,
    rfqContext,
    onSettled,
    emissionData,
}: GenericPartCardDetailsProps<GenericResistor>): JSX.Element => {
    const [isEditForm, setIsEditForm] = React.useState(false);
    const { data: allPackages = [] } = usePartPackages('generic-part-creatable');

    const {
        resistance,
        tolerance,
        power_rating: powerRating,
        voltage_rating: voltageRating,
        temperature_coefficient: tempCoefficient,
        package_id: packageId,
    } = genericPart.technical_parameters;

    const packageForResistor = allPackages.find((p) => p.id === packageId) ?? null;

    const isIncompleteResistor = resistance === null || packageId === null;

    const renderEmissionData = useCallback(() => {
        if (emissionData) {
            return (
                <EmissionsView
                    min={Number(emissionData.product_phase_gwp_in_kg_co2e_min)}
                    max={Number(emissionData.product_phase_gwp_in_kg_co2e_max)}
                />
            );
        }

        if (mpnMatches.length === 0) {
            return '-';
        }

        const dataRequestedForMatch = mpnMatches?.find((match) => match.emissions_data.type === 'Requested');

        if (dataRequestedForMatch) {
            return <Trans>Requested</Trans>;
        }

        if (genericPartId) {
            return <RequestEmissionsData genericPartIds={[genericPartId]} />;
        }
    }, [emissionData, genericPartId, mpnMatches]);

    if (isEditForm) {
        return (
            <Flexbox flexDirection="column" gap={32} width="100%">
                <Text variant="h2">
                    <Trans>Edit generic part</Trans>
                </Text>

                <AddGenericResistorForm
                    initialValues={createGenericResistorFormInitialValues(genericPart, packageForResistor)}
                    onGenericPartCreation={onAddAlsoRemove}
                    rfqContext={rfqContext}
                    onSettled={onSettled}
                />
            </Flexbox>
        );
    }

    return (
        <Flexbox flexDirection="column" gap={32} width="100%">
            <Flexbox flexDirection="column" gap={8}>
                <Text variant="h4" color={colorSystem.neutral[5]}>
                    <Trans>Generic part</Trans>
                </Text>
                <Flexbox justifyContent="space-between">
                    <Text variant="h2">
                        <Trans>Resistor</Trans>
                    </Text>
                    {(isEditable || isIncompleteResistor) && ( // Needed so the edit of generic parts isn't possible outside of bom
                        <Tooltip title={t`Edit generic resistor`} placement="top" arrow>
                            <TertiaryButton
                                size="medium"
                                onClick={() => setIsEditForm(true)}
                                disabled={!isEditable}
                                startIcon={<Edit fontSize="inherit" />}
                            >
                                <Trans>Edit</Trans>
                            </TertiaryButton>
                        </Tooltip>
                    )}
                </Flexbox>
                {isIncompleteResistor && (
                    <span>
                        <Tag color="red" label={t`Incomplete`} />
                    </span>
                )}
            </Flexbox>
            <OTSPartDetailsMounting packageData={packageForResistor} />
            <DetailsSection label={t`Technical parameters`}>
                <Flexbox flexDirection={'column'} gap={12} flexGrow={1}>
                    <HeadingWithValue heading={t`Resistance`}>
                        {resistance ? formatResistance(resistance) : <Trans>Missing</Trans>}
                    </HeadingWithValue>
                    <HeadingWithValue heading={t`Tolerance`}>
                        {tolerance && `≤ ${formatTolerance(tolerance)}`}
                    </HeadingWithValue>
                    <HeadingWithValue heading={t`Power Rating`}>
                        {powerRating && `≥ ${formatPowerRating(powerRating)}`}
                    </HeadingWithValue>
                    <HeadingWithValue heading={t`Voltage Rating`}>
                        {voltageRating && `≥ ${formatVoltageRating(voltageRating)}`}
                    </HeadingWithValue>
                    <HeadingWithValue heading={t`Temperature Coefficient`}>
                        {tempCoefficient && `≤ ${formatTemperatureCoefficient(tempCoefficient)}`}
                    </HeadingWithValue>
                </Flexbox>
            </DetailsSection>
            <DetailsSection label={t`Sustainability`}>
                <Flexbox flexDirection={'column'} gap={12} flexGrow={1}>
                    <Flexbox justifyContent={'space-between'}>
                        <Flexbox alignItems={'center'}>
                            <Text variant="body" color={colorSystem.neutral[7]} style={{ whiteSpace: 'nowrap' }}>
                                {t`kgCO2e`}
                            </Text>
                            <EmissionsTooltip>
                                <Info style={{ color: colorSystem.neutral[5], height: 16 }} />
                            </EmissionsTooltip>
                        </Flexbox>
                        <Text variant="body" color={colorSystem.neutral[9]} style={{ textAlign: 'end' }}>
                            {renderEmissionData()}
                        </Text>
                    </Flexbox>
                </Flexbox>
            </DetailsSection>
            <DetailsSection label={t`MPN matches`}>
                <Flexbox flexDirection="column" gap="4px">
                    {mpnMatches.length === 0 && <Tag color="red" label={t`No matches`} />}
                    {mpnMatches.length > 0 &&
                        mpnMatches
                            .slice(0, MAX_MPN_MATCH_LIST_RESULTS)
                            .map((part, index) => <OTSPartCard part={part} rfqContext={rfqContext} key={index} />)}
                </Flexbox>
            </DetailsSection>
        </Flexbox>
    );
};

const DetailsSection = ({ label, children }: { label: string; children: JSX.Element }) => {
    return (
        <Flexbox flexDirection={'column'} gap={12}>
            <Text variant="h4" color={colorSystem.neutral[7]}>
                {label}
            </Text>
            <Flexbox justifyContent={'space-between'}>{children}</Flexbox>
        </Flexbox>
    );
};

const GenericCapacitorCardDetails = ({
    genericPart,
    mpnMatches,
    isEditable,
    onAddAlsoRemove,
    rfqContext,
    onSettled,
}: GenericPartCardDetailsProps<GenericCapacitor>): JSX.Element => {
    const [isEditForm, setIsEditForm] = React.useState(false);
    const { data: allPackages = [] } = usePartPackages('generic-part-creatable');

    const {
        capacitance,
        dielectric: dielectricMaterial,
        voltage_rating: voltageRating,
        tolerance,
        package_id: packageId,
    } = genericPart.technical_parameters;

    const packageForCapacitor: PackageDTO | null = allPackages.find((p) => p.id === packageId) ?? null;

    const isIncompleteCapacitor = capacitance === null || packageId === null;

    if (isEditForm) {
        return (
            <Flexbox flexDirection="column" gap={32} width="100%">
                <Text variant="h2">
                    <Trans>Edit generic part</Trans>
                </Text>

                <AddGenericCapacitorForm
                    initialValues={createGenericCapacitorFormInitialValues(genericPart, packageForCapacitor)}
                    onGenericPartCreation={onAddAlsoRemove}
                    rfqContext={rfqContext}
                    onSettled={onSettled}
                />
            </Flexbox>
        );
    }

    return (
        <Flexbox flexDirection="column" gap={32} width="100%">
            <Flexbox flexDirection="column" gap={8}>
                <Text variant="h4" color={colorSystem.neutral[5]}>
                    <Trans>Generic part</Trans>
                </Text>

                <Flexbox justifyContent="space-between">
                    <Text variant="h2">
                        <Trans>Capacitor</Trans>
                    </Text>
                    {(isEditable || isIncompleteCapacitor) && ( // Needed so the edit of generic parts isn't possible outside of bom
                        <Tooltip title={t`Edit generic capacitor`} placement="top" arrow>
                            <TertiaryButton
                                size="medium"
                                onClick={() => setIsEditForm(true)}
                                disabled={!isEditable}
                                startIcon={<Edit fontSize="inherit" />}
                            >
                                <Trans>Edit</Trans>
                            </TertiaryButton>
                        </Tooltip>
                    )}
                </Flexbox>
                {isIncompleteCapacitor && (
                    <span>
                        <Tag color="red" label={t`Incomplete`} />
                    </span>
                )}
            </Flexbox>
            <OTSPartDetailsMounting packageData={packageForCapacitor} />
            <DetailsSection label={t`Technical parameters`}>
                <Flexbox flexDirection={'column'} gap={12} flexGrow={1}>
                    <HeadingWithValue heading={t`Capacitance`}>
                        {capacitance ? formatCapacitance(capacitance) : <Trans>Missing</Trans>}
                    </HeadingWithValue>
                    <HeadingWithValue heading={t`Tolerance`}>
                        {tolerance && `≤ ${formatTolerance(tolerance)}`}
                    </HeadingWithValue>
                    <HeadingWithValue heading={t`Dielectric`}>
                        {dielectricMaterial && `${formatDielectricMaterial(dielectricMaterial)}`}
                    </HeadingWithValue>
                    <HeadingWithValue heading={t`Voltage Rating`}>
                        {voltageRating && `≥ ${formatVoltageRating(voltageRating)}`}
                    </HeadingWithValue>
                </Flexbox>
            </DetailsSection>
            <HeadingWithValue heading={t`MPN matches`}>
                <Flexbox flexDirection="column" gap="4px">
                    {mpnMatches.length === 0 && <Tag color="red" label={t`No matches`} />}
                    {mpnMatches.length > 0 &&
                        mpnMatches
                            .slice(0, MAX_MPN_MATCH_LIST_RESULTS)
                            .map((part, index) => <OTSPartCard part={part} rfqContext={rfqContext} key={index} />)}
                </Flexbox>
            </HeadingWithValue>
        </Flexbox>
    );
};
