import { t } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { AllOrigins, OfferOriginEnum, OrbweaverOrigin } from '@luminovo/http-client';

export function formatOfferOrigin(origin: AllOrigins): string {
    switch (origin.origin) {
        case OfferOriginEnum.Manual:
            return t`Manual`;
        case OfferOriginEnum.Farnell:
            return `Farnell API`;
        case OfferOriginEnum.Newark:
            return `Newark API`;
        case OfferOriginEnum.Element14:
            return `element14 API`;
        case OfferOriginEnum.Octopart:
            return `Octopart`;
        case OfferOriginEnum.TrustedParts:
            return `TrustedParts`;
        case OfferOriginEnum.Buerklin:
            return `Bürklin API`;
        case OfferOriginEnum.Tme:
            return `TME API`;
        case OfferOriginEnum.Weltron:
            return `Weltron API`;
        case OfferOriginEnum.Mouser:
            return `Mouser API`;
        case OfferOriginEnum.MyArrow:
            return `MyArrow API`;
        case OfferOriginEnum.Arrow:
            return `Arrow API`;
        case OfferOriginEnum.Schukat:
            return `Schukat API`;
        case OfferOriginEnum.Gudeco:
            return `Gudeco API`;
        case OfferOriginEnum.Future:
            return `Future Electronics API`;
        case OfferOriginEnum.Rutronik:
            return `Rutronik API`;
        case OfferOriginEnum.AvnetAbacus:
            return `Avnet EMEA (Abacus) API`;
        case OfferOriginEnum.AvnetApac:
            return `Avnet APAC API`;
        case OfferOriginEnum.AvnetSilica:
            return `Avnet EMEA (Silica) API`;
        case OfferOriginEnum.AvnetEbv:
            return `Avnet EMEA (EBV) API`;
        case OfferOriginEnum.AvnetUsa:
            return `Avnet USA API`;
        case OfferOriginEnum.Digikey:
            return `Digi-Key API`;
        case OfferOriginEnum.Consigned:
            return t`Consigned`;
        case OfferOriginEnum.Import:
            return t`Import`;
        case OfferOriginEnum.Orbweaver:
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            return (origin as OrbweaverOrigin).datasource_name;
        case OfferOriginEnum.WuerthEisos:
            return `Würth Elektronik eiSos`;
        case OfferOriginEnum.Wuerth:
            return `Würth API`;
        case OfferOriginEnum.BetaLayout:
            return `Beta Layout API`;
        case OfferOriginEnum.SafePcb:
            return `Safe-PCB API`;
        case OfferOriginEnum.IBRRingler:
            return `IBR Ringler API`;
        case OfferOriginEnum.APCT:
            return `APCT API`;
        case OfferOriginEnum.Gatema:
            return `Getema API`;
        case OfferOriginEnum.Alba:
            return `Alba API`;
        case OfferOriginEnum.Stackrate:
            return `Stackrate API`;
        case OfferOriginEnum.Lcsc:
            return `LCSC API`;
        case OfferOriginEnum.Eve:
            return `EVE API`;
        case OfferOriginEnum.Ti:
            return `TI API`;
        case OfferOriginEnum.Tti:
            return `TTI API`;
        case OfferOriginEnum.Samtec:
            return `Samtec API`;
        case OfferOriginEnum.Blume:
            return `Blume Elektronik API`;
        case OfferOriginEnum.Rochester:
            return `Rochester Electronics API`;
        case OfferOriginEnum.AlElektronik:
            return `AL-Elektronik API`;
        case OfferOriginEnum.Master:
            return `Master Electronics API`;
        case OfferOriginEnum.OnlineComponents:
            return `Online Components API`;
        case OfferOriginEnum.QuestComponents:
            return `Quest Components API`;
        case OfferOriginEnum.RsComponents:
            return `RS Components API`;
        case OfferOriginEnum.ChipCart:
            return `ChipCart API`;
        default:
            assertUnreachable(origin);
    }
}
