/* eslint-disable spellcheck/spell-checker*/
/* eslint-disable camelcase */
import {
    ApprovalStatusRuntype,
    CustomComponentFullRuntype,
    CustomFullPartRuntype,
    GenericFullPartRuntype,
    OtsComponentFullRuntype,
    OtsFullPartRuntype,
} from '@luminovo/http-client';
import * as r from 'runtypes';

// eslint-disable-next-line import/no-unused-modules
export interface OtsFullPartWithApprovalStatus extends r.Static<typeof OtsFullPartWithApprovalStatusRuntype> {}
const OtsFullPartWithApprovalStatusRuntype = r.Record({
    type: r.Literal('OffTheShelf'),
    part: OtsFullPartRuntype,
    approvalStatus: ApprovalStatusRuntype,
});

export interface GenericFullPartWithApprovalStatus extends r.Static<typeof GenericFullPartWithApprovalStatusRuntype> {}
const GenericFullPartWithApprovalStatusRuntype = r.Record({
    type: r.Literal('Generic'),
    part: GenericFullPartRuntype,
    approvalStatus: ApprovalStatusRuntype,
});

export interface IpnFullPartWithApprovalStatus extends r.Static<typeof IpnFullPartWithApprovalStatusRuntype> {}
const IpnFullPartWithApprovalStatusRuntype = r.Record({
    type: r.Literal('Ipn'),
    part: OtsComponentFullRuntype,
    approvalStatus: ApprovalStatusRuntype,
});

export type FullPartWithApprovalStatus = r.Static<typeof FullPartWithApprovalStatusRuntype>;
const FullPartWithApprovalStatusRuntype = r.Union(
    OtsFullPartWithApprovalStatusRuntype,
    GenericFullPartWithApprovalStatusRuntype,
    IpnFullPartWithApprovalStatusRuntype,
    r.Record({
        type: r.Literal('Custom'),
        part: CustomFullPartRuntype,
        approvalStatus: ApprovalStatusRuntype,
    }),
    r.Record({
        type: r.Literal('CustomComponent'),
        part: CustomComponentFullRuntype,
        approvalStatus: ApprovalStatusRuntype,
    }),
);

export { lifecycleEnumPublicTranslations } from '@luminovo/sourcing-core';

/**
 *
 * Used to know the section of the screen when adding a part option in the following analytics events:
 *   - select_part - property: section_of_screen
 *   - link_part - property: section_of_screen
 *
 */
export type SectionOfScreen =
    | 'comparePartsModal'
    | 'alternativeParts'
    | 'partialMatches'
    | 'ipnSearch'
    | 'mpnSearch'
    | 'otsPartForm'
    | 'genericPartForm';
