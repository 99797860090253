/* eslint-disable camelcase */
import { PartIdTypes } from '@luminovo/http-client';
import { NegotiationPartLabel } from './NegotiationPartLabel';

export function NegotiationIpnLabel({ ipn }: { ipn?: string | null }): JSX.Element {
    return (
        <NegotiationPartLabel
            part={
                ipn
                    ? {
                          kind: PartIdTypes.Ipn,
                          id: ipn,
                          matches: [],
                      }
                    : undefined
            }
        />
    );
}
