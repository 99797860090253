/* eslint-disable camelcase */
import { compareByDate, isPresent } from '@luminovo/commons';
import {
    AwardedOffer,
    NegotiationLineItem,
    NegotiationLineItemDTO,
    SupplierAndStockLocationDTO,
} from '@luminovo/http-client';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { useSupplierAndStockLocations } from '../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';

function inlineSuppliersIntoNegotiationLineItem(
    lineItem: NegotiationLineItemDTO,
    getSupplier: (supplierId: string) => SupplierAndStockLocationDTO | undefined,
): NegotiationLineItem {
    const updatedAwardedOffers: AwardedOffer[] = lineItem.awarded_offers.map((offer) => ({
        ...offer,
        awarded_solution: {
            ...offer.awarded_solution,
            supplier_and_stock_location: getSupplier(offer.awarded_solution.supplier_and_stock_location_id),
        },
    }));
    return {
        ...lineItem,
        awarded_offers: updatedAwardedOffers,
    };
}

export function useNegotiations() {
    return useHttpQuery(
        'GET /negotiation',
        {},
        {
            select: (data) => Array.from(data.items).sort(compareByDate((n) => n.created_at)),
        },
    );
}

export function useNegotiation(negotiationId: number | undefined) {
    return useHttpQuery(
        'GET /negotiation',
        {},
        {
            select: (data) => data.items.find((item) => item.id === negotiationId),
        },
    );
}

export function useNegotiationLineItems(negotiationId: number | undefined) {
    const suppliers = useSupplierAndStockLocations();

    return useHttpQuery(
        'GET /negotiation/:id/line-items',
        {
            pathParams: {
                id: negotiationId ?? NaN,
            },
        },
        {
            enabled: isPresent(negotiationId) && isPresent(suppliers),
            select: (data) => {
                const getSupplier = (supplierId: string) =>
                    suppliers?.data?.find((supplier) => supplier.id === supplierId);
                return data.map((line_item) => inlineSuppliersIntoNegotiationLineItem(line_item, getSupplier));
            },
        },
    );
}

export function useQuoteRequest(quoteRequestId: number | undefined) {
    return useHttpQuery(
        'POST /quote-request/find',
        {
            requestBody: {
                quote_request_ids: isPresent(quoteRequestId) ? [quoteRequestId] : [],
            },
        },
        {
            enabled: isPresent(quoteRequestId),
            select: (data) => data.items[0],
        },
    );
}

export function useQuoteRequests(quoteRequestIds: number[] | undefined) {
    return useHttpQuery(
        'POST /quote-request/find',
        {
            requestBody: {
                quote_request_ids: quoteRequestIds ?? [],
            },
        },
        {
            select: (data) => data.items,
            enabled: isPresent(quoteRequestIds),
        },
    );
}

export function useNegotiationQuoteRequests(negotiationId: number | undefined) {
    return useHttpQuery(
        'POST /quote-request/find',
        {
            requestBody: {
                negotiation_id: negotiationId ?? NaN,
            },
        },
        {
            select: (data) => data.items,
            enabled: isPresent(negotiationId),
        },
    );
}

export function useAwardScenarios(negotiationId: number | undefined) {
    return useHttpQuery(
        'POST /award-scenario/find',
        {
            requestBody: {
                negotiation_id: negotiationId ?? NaN,
            },
        },
        {
            select: (data) => data.items,
            enabled: isPresent(negotiationId),
        },
    );
}

export function useQuoteRequestLineItemsByQuoteRequest(quoteRequestId: number | undefined) {
    return useHttpQuery(
        'GET /quote-request/:id/line-items',
        {
            pathParams: {
                id: quoteRequestId ?? NaN,
            },
        },
        {
            select: (data) => data.items,
            enabled: isPresent(quoteRequestId),
        },
    );
}

export function useQuoteRequestLineItems(negotiationLineItemIds: number[] | undefined) {
    return useHttpQuery(
        'POST /quote-request/line-items/find',
        {
            requestBody: {
                negotiation_line_item_ids: negotiationLineItemIds ?? [],
            },
        },
        {
            select: (data) => data.items,
            enabled: isPresent(negotiationLineItemIds),
        },
    );
}

export function useNegotiationAggregatedStatuses(negotiationId: number | undefined) {
    return useHttpQuery(
        'GET /negotiation/:id/aggregated-statuses',
        {
            pathParams: {
                id: negotiationId ?? NaN,
            },
        },
        {
            enabled: isPresent(negotiationId),
        },
    );
}

export function useDemands() {
    return useHttpQuery(
        'GET /demand',
        {},
        {
            select: (data) => Array.from(data).sort(compareByDate((n) => n.created_at)),
        },
    );
}

export function useDemandsInNegotiation(negotiationId: number | undefined) {
    return useHttpQuery(
        'GET /negotiation/:id/demands',
        {
            pathParams: {
                id: negotiationId ?? NaN,
            },
        },
        {
            enabled: isPresent(negotiationId),
        },
    );
}
