/* eslint-disable spellcheck/spell-checker */
import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import {
    CenteredLayout,
    colorSystem,
    createColumnHelper,
    Flexbox,
    NonIdealState,
    PrimaryButton,
    SecondaryIconButton,
    Tag,
    TanStackTable,
    Tooltip,
    useTanStackTable,
} from '@luminovo/design-system';
import { Incoterms } from '@luminovo/http-client/src/resources/supplierTerms';
import { Add, Delete, Edit } from '@mui/icons-material';
import {
    SupplierTermsWithSite,
    useSupplierTermsFromSupplierWithSite,
} from '../../../resources/supplierTerms/supplierTermsHandler';
import { useDialogAddSupplierTerm } from './SupplierTermDialogs/AddSupplierTermDialog';
import { useDeleteSupplierTermDialog } from './SupplierTermDialogs/DeleteSupplierTermDialog';
import { useDialogEditSupplierTerm } from './SupplierTermDialogs/EditSupplierTermDialog';
import { getIncotermsFullDetails } from './SupplierTermDialogs/SupplierTermForm';

type SupplierTermsSharedContext = {
    supplierId: string;
};

const EmptyPlaceholder = ({ sharedContext }: { sharedContext: SupplierTermsSharedContext }) => {
    const { openDialog } = useDialogAddSupplierTerm(sharedContext.supplierId);
    return (
        <CenteredLayout>
            <NonIdealState
                title={t`No terms added`}
                description={t`Here you can specify incoterms and payment terms for this supplier`}
                action={{
                    startIcon: <Add />,
                    children: t`Add terms`,
                    color: 'primary',
                    size: 'medium',
                    onClick: openDialog,
                }}
                overrides={{ ActionButton: PrimaryButton }}
            />
        </CenteredLayout>
    );
};

const AddSupplierTermsButton = ({ sharedContext }: { sharedContext: SupplierTermsSharedContext }) => {
    const { openDialog } = useDialogAddSupplierTerm(sharedContext.supplierId);
    return (
        <PrimaryButton size="medium" startIcon={<Add fontSize="inherit" />} onClick={openDialog}>
            Add terms
        </PrimaryButton>
    );
};

const getIncotermsColor = (incoterms: Incoterms): keyof typeof colorSystem => {
    switch (incoterms) {
        case Incoterms.EXW:
            return 'blue';
        case Incoterms.FCA:
            return 'green';
        case Incoterms.CPT:
            return 'primary';
        case Incoterms.CIP:
            return 'red';
        case Incoterms.DAP:
            return 'teal';
        case Incoterms.DPU:
            return 'violet';
        case Incoterms.DDP:
            return 'yellow';
        case Incoterms.FAS:
            return 'blue';
        case Incoterms.FOB:
            return 'green';
        case Incoterms.CFR:
            return 'violet';
        case Incoterms.CIF:
            return 'yellow';
        default:
            return 'neutral';
    }
};

const SupplierTermsIncotermsCell = ({ incoterms }: { incoterms: Incoterms }) => {
    return (
        <Tooltip title={getIncotermsFullDetails(incoterms)}>
            <span>
                <Tag color={getIncotermsColor(incoterms)} attention="low" label={incoterms} />
            </span>
        </Tooltip>
    );
};

function RowActionButton({ supplierTerms }: { supplierTerms: SupplierTermsWithSite }) {
    const { openDialog: openEditDialog } = useDialogEditSupplierTerm(supplierTerms);
    const { openDialog: openDeleteDialog } = useDeleteSupplierTermDialog(supplierTerms);
    return (
        <Flexbox alignItems="center" columnGap={2}>
            <SecondaryIconButton size="medium" onClick={openDeleteDialog}>
                <Delete fontSize="inherit" />
            </SecondaryIconButton>
            <SecondaryIconButton size="medium" onClick={openEditDialog}>
                <Edit fontSize="inherit" />
            </SecondaryIconButton>
        </Flexbox>
    );
}

export function SupplierTermsTable({ supplierId }: { supplierId: string }) {
    const supplierTermWithSite = useSupplierTermsFromSupplierWithSite(supplierId);

    const columnHelper = createColumnHelper<SupplierTermsWithSite>();
    const columns = [
        columnHelper.text('site.name', {
            size: 150,
            label: () => t`Site name`,
            cell: ({ getValue }) => getValue() || <Tag color="neutral" label={t`All sites`} attention="low" />,
        }),
        columnHelper.text('site.site_number', {
            id: 'site_number',
            size: 75,
            label: () => t`Site number`,
            cell: ({ getValue }) => getValue() || '-',
        }),
        columnHelper.text('incoterms', {
            size: 75,
            label: () => t`Incoterms`,
            cell: ({ getValue }) => {
                const value = getValue();
                if (!isPresent(value)) return '-';
                return <SupplierTermsIncotermsCell incoterms={value} />;
            },
        }),
        columnHelper.text('incoterms_named_place', {
            size: 170,
            label: () => t`Named place`,
            cell: ({ getValue }) => getValue() || '-',
        }),
        columnHelper.text('net_payment_due_days', {
            size: 130,
            label: () => t`Net payment due days`,
            cell: ({ getValue }) => {
                const netPaymentDueDays = getValue();
                if (!isPresent(netPaymentDueDays)) return '-';
                return `${netPaymentDueDays} days`;
            },
        }),
        columnHelper.text('early_payment_due_days', {
            size: 130,
            label: () => t`Early payment due days`,
            cell: ({ getValue }) => {
                const earlyPaymentDueDays = getValue();
                if (!isPresent(earlyPaymentDueDays)) return '-';
                return `${earlyPaymentDueDays} days`;
            },
        }),
        columnHelper.text('early_payment_discount_percent', {
            size: 130,
            label: () => t`Early payment discount`,
            cell: ({ getValue }) => {
                const earlypaymentDiscount = getValue();
                if (!isPresent(earlypaymentDiscount)) return '-';
                return `${earlypaymentDiscount}%`;
            },
        }),
        columnHelper.action({
            id: 'actionMenu',
            size: 100,
            cell: ({ row }) => <RowActionButton supplierTerms={row.original} />,
        }),
    ];
    const { table } = useTanStackTable({
        columns,
        data: supplierTermWithSite,
        sharedContext: { supplierId },
    });

    return (
        <TanStackTable
            table={table}
            size="medium"
            EmptyPlaceholder={EmptyPlaceholder}
            ActionButton={AddSupplierTermsButton}
        />
    );
}
