import {
    Availability,
    MonetaryValueBackend,
    Option,
    PurchaseOption,
    SolutionTag,
    SolutionTotalPriceDTO,
    Tag,
} from '@luminovo/http-client';

export { SolutionStatus } from '@luminovo/http-client';

export interface Solution {
    unitPrice: Option<MonetaryValueBackend>;
    totalPrice: Option<SolutionTotalPriceDTO>;
    availability: Option<Availability>;
    solutionTags: Tag[];
    firstPurchaseOption: PurchaseOption;
    token: string;
}

// ranked for importance https://www.figma.com/file/ZHVicAWTv9w0RC9cPgqgld/Sourcing-v1?node-id=699%3A111
export const SolutionNotificationTags: readonly SolutionTag[] = [
    SolutionTag.Excess,
    SolutionTag.QuotePrice,
    SolutionTag.ContractPrice,
    SolutionTag.CustomerNegotiatedPrice,
    SolutionTag.PurchasePrice,
    SolutionTag.StandardPrice,
    SolutionTag.ManualOffer,
    SolutionTag.Customer,
    SolutionTag.RfQ,
    SolutionTag.Converted,
    SolutionTag.OneTimeCost,
    SolutionTag.OnOrder,
    SolutionTag.Consigned,
    SolutionTag.NonCancellableNonReturnable,
] as const;

export const SolutionWarningTags: readonly SolutionTag[] = [
    SolutionTag.LowStock,
    SolutionTag.OnePoint,
    SolutionTag.Extrapolated,
    SolutionTag.Interpolated,
    SolutionTag.Old,
    SolutionTag.Expiring,
    SolutionTag.LongLeadTime,
    SolutionTag.UnknownLeadTime,
    SolutionTag.ExceedsOfferedQuantity,
] as const;

export const SolutionErrorTags: readonly SolutionTag[] = [
    SolutionTag.Inactive,
    SolutionTag.SupplierNotApproved,
    SolutionTag.UnitMismatch,
    SolutionTag.Unavailable,
    SolutionTag.OutdatedManual,
    SolutionTag.Outdated,
    SolutionTag.Expired,
    SolutionTag.ThirdPartyOrigin,
    SolutionTag.GreatlyExceedsOfferedQuantity,
    SolutionTag.NoApprovedParts,
    SolutionTag.InvalidSpecification,
    SolutionTag.SupplierExcluded,
    SolutionTag.PanelMismatch,
] as const;
