import { Link, Text } from '@luminovo/design-system';
import { PartIdTypes, PartLite } from '@luminovo/http-client';
import { useGenericPartDetailsDrawer } from '../../../components/partSpecificationCards/GenericPart/GenericPartCardDetails';
import { useIpnDetailsDrawer } from '../../../components/partSpecificationCards/Ipn/useIpnDetailsDrawer';
import { useOtsPartDetailsDrawer } from '../../../components/partSpecificationCards/OTSPart/useOtsPartDetailsDrawer';
import { formatNegotiationPart } from '../model/formatNegotiationPart';

export function NegotiationPartLabel({ part }: { part?: PartLite }): JSX.Element {
    const { openDrawer: openOtsPartDrawer } = useOtsPartDetailsDrawer();
    const { openDrawer: openIpnPartDrawer } = useIpnDetailsDrawer();
    const { openDrawer: openGenericPartDrawer } = useGenericPartDetailsDrawer();

    if (!part) {
        return <Text>-</Text>;
    }

    // FIXME(negotiations): add support for other part types
    return (
        <Link
            attention="high"
            onClick={() => {
                if (part.kind === PartIdTypes.OffTheShelf) {
                    openOtsPartDrawer({ part: { id: part.id }, rfqContext: { type: 'OutsideRfQ' } });
                } else if (part.kind === PartIdTypes.Ipn) {
                    openIpnPartDrawer({ ipnId: part.id, rfqContext: { type: 'OutsideRfQ' } });
                } else if (part.kind === PartIdTypes.Generic) {
                    openGenericPartDrawer({
                        genericPartId: part.id,
                        isEditEnable: false,
                        rfqContext: { type: 'OutsideRfQ' },
                    });
                }
            }}
        >
            {formatNegotiationPart(part)}
        </Link>
    );
}
