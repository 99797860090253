/* eslint-disable camelcase */
import { t } from '@lingui/macro';
import {
    displayCurrencySymbol,
    extractAmountFromMonetaryValue,
    formatCurrencyWithoutSymbol,
    formatMonetaryValue,
    isPresent,
    MonetaryValue,
} from '@luminovo/commons';
import {
    createColumnHelper,
    FieldNumeric,
    FieldNumericControlled,
    TanStackTable,
    useTanStackTable,
} from '@luminovo/design-system';
import { QuoteRequestLineItemDTO } from '@luminovo/http-client';
import { formatQuantity } from '@luminovo/sourcing-core';
import { InputAdornment } from '@mui/material';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useGlobalCurrency } from '../../../resources/organizationSettings/currencySettingsHandler';
import { useNegotiationsContext } from '../context/NegotiationsContext';
import { useSubmitLineItemPrices } from '../hooks/useSubmitLineItemTargetPrice';
import { compareQuoteRequestLineItems } from '../model/compareQuoteRequestLineItem';
import {
    extractAwaredOfferFromScenario,
    extractTargetPriceSavings,
} from '../model/extractors/extractorNegotionsLineItem';
import { formatNegotiationPart } from '../model/formatNegotiationPart';
import { getUnitPriceDiscount } from '../model/getUnitPriceDiscount';
import { QuoteRequestForm } from '../model/quoteRequestForm';
import { NegotiationIpnLabel } from './NegotiationIpnLabel';
import { NegotiationPartLabel } from './NegotiationPartLabel';
import { Header } from './TableHeader';

type RowDate = QuoteRequestLineItemDTO;

const columnHelper = createColumnHelper<RowDate>();

const ipnColumn = columnHelper.text('component_origin', {
    id: 'ipn',
    label: () => t`IPN`,
    size: 150,
    cell: ({ row }) => {
        return <NegotiationIpnLabel ipn={row.original.component_origin} />;
    },
});

const requestedPartColumn = columnHelper.text((row) => formatNegotiationPart(row.requested_part), {
    id: 'requestedPart',
    label: () => t`Requested part`,
    size: 240,
    cell: ({ row }) => <NegotiationPartLabel part={row.original.requested_part} />,
});

const descriptionColumn = columnHelper.text((row) => row.description ?? '-', {
    id: 'description',
    label: () => t`Description`,
    size: 200,
});

const requiredQuantityColumn = columnHelper.number(
    (row) => formatQuantity(row.required_quantity, { showPiecesUnit: false }),
    {
        id: 'requiredQuantity',
        label: () => t`Qty`,
        size: 100,
    },
);

const potentialQuantityColumn = columnHelper.number(
    (row) => formatQuantity(row.potential_quantity ?? 0, { showPiecesUnit: false }),
    {
        id: 'potentialQuantity',
        size: 100,
        label: () => t`Potential Qty`,
    },
);

const referenceUnitPriceColumn = columnHelper.monetaryValue(() => null, {
    id: 'referenceUnitPrice',
    label: () => t`Reference unit price`,
    header: () => <Header title={t`Unit price`} subtitle={t`Reference`} />,
    size: 100,
    enableSorting: false,
    enableColumnFilter: false,
    cell: function Cell({ row }) {
        const { referenceScenario } = useNegotiationsContext();

        if (!isPresent(referenceScenario)) {
            return '-';
        }

        const referenceUnitPrice = extractAwaredOfferFromScenario(
            row.original.negotiation_line_item_id,
            referenceScenario!,
        )?.awarded_solution.unit_price;
        return formatMonetaryValue(referenceUnitPrice, 'unit-price', { ifAbsent: '-' });
    },
});

const targetPriceColumn = columnHelper.monetaryValue('target_price', {
    id: 'targetPrice',
    label: () => t`Target price`,
    size: 100,
    cell: ({ row }) => <TargetPriceFormInput quoteRequestLineItem={row.original} />,
});

const targetSavingsColumn = columnHelper.number(() => null, {
    id: 'targetSavings',
    size: 75,
    label: () => '',
    enableSorting: false,
    enableColumnFilter: false,
    cell: ({ row }) => <TargetSavingsFormInput quoteRequestLineItem={row.original} />,
});

export function TableQuoteRequestLineItemsForm({ lineItems }: { lineItems: QuoteRequestLineItemDTO[] | undefined }) {
    const { control } = useFormContext<QuoteRequestForm>();
    const { showTargetPrice, showCustomerName } = useWatch({ control });

    const columns = React.useMemo(() => {
        const defaultColumns = [
            ipnColumn,
            requestedPartColumn,
            descriptionColumn,
            requiredQuantityColumn,
            potentialQuantityColumn,
            referenceUnitPriceColumn,
        ];
        if (showTargetPrice) {
            return [...defaultColumns, targetPriceColumn, targetSavingsColumn];
        }
        if (showCustomerName) {
            // TODO(negotiations): implement
        }
        return defaultColumns;
    }, [showTargetPrice, showCustomerName]);

    const { table } = useTanStackTable({
        data: lineItems?.sort(compareQuoteRequestLineItems()),
        columns,
        enableColumnOrdering: false,
        enableColumnHiding: false,
    });

    return <TanStackTable table={table} />;
}

const TargetPriceFormInput = ({ quoteRequestLineItem }: { quoteRequestLineItem: QuoteRequestLineItemDTO }) => {
    const { referenceScenario } = useNegotiationsContext();
    const { isLoading: isCurrencyLoading, preferredCurrency } = useGlobalCurrency();
    const { setValue, control } = useFormContext<QuoteRequestForm>();
    const index = useWatch({ control, name: `lineItemTargetPrices` }).findIndex(
        (x) => x.quoteRequestLineItemId === quoteRequestLineItem.id,
    );

    const { isLoading, mutateAsync: submit } = useSubmitLineItemPrices(quoteRequestLineItem.id);

    return (
        <Controller
            name={`lineItemTargetPrices.${index}.targetPrice`}
            control={control}
            render={({ field, formState }) => {
                // Using this to consistently ensure that it is formatted
                const value = formatCurrencyWithoutSymbol(extractAmountFromMonetaryValue(field.value), 'unit-price');
                return (
                    <FieldNumeric
                        value={Number(value)}
                        disabled={isCurrencyLoading || isLoading || formState.isSubmitting}
                        onChange={(value) => {
                            const newVal: MonetaryValue = {
                                amount: (value ?? 0).toString(),
                                currency: field.value?.currency ?? preferredCurrency,
                            };
                            field.onChange(newVal);
                        }}
                        onBlur={async () => {
                            await submit();

                            if (!isPresent(referenceScenario)) {
                                return;
                            }
                            const tragetSavings =
                                (extractTargetPriceSavings(quoteRequestLineItem, referenceScenario) ?? 0) * 100;
                            setValue(`lineItemTargetSavings.${index}.targetSavings`, tragetSavings);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {displayCurrencySymbol(field.value?.currency ?? preferredCurrency)}
                                </InputAdornment>
                            ),
                        }}
                    />
                );
            }}
        />
    );
};

const TargetSavingsFormInput = ({ quoteRequestLineItem }: { quoteRequestLineItem: QuoteRequestLineItemDTO }) => {
    const { referenceScenario } = useNegotiationsContext();
    const { setValue, formState, control } = useFormContext<QuoteRequestForm>();

    const index = useWatch({ control, name: `lineItemTargetPrices` }).findIndex(
        (x) => x.quoteRequestLineItemId === quoteRequestLineItem.id,
    );
    const targetSavings = useWatch({ control, name: `lineItemTargetSavings.${index}.targetSavings` });

    const { isLoading, mutateAsync: submit } = useSubmitLineItemPrices(quoteRequestLineItem.id);

    return (
        <FieldNumericControlled
            control={control}
            name={`lineItemTargetSavings.${index}.targetSavings`}
            min={0}
            max={100}
            FieldProps={{
                disabled: isLoading || formState.isSubmitting,
                onBlurCapture: async () => {
                    await submit();

                    if (!isPresent(referenceScenario)) {
                        return;
                    }

                    const referenceUnitPrice = extractAwaredOfferFromScenario(
                        quoteRequestLineItem.negotiation_line_item_id,
                        referenceScenario,
                    )?.awarded_solution.unit_price;

                    const discountedUnitPrice = getUnitPriceDiscount(referenceUnitPrice ?? null, targetSavings);
                    setValue(`lineItemTargetPrices.${index}.targetPrice`, discountedUnitPrice);
                },
                InputProps: {
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                },
            }}
        />
    );
};
