import { t } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import { isPresent, uniq } from '@luminovo/commons';
import {
    BACKEND_BASE,
    BomFileDTO,
    BomImporterBackendInput,
    BomLineBuildingInput,
    BomLineBuildingOutput,
    ColumnMap,
    DesignItemOriginTypes,
    DesignItemResponseDTO,
    HeaderMetadata,
    SingleOriginalExcelRow,
    TaskAcceptedResponse,
    TaskAcceptedResponseRuntype,
    http,
} from '@luminovo/http-client';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { analytics } from '../../utils/analytics';
import { assertAgainstRuntype } from '../../utils/customConsole';
import HttpService, { uploadResources } from '../HttpService';
import { NotificationFunction } from '../NotificationFunction';
import { FileWithUrlAndName } from '../assembly/assemblyFrontendTypes';
import { UseQueryResponseObject } from '../frontendTypes';

type BomImportBackendResponse = UseQueryResponseObject<{ bom_file_id: string; design_items: string[] }>;

const BOM_IMPORTER_REQUEST_TIMEOUT_MILLISECS = 180 * 1000;

// Calls to backend-core
const BOM_FILES = BACKEND_BASE + `/bom-files`;
const BOM_IMPORTER_DESIGN_ITEMS = BACKEND_BASE + `/design-items/bom-importer`;
const BOM_IMPORTER = BACKEND_BASE + `/bom-importer`;

// Calls to bom-importer service
// Note, these calls are just to put things in task queue, you get task id as response
// and then need to GET status of that task
// display it as a progress bar until you get the response information
// eslint-disable-next-line spellcheck/spell-checker
const BOM_IMPORTER_BOM_SCREENING = BOM_IMPORTER + `/screener`;
const BOM_IMPORTER_LINE_BUILDER = BOM_IMPORTER + `/line-builder`;

const FAILED_BOM_IMPORT = BOM_FILES + `/upload-failed`;

const getSingleBomFileUploadEndpoint = (bomFileId: string) => BOM_FILES + `/${bomFileId}/upload`;

const getSingleBomFailedImportFileUploadEndpoint = () => FAILED_BOM_IMPORT;

export const uploadFileToBOMImporter = async (file: File, token: string): Promise<TaskAcceptedResponse | string> => {
    const formData = new FormData();
    const reader = new FileReader();
    reader.readAsDataURL(file);
    formData.append('file', file);
    const fileUploadHeaders = new Map<string, string>([['Content-Type', 'multipart/form-data']]);

    return await new HttpService()
        .withToken(token)
        .withHeaders(fileUploadHeaders)
        .postV2(BOM_IMPORTER_BOM_SCREENING, formData)
        .then((response) => {
            const data: TaskAcceptedResponse = response.data;
            assertAgainstRuntype(data, TaskAcceptedResponseRuntype);
            return data;
        })
        .catch((err) => {
            const isRunningOnWindows = navigator.userAgent.indexOf('Windows') > -1;
            let errorMessage = isRunningOnWindows
                ? t`Failed to upload BOM. Please check if the file is opened by another program`
                : t`Failed to upload BOM`;
            if (err.status === 422 && err.data.detail) {
                errorMessage = err.data.detail;
            }
            return Promise.resolve(errorMessage);
        });
};

const uploadImportedBomToContainer = async (
    bomFileId: string,
    bomFile: File,
    enqueueSnackbar: NotificationFunction,
    captureAzureError?: () => void,
    // TODO: Please fix this the next time you're touching this file :)
    // eslint-disable-next-line max-params
) => {
    const fileWithUrl: FileWithUrlAndName = {
        file: bomFile,
        url: URL.createObjectURL(bomFile),
        name: bomFile.name,
    };
    await uploadResources([fileWithUrl], getSingleBomFileUploadEndpoint(bomFileId), enqueueSnackbar, captureAzureError);
};

type PostToLineBuilderParams = {
    excelLines: SingleOriginalExcelRow[];
    columnMap: ColumnMap;
    fileName: string;
    sheetName: string;
    headerMetaData: HeaderMetadata | null;
    rfqId: string;
    token: string;
    importingAssembly: string;
};

export const postToLineBuilder = async ({
    excelLines,
    columnMap,
    fileName,
    sheetName,
    headerMetaData,
    rfqId,
    token,
    importingAssembly,
}: PostToLineBuilderParams): Promise<TaskAcceptedResponse | string> => {
    const bomLineBuildingInput: BomLineBuildingInput = {
        /* eslint-disable camelcase */
        relevant_excel_lines: excelLines,
        column_map: columnMap,
        file_name: fileName,
        sheet_name: sheetName,
        bom_header_metadata: headerMetaData,
        rfq_id: rfqId,
        importing_assembly: importingAssembly,
    };
    return await new HttpService()
        .withToken(token)
        .postV2(BOM_IMPORTER_LINE_BUILDER, bomLineBuildingInput)
        .then((response) => {
            const data: TaskAcceptedResponse = response.data;
            assertAgainstRuntype(data, TaskAcceptedResponseRuntype);
            return data;
        })
        .catch((err) => {
            let errorMessage = 'Could not extract components';
            if (err.status === 422 && err.data.detail) {
                errorMessage = err.data.detail;
            }
            return Promise.resolve(errorMessage);
        });
};

type BOMImporterLinesRequestData = {
    bomImporterLineBuildingOutput: BomLineBuildingOutput | null;
    headerMetadata: HeaderMetadata | null;
    importingAssemblyId: string;
    columnMap: ColumnMap;
};

export const postImportBom = async (
    bomLinesRawRequestData: BOMImporterLinesRequestData,
    file: File,
    notificationFunction: NotificationFunction,
    token: string,
    acceptPreviousBomLines: boolean,
    // eslint-disable-next-line max-params
) => {
    const { bomImporterLineBuildingOutput, headerMetadata, importingAssemblyId, columnMap } = bomLinesRawRequestData;

    const captureAzureError = () => {
        analytics.track('could_not_upload_bom', {
            assembly_uuid: importingAssemblyId,
            error_type: 'failed Azure upload',
        });
    };

    if (bomImporterLineBuildingOutput !== null) {
        const bomImportedAssemblies = isOldBomLinebuildingData(bomImporterLineBuildingOutput)
            ? []
            : bomImporterLineBuildingOutput.assemblies;

        const linesPerAssembly = isOldBomLinebuildingData(bomImporterLineBuildingOutput)
            ? {
                  [importingAssemblyId]: bomImporterLineBuildingOutput.lines.items,
              }
            : bomImporterLineBuildingOutput.lines_per_assembly;
        const bomImportInput: BomImporterBackendInput = {
            header_metadata: headerMetadata
                ? {
                      raw_header_line: headerMetadata.raw_header_row,
                      header_line_number: headerMetadata.header_row_number,
                  }
                : null,
            column_map: columnMap,
            importing_assembly: importingAssemblyId,
            accept_previous_bom_lines: acceptPreviousBomLines,
            assemblies: bomImportedAssemblies,
            lines_per_assembly: linesPerAssembly,
        };

        const importBackendResponse: BomImportBackendResponse | void = await new HttpService()
            .withToken(token)
            .withConfig({ timeout: BOM_IMPORTER_REQUEST_TIMEOUT_MILLISECS })
            .postV2(BOM_IMPORTER_DESIGN_ITEMS, bomImportInput);

        if (importBackendResponse) {
            await uploadImportedBomToContainer(
                importBackendResponse.data.data.bom_file_id,
                file,
                notificationFunction,
                captureAzureError,
            );
        }
        return importBackendResponse;
    }
};

const isOldBomLinebuildingData = (data: BomLineBuildingOutput) => {
    return 'lines' in data;
};

export const uploadFailedBOMImportToContainer = async (fileToUpload: File, enqueueSnackbar: NotificationFunction) => {
    const fileWithUrl: FileWithUrlAndName = {
        file: fileToUpload,
        url: URL.createObjectURL(fileToUpload),
        name: fileToUpload.name,
    };
    await uploadResources([fileWithUrl], getSingleBomFailedImportFileUploadEndpoint(), enqueueSnackbar);
};

// TODO: Refactor this to a bulk endpoint
export function useBomFiles(designItemResponses: DesignItemResponseDTO[]) {
    const bomFileIds = React.useMemo(() => {
        return uniq(
            designItemResponses
                .map((c) =>
                    c.origin.type === DesignItemOriginTypes.ExcelFile ? c.origin.data?.bom_file_id : undefined,
                )
                .filter(isPresent),
        );
    }, [designItemResponses]);

    return useQuery({
        queryKey: ['useBomFiles', bomFileIds],
        queryFn: async (): Promise<Array<BomFileDTO>> => {
            const promises = bomFileIds.map((id) => {
                return http('GET /bom-files/:id', { pathParams: { id } }, getToken());
            });
            const result = await Promise.all(promises);
            return result.map((d): BomFileDTO => d.data);
        },
        staleTime: 60_000 /* 1 min */,
    });
}
