import { plural, t, Trans } from '@lingui/macro';
import {
    assertUnreachable,
    extractFileNameFromUrl,
    formatDecimal,
    formatToLongDate,
    isPresent,
    transEnum,
} from '@luminovo/commons';
import { colorSystem, Flexbox, Link, Tag, TertiaryButton, Text, Tooltip } from '@luminovo/design-system';
import {
    CustomerDTO,
    CustomFullPart,
    CustomOptionOfferDTO,
    CustomPartOfferDTO,
    CustomPartTypeEnum,
    OtsFullPart,
    PanelDetailsPostDTO,
    RfqDTO,
    SolutionTag,
    StandardPartOfferDTO,
} from '@luminovo/http-client';
import {
    DescriptionOutlined,
    ExpandMore,
    GetApp,
    OpenInNewRounded as OpenInNewRoundedIcon,
    WarningRounded,
} from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { formatPackaging, formatQuantity, formatSupplierAndStockLocationDTO } from '../../../formatters';
import { depanelizationTranslations } from '../../../i18n';
import { hasSolutionTag, isStandardPartMarketOffer } from '../../../model';
import { Solution } from '../../../types';
import { AvailabilityChip } from '../../chips';

export const HeaderItem: React.FunctionComponent<{
    label: string | JSX.Element;
}> = ({ label, children }): JSX.Element => {
    return (
        <Flexbox justifyContent={'space-between'} alignItems={'center'}>
            <Text variant="h5" color={colorSystem.neutral[6]}>
                {label}
            </Text>
            <Text variant="body" color={colorSystem.neutral[9]} style={{ textAlign: 'right' }}>
                {children}
            </Text>
        </Flexbox>
    );
};

export const HeaderItemLinkedLocation: React.FunctionComponent<{
    linkedLocation: StandardPartOfferDTO['linked_location'];
}> = ({ linkedLocation }): JSX.Element => {
    if (linkedLocation.type === 'SupplierAndStockLocation') {
        return <HeaderItem label={t`Supplier`}>{formatSupplierAndStockLocationDTO(linkedLocation)}</HeaderItem>;
    }

    if (linkedLocation.type === 'InventorySite') {
        return <HeaderItem label={t`Inventory site`}>{linkedLocation.name}</HeaderItem>;
    }

    assertUnreachable(linkedLocation);
};

export const HeaderItemLeadTime: React.FunctionComponent<{
    solution: Solution;
}> = ({ solution }): JSX.Element => {
    return (
        <HeaderItem label={t`Lead time`}>
            <AvailabilityChip solution={solution} />
        </HeaderItem>
    );
};

export const HeaderItemPackage: React.FunctionComponent<{
    offer: StandardPartOfferDTO;
}> = ({ offer }): JSX.Element => {
    return (
        <HeaderItem label={t`Packaging`}>
            {isPresent(offer.packaging) ? (
                <Tag attention={'low'} color={'neutral'} label={formatPackaging(offer.packaging)} />
            ) : (
                <Text color={colorSystem.neutral[6]}>{formatPackaging(offer.packaging)}</Text>
            )}
        </HeaderItem>
    );
};

export const HeaderItemMpn: React.FunctionComponent<{
    otsPart: OtsFullPart;
}> = ({ otsPart }): JSX.Element => {
    return <HeaderItem label={t`MPN`}>{otsPart.mpn}</HeaderItem>;
};

export const HeaderItemIpn: React.FunctionComponent<{
    id: string;
    href: string;
}> = ({ id, href }): JSX.Element => {
    return (
        <HeaderItem label={t`IPN`}>
            <Link href={href} target="_blank" rel="noopener noreferrer">
                <Flexbox gap={4} alignItems={'center'}>
                    <OpenInNewRoundedIcon fontSize="inherit" />
                    <Text variant="body" color="inherit">
                        {id}
                    </Text>
                </Flexbox>
            </Link>
        </HeaderItem>
    );
};

export const HeaderItemManufacturer: React.FunctionComponent<{
    otsPart: OtsFullPart;
}> = ({ otsPart }): JSX.Element => {
    return <HeaderItem label={t`Manufacturer`}>{otsPart.manufacturer.name}</HeaderItem>;
};

export const HeaderItemCustomPartDescription: React.FunctionComponent<{
    description: string;
}> = ({ description }): JSX.Element => {
    return (
        <HeaderItem label={t`Part description`}>
            <Text>{description}</Text>
        </HeaderItem>
    );
};

export const HeaderItemSku: React.FunctionComponent<{
    offer: StandardPartOfferDTO;
}> = ({ offer }): JSX.Element => {
    if (!isStandardPartMarketOffer(offer)) {
        return <></>;
    }

    return <HeaderItem label={t`SKU`}>{offer.supplier_part_number ?? t`Unknown`}</HeaderItem>;
};

export const HeaderItemValidUntil: React.FunctionComponent<{
    offer: StandardPartOfferDTO | CustomOptionOfferDTO;
}> = ({ offer }): JSX.Element => {
    if (!isPresent(offer.valid_until)) {
        return <></>;
    }

    return <HeaderItem label={t`Valid until`}>{formatToLongDate(offer.valid_until)}</HeaderItem>;
};

export const HeaderItemUnitOfMeasurement: React.FunctionComponent<{
    offer: StandardPartOfferDTO | CustomOptionOfferDTO;
}> = ({ offer }): JSX.Element => {
    if (offer.unit_of_measurement.quantity_unit.quantity === 1) {
        return <></>;
    }

    return (
        <HeaderItem label={t`Unit of measurement`}>
            {formatQuantity(offer.unit_of_measurement.quantity_unit, { showPiecesUnit: true })}
        </HeaderItem>
    );
};

export const HeaderItemNcnr: React.FunctionComponent<{
    offer: StandardPartOfferDTO;
}> = ({ offer }): JSX.Element => {
    if (!isPresent(offer.ncnr)) {
        return <></>;
    }

    return <HeaderItem label={t`NCNR`}>{`Yes`}</HeaderItem>;
};

export const HeaderItemValidForRfq: React.FunctionComponent<{
    solution?: Solution;
    validFor?: RfqDTO;
    href?: string;
}> = ({ solution, validFor, href }): JSX.Element => {
    const isInactive = isPresent(solution) && hasSolutionTag(solution, SolutionTag.Inactive);

    if (!isPresent(validFor)) {
        return <></>;
    }

    return (
        <HeaderItem label={t`Valid for`}>
            <Link href={href} target="_blank" rel="noopener noreferrer">
                <Flexbox gap={4} alignItems={'center'}>
                    {isInactive && <WarningRounded fontSize="inherit" style={{ color: colorSystem.red[6] }} />}
                    <Text variant="body" color="inherit">
                        {validFor.name}
                    </Text>
                </Flexbox>
            </Link>
        </HeaderItem>
    );
};

export const HeaderItemValidForCustomer: React.FunctionComponent<{
    solution?: Solution;
    validFor?: CustomerDTO;
    href?: string;
}> = ({ solution, validFor, href }): JSX.Element => {
    const isInactive = isPresent(solution) && hasSolutionTag(solution, SolutionTag.Inactive);

    if (!isPresent(validFor)) {
        return <></>;
    }

    return (
        <HeaderItem label={t`Valid for`}>
            <Link href={href} target="_blank" rel="noopener noreferrer">
                <Flexbox gap={4} alignItems={'center'}>
                    {isInactive && <WarningRounded fontSize="inherit" style={{ color: colorSystem.red[6] }} />}
                    <Text variant="body" color="inherit">
                        {validFor.name}
                    </Text>
                </Flexbox>
            </Link>
        </HeaderItem>
    );
};
export const HeaderItemNotes: React.FunctionComponent<{
    offer: StandardPartOfferDTO | CustomOptionOfferDTO;
}> = ({ offer }): JSX.Element => {
    if (!Boolean(offer.notes)) {
        return <></>;
    }

    return (
        <Flexbox flexDirection={'column'} gap={8}>
            <Text variant="h5" color={colorSystem.neutral[6]}>
                <Trans>Notes</Trans>
            </Text>
            <Text variant="body" color={colorSystem.neutral[9]} style={{ wordBreak: 'break-word' }}>
                {offer.notes}
            </Text>
        </Flexbox>
    );
};

export const HeaderItemOfferNumber: React.FunctionComponent<{
    offer: StandardPartOfferDTO | CustomOptionOfferDTO;
}> = ({ offer }): JSX.Element => {
    if (!isPresent(offer.offer_number)) {
        return <></>;
    }

    if (!isPresent(offer.offer_url)) {
        return <HeaderItem label={t`Offer number`}>{offer.offer_number}</HeaderItem>;
    }

    return (
        <HeaderItem label={t`Offer number`}>
            <Link href={offer.offer_url} target="_blank" rel="noopener noreferrer">
                <Flexbox gap={4} alignItems={'center'}>
                    <OpenInNewRoundedIcon fontSize="inherit" />
                    <Text variant="body" color="inherit">
                        {offer.offer_number}
                    </Text>
                </Flexbox>
            </Link>
        </HeaderItem>
    );
};

export const HeaderItemSourcingBatchSize: React.FunctionComponent<{
    offer: CustomOptionOfferDTO;
}> = ({ offer }): JSX.Element => {
    if (!isPresent(offer.sourcing_batch_size)) {
        return <></>;
    }

    return (
        <HeaderItem label={t`Sourcing batch size`}>
            <Flexbox gap={4} alignItems={'center'}>
                <Text variant="body" color="inherit">
                    {offer.sourcing_batch_size}
                </Text>
            </Flexbox>
        </HeaderItem>
    );
};

export const HeaderItemPcbFiles: React.FunctionComponent<{
    part: CustomFullPart;
    offer: CustomPartOfferDTO;
    onClick: () => void;
    isLoading: boolean;
}> = ({ part, offer, onClick, isLoading }): JSX.Element => {
    if (part.type.name !== CustomPartTypeEnum.PCB || !offer.has_order_files) {
        return <></>;
    }

    return (
        <HeaderItem label={t`PCB files`}>
            <Tooltip
                title={t`Download the order archive containing all the relevant PCB manufacturing files.`}
                placement="bottom"
                arrow
            >
                <TertiaryButton
                    size="small"
                    onClick={onClick}
                    disabled={isLoading}
                    isLoading={isLoading}
                    startIcon={<GetApp />}
                >
                    <Trans>Download</Trans>
                </TertiaryButton>
            </Tooltip>
        </HeaderItem>
    );
};

export const HeaderItemPanelSpecification: React.FunctionComponent<{
    panel: PanelDetailsPostDTO;
    panelDimensions:
        | {
              x: number | null;
              y: number | null;
          }
        | undefined;
}> = ({ panelDimensions, panel }): JSX.Element => {
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <Flexbox flexDirection={'column'} gap={'16px'}>
            <Flexbox
                onClick={() => setIsOpen(!isOpen)}
                gap={8}
                alignItems={'flex-start'}
                style={{
                    cursor: 'pointer',
                }}
            >
                <ExpandMore
                    style={{
                        transform: !isOpen ? `rotate(-90deg)` : `rotate(0deg)`,
                        transition: 'transform 0.1s linear',
                        width: '20px',
                    }}
                />

                <Text>
                    <Trans>
                        Delivery panel: {panel.row_count}{' '}
                        {plural(panel.row_count, {
                            one: 'row',
                            other: 'rows',
                        })}
                        , {panel.column_count}{' '}
                        {plural(panel.column_count, {
                            one: 'column',
                            other: 'columns',
                        })}{' '}
                        {panelDimensions && (
                            <>
                                ({formatDecimal(panelDimensions.x)} mm x {formatDecimal(panelDimensions.y)} mm)
                            </>
                        )}
                    </Trans>
                </Text>
            </Flexbox>
            {isOpen && (
                <Box
                    display={'grid'}
                    flexDirection={'column'}
                    gridTemplateColumns={'1fr 1fr'}
                    gap={'24px'}
                    width={'100%'}
                >
                    <Flexbox flexDirection={'column'} gap={4}>
                        <Text variant="h5" color={colorSystem.neutral[6]}>
                            <Trans>Row count</Trans>
                        </Text>
                        <Text variant="body">{panel.row_count}</Text>
                    </Flexbox>
                    <Flexbox flexDirection={'column'} gap={4}>
                        <Text variant="h5" color={colorSystem.neutral[6]}>
                            <Trans>Column count</Trans>
                        </Text>
                        <Text variant="body">{panel.column_count}</Text>
                    </Flexbox>
                    <Flexbox flexDirection={'column'} gap={4}>
                        <Text variant="h5" color={colorSystem.neutral[6]}>
                            <Trans>Horizontal spacing</Trans>
                        </Text>
                        <Text variant="body">{panel.horizontal_spacing_in_mm} mm</Text>
                    </Flexbox>
                    <Flexbox flexDirection={'column'} gap={4}>
                        <Text variant="h5" color={colorSystem.neutral[6]}>
                            <Trans>Vertical spacing</Trans>
                        </Text>
                        <Text variant="body">{panel.vertical_spacing_in_mm} mm</Text>
                    </Flexbox>
                    <Flexbox flexDirection={'column'} gap={4}>
                        <Text variant="h5" color={colorSystem.neutral[6]}>
                            <Trans>Top padding</Trans>
                        </Text>
                        <Text variant="body">{panel.padding.top_in_mm} mm</Text>
                    </Flexbox>
                    <Flexbox flexDirection={'column'} gap={4}>
                        <Text variant="h5" color={colorSystem.neutral[6]}>
                            <Trans>Bottom padding</Trans>
                        </Text>
                        <Text variant="body">{panel.padding.bottom_in_mm} mm</Text>
                    </Flexbox>
                    <Flexbox flexDirection={'column'} gap={4}>
                        <Text variant="h5" color={colorSystem.neutral[6]}>
                            <Trans>Left padding</Trans>
                        </Text>
                        <Text variant="body">{panel.padding.left_in_mm} mm</Text>
                    </Flexbox>
                    <Flexbox flexDirection={'column'} gap={4}>
                        <Text variant="h5" color={colorSystem.neutral[6]}>
                            <Trans>Right padding</Trans>
                        </Text>
                        <Text variant="body">{panel.padding.right_in_mm} mm</Text>
                    </Flexbox>
                    <Flexbox flexDirection={'column'} gap={4}>
                        <Text variant="h5" color={colorSystem.neutral[6]}>
                            <Trans>Depanelization type</Trans>
                        </Text>
                        <Text variant="body">{transEnum(panel.depanelization, depanelizationTranslations)}</Text>
                    </Flexbox>
                    <Flexbox flexDirection={'column'} gap={4}>
                        <Text variant="h5" color={colorSystem.neutral[6]}>
                            <Trans>Min. milling distance</Trans>
                        </Text>
                        <Text variant="body">{panel.min_milling_distance_in_mm}</Text>
                    </Flexbox>
                </Box>
            )}
        </Flexbox>
    );
};

export const HeaderItemOfferFiles: React.FunctionComponent<{
    files: string[] | undefined;
}> = ({ files = [] }): JSX.Element => {
    if (files.length < 1) return <></>;

    return (
        <Flexbox justifyContent={'space-between'} alignItems={'flex-start'} gap={'16px'}>
            <Text variant="h5" style={{ color: colorSystem.neutral[6], whiteSpace: 'nowrap' }}>
                <Trans>Additional files</Trans>
            </Text>
            <Flexbox gap="8px" alignItems="center" flexWrap={'wrap'}>
                {files.map((resource, index) => (
                    <Link
                        key={index}
                        href={resource}
                        onClick={(e) => e.stopPropagation()}
                        download={resource}
                        attention="low"
                        startIcon={<DescriptionOutlined />}
                    >
                        {extractFileNameFromUrl(resource)}
                    </Link>
                ))}
            </Flexbox>
        </Flexbox>
    );
};
