import { BomScreeningSheet, ScreenerHandlerOutput } from '@luminovo/http-client';
import { ValidationError } from '../../DesignItemDetails/components/AutocompleteColumnTags/validation';
import { BomImporterDetails } from './BomImporterDetails';
import { BomSheetSelect } from './BomSheetSelect';
import { ColumnMappingGuidanceBanner } from './ColumnMappingGuidance/ColumnMappingGuidanceBanner';

export function BomImporterSidebar({
    bomScreeningOutput,
    sheetIndex,
    validationErrors,
    switchSheet,
}: {
    bomScreeningOutput: ScreenerHandlerOutput;
    sheetIndex: number;
    validationErrors: ValidationError[];
    switchSheet: (sheetIndex: number) => void;
}) {
    const bomScreeningSheet: BomScreeningSheet = bomScreeningOutput.sheets[sheetIndex];

    return (
        <>
            <ColumnMappingGuidanceBanner />
            <BomSheetSelect sheetIndex={sheetIndex} bomScreeningOutput={bomScreeningOutput} switchSheet={switchSheet} />
            <BomImporterDetails bomScreeningSheet={bomScreeningSheet} validationErrors={validationErrors} />
        </>
    );
}
