import { config } from './config';

export const BACKEND_HOST: string = config.BACKEND_HOST;
export const HOST_NAME = config.HOST_NAME;
export const BACKEND_BASE = config.BACKEND_BASE;
export const WEBSOCKET_BASE = config.WEBSOCKET_BASE;

/*******************************************************************************************
 * IMPORTANT: Keep this in sync with the same function in `@luminovo/commons`
 * Any changes made here should be reflected there as well.
 *******************************************************************************************/
export const isProductionEnvironment = (): boolean => {
    if (process.env.REACT_APP_PRETEND_TO_BE_IN_PRODUCTION === 'true') {
        return true;
    }
    if (process.env.NODE_ENV === 'production') {
        return true;
    }
    if (process.env.NODE_ENV === 'development') {
        return false;
    }
    if (HOST_NAME.includes('chromatic.com')) {
        return false;
    }
    if (HOST_NAME.includes('localhost')) {
        return false;
    }
    // Default to true unless we know for any of the reasons above
    // that it's definitely not a production environment.
    return true;
};
