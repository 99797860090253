import { assertPresent } from '@luminovo/commons';
import { PCBV2 } from '@luminovo/http-client';
import React from 'react';
import { Capability, PcbCapabilitiesType } from '../../../../../../resources/pcb/utils/usePcbCapabilities';
import { useExtractedPcbSpecification } from './useExtractedPcbSpecification';

export const useExtractedPcbCapabilities = ({
    pcb,
    pcbCapabilities,
}: {
    pcb: PCBV2;
    pcbCapabilities: PcbCapabilitiesType;
}) => {
    const { data: converted } = useExtractedPcbSpecification({ pcbId: pcb.id });

    const extractedCapability = React.useMemo(() => {
        return converted !== undefined ? converted.extracted : {};
    }, [converted]);
    const extractedFile = converted?.file;

    // Not using the id because it was introduced more recently, and there might
    // be some files without an id
    const pcbFile = (pcb.files ?? []).find((file) => file.name === extractedFile?.name);

    const result = React.useMemo(() => {
        const extractedBasicCapabilities: Capability[] = pcbCapabilities.basic
            .map((c): Capability => {
                const ec = extractedCapability[c.capabilityName];

                return {
                    ...c,
                    //@ts-ignore
                    extractedFromPdf:
                        ec === undefined
                            ? undefined
                            : {
                                  value: ec.value,
                                  file: assertPresent(pcbFile),
                                  regions: ec.regions,
                              },
                };
            })
            .filter((c) => c.extractedFromPdf !== undefined);

        const extractedAdvancedCapabilities: Capability[] = pcbCapabilities.advanced
            .map((c): Capability => {
                const ec = extractedCapability[c.capabilityName];

                return {
                    ...c,
                    //@ts-ignore
                    extractedFromPdf:
                        ec === undefined
                            ? undefined
                            : {
                                  value: ec.value,
                                  file: assertPresent(pcbFile),
                                  regions: ec.regions,
                              },
                };
            })
            .filter((c) => c.extractedFromPdf !== undefined);

        return {
            basic: extractedBasicCapabilities,
            advanced: extractedAdvancedCapabilities,
            fields: [...extractedBasicCapabilities, ...extractedAdvancedCapabilities],
        };
    }, [extractedCapability, pcbFile, pcbCapabilities.advanced, pcbCapabilities.basic]);

    return result;
};
