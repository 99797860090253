import * as React from 'react';

export const LazyNegotiationListPage = React.lazy(async () => import('./pages/NegotiationListPage'));
export const LazyNegotiationQuoteImporterPage = React.lazy(
    async () => import('./NegotiationsQuoteImporter/ExcelQuoteImporterPage'),
);
export const LazyNegotiationDetailsPage = React.lazy(async () => import('./pages/NegotiationDetailsPage'));
export const LazyNegotiationCreatePage = React.lazy(async () => import('./pages/NegotiationCreatePage'));
export const LazyNegotiationLineItemDetailsPage = React.lazy(
    async () => import('./pages/NegotiationLineItemDetailsPage'),
);
export const LazyQuoteRequestEmailPreviewPage = React.lazy(async () => import('./pages/QuoteRequestEmailPreviewPage'));
export const LazyQuoteRequestAssignPage = React.lazy(async () => import('./pages/QuoteRequestAssignPage'));
