import { t } from '@lingui/macro';
import { BrokenImageIcon, CenteredLayout, NonIdealState } from '@luminovo/design-system';
import { PCBV2 } from '@luminovo/http-client';
import { Box, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Prompt } from 'react-router';
import { isPcbSetupWithoutFiles } from '../../../resources/pcb/pcbFunctions';
import { useFindPcb } from '../../../resources/pcb/pcbHandlers';
import { ViewContext } from '../../Bom/components/ModuleTableData';
import { DrillManagement } from '../components/DrillManagement';
import { PcbActionToolbar, useCurrentRouteDetails } from '../components/FormActionToolbar';
import {
    ContainersWithFiles,
    createPcbFileUpdateRequest,
    getInitialItemState,
} from '../components/LayerAssignment/utils/layerAssignmentsUtils';
import { useMutationUpdatePcbFileTypes } from '../components/LayerAssignment/utils/useMutationUpdatePcbFileTypes';
import { PcbLoading } from '../components/PcbLoading';
import { PcbSidebarLayout } from '../components/PcbSidebarLayout';
import { usePcbApprovalState } from '../utils/usePcbApprovalState';

export function DrillManagementTab({
    assemblyId,
    rfqId,
    isEditable,
    viewContext,
}: {
    assemblyId: string;
    rfqId: string;
    isEditable: boolean;
    viewContext: ViewContext;
}) {
    return (
        <PcbLoading assemblyId={assemblyId} rfqId={rfqId} isEditable={isEditable} viewContext={viewContext}>
            <PcDrillManagementLayoutWrapper
                assemblyId={assemblyId}
                rfqId={rfqId}
                isEditable={isEditable}
                viewContext={viewContext}
            />
        </PcbLoading>
    );
}

function PcDrillManagementLayoutWrapper({
    assemblyId,
    rfqId,
    isEditable,
    viewContext,
}: {
    assemblyId: string;
    rfqId: string;
    isEditable: boolean;
    viewContext: ViewContext;
}) {
    const { data: pcb } = useFindPcb({ assemblyId });

    if (!pcb) {
        return <></>;
    }

    return (
        <DrillManagementLayout
            assemblyId={assemblyId}
            rfqId={rfqId}
            pcb={pcb}
            isEditable={isEditable}
            viewContext={viewContext}
        />
    );
}

function DrillManagementLayout({
    assemblyId,
    rfqId,
    pcb,
    isEditable,
    viewContext,
}: {
    assemblyId: string;
    rfqId: string;
    pcb: PCBV2;
    isEditable: boolean;
    viewContext: ViewContext;
}) {
    const pcbFiles = React.useMemo(() => pcb.files ?? [], [pcb]);
    const [items, setItems] = useState<ContainersWithFiles>(getInitialItemState(pcbFiles));
    const [isEditing, setIsEditing] = React.useState(false);
    const [isDirty, setIsDirty] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const { mutateAsync, isLoading: isUpdatingFiles } = useMutationUpdatePcbFileTypes({ pcbId: pcb.id });

    const useFormReturn = useForm({});

    const handleCancel = () => {
        setItems(getInitialItemState(pcbFiles));
        setIsEditing(false);
        setIsDirty(false);
    };

    const { currentRoute } = useCurrentRouteDetails({ viewContext });
    const { isRouteApproved, onApprovalClick } = usePcbApprovalState({
        pcbId: pcb.id,
        currentRoute: currentRoute?.route,
    });

    const handleEdit = async () => {
        if (isRouteApproved === true) {
            await onApprovalClick(false);
        }
        setIsEditing(true);
    };

    const handleSubmit = useFormReturn.handleSubmit(async () => {
        const updateRequest = createPcbFileUpdateRequest(items, false);
        const mutateData = await mutateAsync(updateRequest);
        if (isRouteApproved === false) {
            await onApprovalClick(true);
        }
        enqueueSnackbar(t`File matching approved. The PCB will be re-rendered now` + '...', {
            variant: 'success',
        });
        setIsEditing(false);
        setIsDirty(false);
        return mutateData;
    });

    function handleChange(newValue: ContainersWithFiles | ((prev: ContainersWithFiles) => ContainersWithFiles)) {
        setIsDirty(true);
        if (newValue instanceof Function) {
            return setItems((items) => newValue(items));
        }

        setItems({ ...items });
    }

    // If Pcb files are updated from the server, we need to update the state
    React.useEffect(() => {
        setItems({ ...getInitialItemState(pcbFiles) });
    }, [pcbFiles]);

    React.useEffect(() => {
        setIsEditing(isRouteApproved === false && isEditable ? true : false);
    }, [isRouteApproved, isEditable]);

    return (
        <PcbSidebarLayout pcb={pcb} assemblyId={assemblyId} rfqId={rfqId} viewContext={viewContext}>
            <FormProvider {...useFormReturn}>
                <PcbActionToolbar
                    viewContext={viewContext}
                    rfqId={rfqId}
                    assemblyId={assemblyId}
                    pcb={pcb}
                    pageTitle={t`Drills`}
                    formId={'pcb-drill-management-form'}
                    isEditing={isEditing}
                    isDirty={isDirty}
                    disabled={isUpdatingFiles}
                    isEditable={isEditable}
                    submitButtonOptions={{
                        onClick: handleSubmit,
                    }}
                    cancelButtonOptions={{
                        onClick: handleCancel,
                    }}
                    editButtonOptions={{
                        onClick: handleEdit,
                    }}
                />
                <Prompt
                    message={t`This form has unsaved changes. Are you sure you want to lose your progress?`}
                    when={isDirty}
                />
                {isPcbSetupWithoutFiles(pcb) ? (
                    <CenteredLayout style={{ height: 'calc(100vh - 155px)' }}>
                        <NonIdealState
                            title={t`No PCB files uploaded`}
                            description={t`Please upload your PCB files to start managing your drills.`}
                            Icon={BrokenImageIcon}
                        />
                    </CenteredLayout>
                ) : (
                    <Box
                        style={{
                            overflow: 'scroll',
                            width: '100%',
                            height: 'calc(100vh - 155px)',
                        }}
                    >
                        {pcb.files && pcb.files.length > 0 ? (
                            <DrillManagement items={items} handleOnChange={handleChange} isEditable={isEditing} />
                        ) : (
                            <CenteredLayout>
                                <CircularProgress />
                            </CenteredLayout>
                        )}
                    </Box>
                )}
            </FormProvider>
        </PcbSidebarLayout>
    );
}
